import { HttpParams } from '@angular/common/http';
// Angular Imports
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
// App Imports
import {
  AttributeDescription,
  CustomAtrributeValue,
} from '@shared/interfaces/attribute-description.interface';
import { DirectMessage } from '@shared/interfaces/direct-message.interface';
import { VentureInterface } from '@shared/interfaces/venture.interface';
import { FormUtils } from '@shared/utils/form-utils';
import { ImageInterface } from '@src/app/shared/interfaces/file.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VentureHttpService extends BaseHttpService<VentureInterface> {
  get(): any {
    return super.gets().pipe(
      map((items = []) => {
        return items.map((item) => this.tranformData(item));
      })
    );
  }

  paginate(
    params: HttpParams = null,
    innerPath: any = null
  ): Observable<ApiGetResponse<VentureInterface>> {
    return super.paginate(params, innerPath).pipe(
      map((res: ApiGetResponse<VentureInterface>) => {
        return this.handlePaginateResponse(res);
      })
    );
  }

  shouldShowContent(
    attributes: AttributeDescription[],
    item: VentureInterface,
    keys: string[]
  ): boolean {
    for (const key of keys) {
      const isVisibleInSystem = FormUtils.getFieldOptions(
        attributes,
        key
      )?.isVisibleInSystem;
      if (
        isVisibleInSystem ||
        item[StringUtils.toLowerCaseFirstLetter(key)] !== null
      ) {
        return true;
      }
    }
  }

  paginateX(
    params: any = null,
    innerPath: any = null
  ): Observable<ApiGetResponse<VentureInterface>> {
    return super.paginateX(params, innerPath).pipe(
      map((res: ApiGetResponse<VentureInterface>) => {
        return this.handlePaginateResponse(res);
      })
    );
  }

  paginateAsPost(
    params: any = null,
    innerPath: any = null
  ): Observable<ApiGetResponse<VentureInterface>> {
    return super.paginateAsPost(params, innerPath).pipe(
      map((res: ApiGetResponse<VentureInterface>) => {
        return this.handlePaginateResponse(res);
      })
    );
  }

  tranformData(venture: VentureInterface): VentureInterface {
    super.tranformData(venture);
    venture.characteristicsArray = [];
    for (const key of VENTURE_CHARACTERISTICS) {
      const data = venture[
        StringUtils.toLowerCaseFirstLetter(key)
      ] as CustomAtrributeValue;
      if (data) {
        venture.characteristicsArray.push(data);
      }
    }
    return venture;
  }

  getApiRootPath(): string {
    return 'ventures';
  }

  getDemands(): string[] {
    return [
      'Sponsorship',
      'Funding',
      'Market Access / Channel / Local Representatives / Franchisers / Licensees',
      'Pilot Customer / Customers',
      'Technology',
      'Expertise',
      'Acceleration',
      'Professionalization',
      'Services',
    ];
  }

  sendGetInTouchMessage(
    ventureId: number,
    payload: DirectMessage
  ): Observable<any> {
    const url = `${this.apiUrl}/${ventureId}/messages`;
    return this.http.post(url, payload);
  }

  submitVentureToChallenge(
    ventureId: number,
    challengeId: number
  ): Observable<any> {
    const url = `${this.apiUrl}/${ventureId}/submit`;
    const payload = {
      orgId: challengeId,
    };

    return this.http.post(url, payload);
  }

  getDefaultBanner(): Observable<ImageInterface> {
    const url = `${this.apiUrl}/default-banner`;

    return this.http.get<ImageInterface>(url);
  }
}

export const VENTURE_CHARACTERISTICS = [
  'BusinessPotential',
  'BusinessReach',
  // 'BusinessScalability',
  'BusinessCustomers',
  'BusinessKind',
  'ExpectedTimeToMarket',
  'MWhSavings',
  'CO2Savings',
];

export const VENTURE_REQUIRED_KEY = [
  'Title',
  'Image',
  'Phase',
  'Status',
  'LeadCompany',
];

export const VENTURE_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES = [
  'ContactOfEconomicPartner',
  'BachelorThesisOfNotAParttimeStudent',
  'StartDate',
  'Deadline',
  'ProjectType',
  'FocusTopics',
  'LookingFor',
  'TermsAndConditionsConfirmation',
];
