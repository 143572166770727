import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CropperComponent } from 'angular-cropperjs';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import {
  ImageCropperComponent,
  ImageTransform,
  LoadedImage,
} from 'ngx-image-cropper';
import { SuperImageCropper } from 'super-image-cropper';
@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageInputComponent implements AfterViewInit {
  @Input() aspectRatio: number;
  @Input() showDialogHeader = true;
  @Input() closeDialogOnCrop = true;
  @Input() useSmallDropZone = false;

  @ViewChild(ImageCropperComponent) imgCropper: ImageCropperComponent;

  @Output() loadImgFailed: EventEmitter<void> = new EventEmitter();

  @Output() uploadSuccess = new EventEmitter();

  acceptType = '*';

  scale = 1;

  transform: ImageTransform = {};

  imageFile: File;

  originalImageFile: LoadedImage;

  imageUrl = '';
  cropperOptions = {
    aspectRatio: 1,
    zoomOnWheel: false,
    autoCropArea: 1,
    viewMode: 0,
  };

  MAX_GIF_FILE_SIZE_MB = 2;
  showMaxFileSizeErrorMessage = false;
  isCropping = false;

  @ViewChild('cropperComponent') public cropperComponent: CropperComponent;

  constructor(public activeModal: NgbActiveModal) {}

  ngAfterViewInit() {
    this.cropperOptions.aspectRatio = this.aspectRatio;
  }

  dropped(files: NgxFileDropEntry[]): void {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          const isExceedMaxFileSize =
            this.getFileSizeInMB(file) >= this.MAX_GIF_FILE_SIZE_MB;

          if (isExceedMaxFileSize) {
            this.showMaxFileSizeErrorMessage = true;
            return;
          }

          this.imageFile = file;
          this.imageUrl = URL.createObjectURL(file);
          this.showMaxFileSizeErrorMessage = false;
        });
      }
    }
  }

  loadImageFailed(): void {
    this.clearImg();
    this.loadImgFailed.emit();
  }

  crop(): void {
    const isGif = this.isGIF(this.imageFile);
    if (!isGif) {
      this.isCropping = true;
      this.cropperComponent.cropper.getCroppedCanvas().toBlob((blob) => {
        this.imageCropped(blob);
        this.isCropping = false;
      });
    } else {
      this.cropGifImage();
    }
  }

  onZoom(event: string): void {
    const ratio = Number(event);
    this.cropperComponent.cropper.zoom(ratio - this.scale);
  }

  imageCropped(blob?: Blob): void {
    let file: File;

    if (blob) {
      file = new File([blob], this.imageFile.name);
    }

    this.clearImg();

    if (this.closeDialogOnCrop) {
      this.activeModal.close(file);
    } else {
      this.uploadSuccess.emit(file);
    }
  }

  clearImg(): void {
    this.imageFile = null;
    this.scale = 1;
  }

  imageLoaded(img: LoadedImage): void {
    this.originalImageFile = img;
  }

  isGIF(file) {
    if (file.type.startsWith('image/gif')) {
      return true;
    }

    const fileExtension = file.name.split('.').pop().toLowerCase();
    return fileExtension === 'gif';
  }

  cropGifImage() {
    const imageCropper = new SuperImageCropper();

    this.isCropping = true;
    imageCropper
      .crop({
        cropperInstance: this.cropperComponent.cropper,
        src: this.imageUrl,
        outputType: 'blob',
        gifJsOptions: {
          transparent: null,
        },
      })
      .then((blob) => {
        this.imageCropped(blob as Blob);
        this.isCropping = false;
      });
  }

  getFileSizeInMB(file): number {
    const sizeInBytes = file.size;
    const sizeInMB = sizeInBytes / (1024 * 1024);
    return Number(sizeInMB.toFixed(2));
  }
}
