<h4 class="font-weight-bold px-4 pt-3 pb-0" translate>Profile Detail</h4>
<div class="modal-body">
  <div
    class="mb-3 font-size-sm"
    [innerHTML]="
      'UI.EnforceProfile.Message'
        | translate
        | stringReplacement
          : {
              '{tenantName}':
                this.getTenantName()
            }
    "
  ></div>
  <div class="d-flex justify-content-center">
    <app-enforce-profile
      class="w-100"
      [isCreating]="false"
      [id]="id"
    ></app-enforce-profile>
  </div>
</div>
