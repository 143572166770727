<div>
  <div
    *ngIf="showSearchButton && items?.length > 0"
    class="people-cards__search"
  >
    <div class="input-group flex-nowrap">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1"
          ><i class="fas fa-search"></i
        ></span>
      </div>
      <input
        class="people-cards__search-btn"
        [placeholder]="'Search' | translate"
        [ngModel]="searchName"
        (ngModelChange)="setDisplayItems($event)"
      />
    </div>
  </div>

  <div
    *ngIf="allowAddPeople !== undefined ? allowAddPeople : editable"
    class="people-cards__search"
  >
    <div class="input-group flex-nowrap">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1"
          ><i
            class="fas"
            [ngClass]="peopleSearching ? 'fa-spinner fa-spin' : 'fa-user'"
          ></i
        ></span>
      </div>

      <input
        class="people-cards__search-btn flex-grow-1 flex-shrink-1"
        [placeholder]="'UI.Label.AddTeamMember' | translate"
        [(ngModel)]="selectedPeople"
        [ngbTypeahead]="peopleSearch"
        [inputFormatter]="peopleInputFormatter"
        (selectItem)="onSelect($event, searchName)"
        [resultTemplate]="customItemTemplate"
        container="body"
        #searchName
      />

      <div>
        <button
          *ngIf="showAddButton"
          type="button"
          (click)="addPeople()"
          class="btn btn-primary widget__btn-create ml-1 ml-md-4"
        >
          <i class="fas fa-plus fa-lg"></i>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="peopleSearchFailed" class="invalid-feedback d-block">
    Sorry, suggestions could not be loaded.
  </div>

  <app-people-card-list
    *ngIf="isGridView && !isTableExpanded; else tableView"
    (extraActionClicked)="promoteStakeholder($event)"
    (peopleRemoved)="openConfirmRemoveItemDialog($event)"
    (peopleRoleChanged)="peopleRoleChanged.emit($event)"
    (peopleCardClicked)="onPeopleCardClicked()"
    [allowRemovePeople]="allowRemovePeople"
    [customUserRoles]="customUserRoles"
    [displayItems]="pagingDisplayItems"
    [editable]="editable"
    [emptyMessage]="emptyMessage"
    [enableExtraAction]="enableExtraAction"
    [enableInteractionBar]="true"
    [extraActions]="extraActions"
    [globalOrgId]="globalOrgId"
    [cardType]="cardType"
    [hideProfileImage]="hideProfileImage"
  ></app-people-card-list>

  <app-bootstrap-pagination
    *ngIf="enablePagination"
    [page]="page"
    [pageSize]="pageSize"
    [collectionSize]="collectionSize"
    (changePageSize)="onPageSizeChange($event)"
    (changePage)="onPageIndexChange($event)"
    [pageSizeConfig]="pageSizeConfig.SixItemsFirstPage"
    [showChangePageSizeCtrl]="true"
  ></app-bootstrap-pagination>
</div>

<ng-template #customItemTemplate let-r="result" let-t="term" let-index="index">
  <div appRequireLogin class="d-flex align-items-center">
    <div class="avatar-small mr-3">
      <div class="avatar-wrapper">
        <img
          class="people__avatar widget__avatar-image"
          [src]="r.image"
          appDefaultAvatar
          appDynamicImgUrl
          alt=""
        />
      </div>
    </div>

    <ngb-highlight
      result="{{ r.firstName }} {{ r.lastName }} ({{ r.email }})"
      [term]="t"
    ></ngb-highlight>
  </div>
</ng-template>

<ng-template #tableView>
  <app-metadata-table-people
    *ngIf="pagingDisplayItems.length > 0; else itemEmtpyMessage"
    [entityDatas]="pagingDisplayItems"
    [editable]="false"
    [isLoading]="isLoading"
    [enableExtraAction]="enableExtraAction"
    (peoplePromoted)="promoteStakeholder($event)"
    (peopleRemoved)="openConfirmRemoveItemDialog($event)"
  ></app-metadata-table-people>
</ng-template>

<ng-template #modalRef let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="btn-close close"
      aria-label="Close button"
      aria-describedby="modal-title"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="tableView"></ng-container>
    <app-bootstrap-pagination
      *ngIf="enablePagination"
      [page]="page"
      [pageSize]="pageSize"
      [collectionSize]="collectionSize"
      (changePageSize)="onPageSizeChange($event)"
      (changePage)="onPageIndexChange($event)"
      [pageSizeConfig]="pageSizeConfig.SixItemsFirstPage"
      [showChangePageSizeCtrl]="true"
    ></app-bootstrap-pagination>
  </div>
</ng-template>

<ng-template #itemEmtpyMessage>
  <div class="people-cards-emtpy m-0 font-size-sm" translate>
    There are no people cards yet
  </div>
</ng-template>
