<div class="modal-header">
  <h4 class="modal-title">
    {{ 'Entity.Organization.PartnerOrg.Settings.Label' | translate }}
  </h4>
  <button
    type="button"
    class="btn-close close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="org-setting d-flex flex-column gap-16">
    <div
      *ngIf="!leadOrg.showAsChallenge"
      class="justify-content-between align-items-center org-setting__item"
    >
      <div>
        <div class="org-setting__title">{{ 'Ventures' | translate }}</div>
        <div class="org-setting__description">
          {{
            'Entity.Organization.PartnerOrg.Settings.ProjectIdea.Label'
              | translate
          }}
        </div>
      </div>
      <div>
        <app-toggle-button-slider
          [(status)]="organization.showVentures"
          [settings]="{
            activeText: 'UI.Toogle.Show' | translate,
            deactiveText: 'UI.Toogle.Hide' | translate
          }"
          (statusChange)="onSettingChange($event, SettingEnum.ProjectIdeas)"
        >
        </app-toggle-button-slider>
      </div>
    </div>
    <div
      *ngIf="!leadOrg.showAsChallenge"
      class="justify-content-between align-items-center org-setting__item"
    >
      <div>
        <div class="org-setting__title">{{ 'People' | translate }}</div>
        <div class="org-setting__description">
          {{
            'Entity.Organization.PartnerOrg.Settings.Member.Label' | translate
          }}
        </div>
      </div>
      <div>
        <app-toggle-button-slider
          [(status)]="organization.showMember"
          [settings]="{
            activeText: 'UI.Toogle.Show' | translate,
            deactiveText: 'UI.Toogle.Hide' | translate
          }"
          (statusChange)="onSettingChange($event, SettingEnum.People)"
        >
        </app-toggle-button-slider>
      </div>
    </div>
    <div
      *ngIf="leadOrg.showAsChallenge"
      class="justify-content-between align-items-center org-setting__item"
    >
      <div>
        <div class="org-setting__title">Custom Attributes</div>
        <div class="org-setting__description">
          {{
            'Entity.Organization.PartnerOrg.Settings.CustomAttributes.Label'
              | translate
          }}
        </div>
      </div>
      <div>
        <app-toggle-button-slider
          [(status)]="organization.showCustomAttribute"
          [settings]="{
            activeText: 'UI.Toogle.Show' | translate,
            deactiveText: 'UI.Toogle.Hide' | translate
          }"
          (statusChange)="onSettingChange($event, SettingEnum.CustomAttributes)"
        >
        </app-toggle-button-slider>
      </div>
    </div>
    <div class="justify-content-between align-items-center org-setting__item">
      <div>
        <div class="org-setting__title">{{ 'Events' | translate }}</div>
        <div class="org-setting__description">
          {{
            'Entity.Organization.PartnerOrg.Settings.Events.Label' | translate
          }}
        </div>
      </div>
      <div>
        <app-toggle-button-slider
          [(status)]="organization.showEvent"
          [settings]="{
            activeText: 'UI.Toogle.Show' | translate,
            deactiveText: 'UI.Toogle.Hide' | translate
          }"
          (statusChange)="onSettingChange($event, SettingEnum.Events)"
        >
        </app-toggle-button-slider>
      </div>
    </div>
  </div>
</div>
