<div class="page-header widget__heading--background">
  <div class="container p-0">
    <div
      class="page-header__row d-flex justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center flex-1">
        <h2
          class="page-header__text widget__heading truncate-line-1 m-0 mr-auto mr-md-0 font-weight-bold"
        >
          <ng-container *ngIf="title">{{ title }}</ng-container>
          <ng-content></ng-content>
        </h2>

        <div
          *ngIf="helpText"
          class="ml-2 d-flex align-items-center"
          triggers="click"
          [placement]="helpTextPlacement"
          [ngbTooltip]="helpText"
          tooltipClass="page-header-tooltip"
          appTooltip
          #t="ngbTooltip"
          [toolTipControl]="t"
          container="body"
        >
          <i
            class="tooltip-icon fas fa-info-circle align-middle pointer-cursor font-size-xl mt-1"
          ></i>
        </div>

        <div class="d-flex align-items-center ml-3 mt-1" *ngIf="isEventPage">
          <div
            *ngFor="let item of eventViewModes"
            class="mr-3 pointer-cursor"
            (click)="onEventViewModeChange(item.value)"
            [class.text-primary]="item.value === (eventViewMode | async)"
          >
            <i class="mr-2" [class]="item.iconClass"></i>
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>

      <div *ngIf="entityName === EntityName.Venture" class="mr-3">
        <app-sort-options
          [items]="sortOptions"
          (selectItem)="onSort($event)"
        > </app-sort-options>
      </div>

      <button
        (click)="onCreatePostClick($event)"
        *ngIf="isShownActionBtn && isHomePage && !isMessages()"
        class="page-header__btn btn btn-primary font-weight-bold"
        type="button"
      >
        <em class="fas fa-plus pr-2"></em>
        <span class="text-uppercase" translate>UI.NewMessage.CreatePost</span>
      </button>

      <button
        (click)="onBtnClick($event)"
        *ngIf="isShownActionBtn && isHomePage && isMessages()"
        class="page-header__btn btn btn-primary font-weight-bold"
        type="button"
      >
        <em class="fas fa-plus pr-2"></em>
        <span class="text-uppercase" translate>
          UI.NewMessage.CreateMessage
        </span>
      </button>

      <button
        (click)="onBtnClick($event)"
        *ngIf="isShownActionBtn && !isHomePage"
        class="page-header__btn btn btn-primary font-weight-bold"
        type="button"
      >
        <em class="fas fa-plus pr-2"></em>
        <span class="text-uppercase" translate>
          {{ createBtnText }}
        </span>
      </button>
    </div>
  </div>
</div>
