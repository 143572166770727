import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AttributeType } from '@src/app/shared/enums/attribute-type.enum';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { MetadataRecord } from '@src/app/shared/interfaces/metadata-table.interface';
import { CustomMetadataUtils } from '@src/app/shared/utils/custom-metadata-utils';

@Component({
  selector: 'app-custom-attributes',
  templateUrl: './custom-attributes.component.html',
})
export class CustomAttributesComponent implements OnChanges {
  @Input() formInput: UntypedFormGroup;
  @Input() editable = false;
  @Input() metadataRecord: MetadataRecord = {
    attributeDescriptions: [],
    attributeValues: [],
  };
  @Input() orderOfPropertyNames: string[] = [];

  AttributeType = AttributeType;
  orderedAttributeDescriptions: AttributeDescription[] = [];
  
  

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.metadataRecord && changes.metadataRecord.currentValue) {
      this.orderedAttributeDescriptions =
        this.attributeDescriptionsSortByOrderOfPropertyNames(
          this.metadataRecord.attributeDescriptions,
          this.orderOfPropertyNames
        );
    }
  }

  getFormOptions(attrDes: AttributeDescription): Record<string, any> {
    return CustomMetadataUtils.getFormOptions(attrDes);
  }

  private attributeDescriptionsSortByOrderOfPropertyNames(
    attributeDescriptions: AttributeDescription[] = [],
    orderOfPropertyNames: string[] = []
  ): AttributeDescription[] {
    if (attributeDescriptions.length > 0 && orderOfPropertyNames.length > 0) {
      const existAttrDesLst = attributeDescriptions.filter((x) =>
        orderOfPropertyNames.includes(x.propertyName)
      );
      const remainAttrDesLst = attributeDescriptions.filter(
        (x) => !orderOfPropertyNames.includes(x.propertyName)
      );

      existAttrDesLst.sort((a, b) => {
        return (
          orderOfPropertyNames.indexOf(a.propertyName) -
          orderOfPropertyNames.indexOf(b.propertyName)
        );
      });

      return [...existAttrDesLst, ...remainAttrDesLst];
    }
    return attributeDescriptions;
  }
}
