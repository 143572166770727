import {
  AfterViewChecked,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '@ngx-translate/core';
import { EventPopoverWrapperComponent } from '@src/app/components/dialogs/event-calendar-dialog/event-popover-wrapper/event-popover-wrapper.component';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { I18nService } from '@src/app/core/i18n/i18n.service';
import { GlobalFilterStoredService } from '@src/app/core/services/global-filter-stored.service';
import { SessionService } from '@src/app/core/session.service';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { EventInterface } from '@src/app/shared/interfaces/event.interface';
import { GlobalFilterCriteria } from '@src/app/shared/interfaces/filters/global-filter.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { TimeUtils } from '@src/app/shared/utils/time-utils';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-event-calendar-dialog',
  templateUrl: './event-calendar-dialog.component.html',
  styles: [],
  providers: [NgbTooltip, TranslatePipe],
})
export class EventCalendarDialogComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    eventsSet: () => {},
    select: () => {},
    editable: false,
    events: [],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek',
    },
    views: {
      dayGridMonth: {
        dayMaxEvents: 3,
      },
      dayGridWeek: {
        dayMaxEvents: 15,
      },
    },
    selectable: false,
    droppable: false,
    eventDurationEditable: true,
    buttonText: {
      month: 'Month',
      week: 'Week',
      today: 'Today',
    },
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: false,
      hour12: false,
    },
    locale: 'en-GB',
    eventDidMount: function (event) {
      this.renderTooltip(event);
      this.bindCentralConfigToEventColor();
    }.bind(this),
    eventWillUnmount: this.destroyTooltip.bind(this),
    eventClick: this.showPopover.bind(this),
    viewDidMount: this.bindCentralConfigToToolbarButton.bind(this),
    datesSet: this.handleDatasetChange.bind(this),
    moreLinkDidMount: this.bindCentralConfigToMoreBtn.bind(this),
    moreLinkContent: function (arg) {
      return arg.text + ' events';
    },
  };
  isLoading: boolean;
  organizationId: number;

  @Input() filterCriteria: GlobalFilterCriteria;
  @ViewChild('popoverTmpl', { static: true }) popoverTmpl: TemplateRef<any>;
  @ViewChild('loading') loadingTmpl!: TemplateRef<any>;

  popoversMap = new Map<any, ComponentRef<EventPopoverWrapperComponent>>();

  popoverFactory = this.resolver.resolveComponentFactory(
    EventPopoverWrapperComponent
  );
  isFirstLoad = true;

  constructor(
    public baseHttpService: EventHttpService,
    public ngbTooltip: NgbTooltip,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private renderer: Renderer2,
    private el: ElementRef,
    private translatePipe: TranslatePipe,
    private i18nService: I18nService,
    private filteredStoreService: GlobalFilterStoredService,
    private sessionService: SessionService
  ) {}

  ngAfterViewChecked() {
    this.handlePopoverPosition();
  }

  ngOnInit(): void {
    const lang = this.i18nService.language.code;

    this.calendarOptions.buttonText.month =
      this.translatePipe.transform('UI.Date.Month');
    this.calendarOptions.buttonText.week =
      this.translatePipe.transform('UI.Calendar.Week');
    if (lang === 'en') {
      this.calendarOptions.locale = lang + '-GB'; //convert 24:00 to 00:00
    } else {
      this.calendarOptions.locale = lang;
    }

    this.filteredStoreService
      .getCurrentFilterToken()
      .pipe(untilDestroyed(this))
      .subscribe((id: number) => {
        this.organizationId = id;
      });
  }

  async ngAfterViewInit() {
    this.appendLoadingTemplateToCalendar();
  }

  getEvents(date?: {
    fromDate: Date | string;
    toDate: Date | string;
  }): Observable<ApiGetResponse<EventInterface>> {
    return this.sessionService.apiReady$.pipe(
      filter(Boolean),
      switchMap(() => {
        return this.baseHttpService.paginateX({
          pageSize: 100000,
          ...date,
          ...(this.organizationId && { organizationIds: this.organizationId }),
        });
      })
    );
  }

  renderEvents(date: { fromDate: Date | string; toDate: Date | string }): void {
    this.handleLoading(true);

    this.getEvents(date)
      .pipe(
        map((res: ApiGetResponse<EventInterface>) =>
          this.mappingResponseToListData(res.items)
        )
      )
      .subscribe((res: any[]) => {
        this.handleLoading(false);
        this.calendarOptions.events = [...res];
      });
  }

  mappingResponseToListData = (items: EventInterface[]): any[] => {
    return items.map((item: EventInterface) => {
      const { date, time } = this.convertToLocalTime(item.eventDateTime);
      return {
        id: item.id,
        start: item.eventDateTime,
        end: TimeUtils.addHoursToDate(
          new Date(item.eventDateTime),
          item.eventLength
        ),
        title: item.title,
        backgroundColor: '#F20303',
        eventUrl: this.getEventDetailUrl(item.id),
        eventDate: date,
        time,
      };
    });
  };

  renderTooltip(event): void {
    const projectableNodes = Array.from(event.el.childNodes);

    const compRef = this.popoverFactory.create(
      this.injector,
      [projectableNodes],
      event.el
    );
    compRef.instance.template = this.popoverTmpl;

    this.appRef.attachView(compRef.hostView);
    this.popoversMap.set(event.el, compRef);
  }

  destroyTooltip(event): void {
    const popover = this.popoversMap.get(event.el);
    if (popover) {
      this.appRef.detachView(popover.hostView);
      popover.destroy();
      this.popoversMap.delete(event.el);
    }
  }

  showPopover(event): void {
    const popover = this.popoversMap.get(event.el);
    if (popover) {
      popover.instance.popover.open({ event: event });
    }
  }

  hidePopover(event): void {
    const popover = this.popoversMap.get(event.el);
    if (popover) {
      popover.instance.popover.close();
    }
  }

  onCloseEventPopup(event): void {
    this.hidePopover(event);
  }

  convertToLocalTime(utcTimeString: string): { date: string; time: string } {
    const utcDate = new Date(utcTimeString);
    const date = utcDate
      .toLocaleDateString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .replace(/\//g, '.');
    let time = utcDate.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    if (time === '24:00') {
      time = '00:00';
    }

    return {
      date,
      time,
    };
  }

  toggleButtonStyle(selector: string, addPrimary: boolean): void {
    const buttons = this.el.nativeElement.querySelectorAll(selector);
    buttons.forEach((button) => {
      button.classList.toggle('btn-primary', addPrimary);
    });
  }

  bindCentralConfigToToolbarButton(): void {
    this.toggleButtonStyle('.fc-button', true);
    this.toggleButtonStyle('.fc-dayGridMonth-button', false);
    this.toggleButtonStyle('.fc-dayGridWeek-button', false);
  }

  bindCentralConfigToEventColor(): void {
    const elements = this.el.nativeElement.querySelectorAll(
      '.fc-daygrid-block-event, .fc-daygrid-event-dot'
    );

    elements.forEach((element) => {
      element.classList.add('btn-primary');
    });
  }

  handleSwitchViewButton(dateInfo): void {
    const viewType = dateInfo.view.type;
    const monthViewBtn = this.el.nativeElement.querySelector(
      '.fc-dayGridMonth-button'
    );
    const weekViewBtn = this.el.nativeElement.querySelector(
      '.fc-dayGridWeek-button'
    );

    if (monthViewBtn && weekViewBtn) {
      if (viewType === 'dayGridMonth') {
        this.renderer.addClass(monthViewBtn, 'btn-primary');
        this.renderer.removeClass(weekViewBtn, 'btn-primary');
        this.renderer.addClass(weekViewBtn, 'btn-outline-primary');
      } else {
        this.renderer.addClass(weekViewBtn, 'btn-primary');
        this.renderer.removeClass(monthViewBtn, 'btn-primary');
        this.renderer.addClass(monthViewBtn, 'btn-outline-primary');
      }
    }
  }

  handleDatasetChange(dateInfo): void {
    const fromDate = dateInfo.start.toISOString();
    const toDate = dateInfo.end.toISOString();

    this.renderEvents({ fromDate, toDate });

    this.handleSwitchViewButton(dateInfo);
  }

  handleLoading(loading: boolean): void {
    const calendar = this.el.nativeElement.querySelector('.fc-view-harness');
    if (!calendar) return;
    if (loading) {
      this.renderer.addClass(calendar, 'loading-calendar');
    } else {
      this.renderer.removeClass(calendar, 'loading-calendar');
    }
  }

  appendLoadingTemplateToCalendar(): void {
    const calendar = this.el.nativeElement.querySelector('.fc-view-harness');
    if (calendar) {
      const loadingContent =
        this.loadingTmpl.createEmbeddedView(null).rootNodes[0];
      const cloneLoadingContent = document.importNode(loadingContent, true);

      this.renderer.appendChild(calendar, cloneLoadingContent);
    }
  }

  bindCentralConfigToMoreBtn(): void {
    const viewMoreBtns = this.el.nativeElement.querySelectorAll(
      '.fc-daygrid-more-link'
    );

    viewMoreBtns.forEach((viewMoreBtn) => {
      this.renderer.addClass(viewMoreBtn, 'btn-link-primary');
    });
  }

  handleMouseDown(e): void {
    e.stopPropagation();
    e.preventDefault();
  }

  getEventDetailUrl(id): string {
    const organizationId = StringUtils.getParamFromUrl(UrlParam.FilterTokenId);
    const origin = window.location.origin;
    const locale = this.i18nService.language.locale.toLowerCase();
    let redirectUrl: string;
    const eventPath = environment.jipUrl.event;

    if (organizationId) {
      redirectUrl = `${origin}/${locale}${eventPath}/${id}?organizationId=${organizationId}`;
    } else {
      redirectUrl = `${origin}/${locale}${eventPath}/${id}`;
    }

    return redirectUrl;
  }

  handlePopoverPosition() {
    const calendarView = this.el.nativeElement.querySelector(
      '.fc-view-harness.fc-view-harness-active'
    );
    const popoverEl = this.el.nativeElement.querySelector(
      '.fc-popover.fc-more-popover'
    );

    if (!popoverEl) return;

    const popoverLeftPos = popoverEl.style.left;
    const isOverflow =
      parseFloat(popoverLeftPos) + popoverEl.clientWidth >
      calendarView.clientWidth;

    if (isOverflow) {
      popoverEl.style.right = '0px';
      popoverEl.style.left = 'unset';
    }
  }

  ngOnDestroy(): void {
    /** */
  }
}
