import { Component, Injector, Input } from '@angular/core';
import { BaseEntityEditComponent } from '@components/base/base-detail/base-entity-edit/base-entity-edit.component';
import { EventInterface } from '@shared/interfaces/event.interface';
import { EventHttpService } from '@core/http/event-http.service';
import { AuthenService } from '@core/authen/authen.service';
import { SessionService } from '@core/session.service';
import { EntityName } from '@shared/enums/entity-name.enum';
import { environment } from '@env/environment';
import { FormUtils } from '@shared/utils/form-utils';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';

// @ts-ignore
@Component({
  selector: 'app-base-event-registration',
  template: ``,
})
export class BaseEventRegistrationComponent extends BaseEntityEditComponent<EventInterface> {
  // tslint:disable-next-line:variable-name
  @Input() widget_in_live_edit_mode: 1 | undefined;

  constructor(
    public eventHttpService: EventHttpService,
    protected authService: AuthenService,
    protected sessionService: SessionService,
    protected injector: Injector
  ) {
    super(eventHttpService, authService, sessionService, injector);

    this.entityName = EntityName.Event;
    this.entityRoot = environment.jipUrl.event;
  }

  runEntityStateCount(): void {
    // override
  }

  handleCustomMetadata(): void {
    // override
  }

  handleAfterGetEntityDetail(entity: EventInterface): void {
    super.handleAfterGetEntityDetail(entity)
  };

  redirectToEvents(): void {
    if (this.widget_in_live_edit_mode) return;

    const isUserLoggedIn = this.sessionService.isLogin;
    const isTokenExpired = this.sessionService.isTokenExpired();

    if ((isUserLoggedIn && !isTokenExpired) || !this.id) {
      const event = this.sessionService.appendLanguagePath(
        `${environment.jipUrl.event}/${this.id}/?${UrlParam.RegisterEvent}=true`
      );

      FormUtils.navigateTo(event);
    }
  }
}
