<form class="enforce-profile" *ngIf="form && entity" [formGroup]="form">
  <app-form-section
    #enforceProfileFormSection
    [index]="0"
    [showEditButton]="false"
    [canSave]="false"
    [saveSectionCallback]="saveSection.bind(this)"
    (saveSection)="markOrgFormAsTouched()"
    (cancelSection)="
      cancelSection($event);
      cancelCompany(
        $event,
        organizer.resetCompanyInput.bind(organizer),
        'LeadCompany'
      )
    "
    (editIndexChange)="markEditingSectionIndex($event)"
  >
    <ng-container *ngIf="!entity.image.url">
      <div
        class="position-relative w-50"
        [ngClass]="{
          'd-none': showUploadImageSection
        }"
      >
        <div
          class="venture__image-upload bg-primary btn-primary enforce-profile-upload-btn"
          (click)="toggleUpload()"
        >
          <div class="jip__container pointer-cursor">
            <i
              *ngIf="!isUploading"
              class="fas fa-camera fa-lg align-middle fa-image text-white fill-container small-image-upload-btn"
            ></i>

            <i
              *ngIf="isUploading"
              class="fas fa-spinner fa-spin m-0 fa-lg align-middle fa-image text-white fill-container small-image-upload-btn"
            ></i>
          </div>
        </div>

        <div class="'widget__image-wrapper profile-image-wrapper">
          <img
            *ngIf="image?.value?.url"
            [src]="image?.value?.url"
            [alt]="form.value?.title"
          />
        </div>
      </div>

      <div [ngClass]="{ 'd-none': !showUploadImageSection }">
        <app-image-input
          [aspectRatio]="1"
          [showDialogHeader]="false"
          [closeDialogOnCrop]="false"
          [useSmallDropZone]="true"
          (uploadSuccess)="onSelectFile({ target: { files: [$event] } })"
        ></app-image-input>
      </div>
    </ng-container>

    <div class="mt-2">
      <app-form-field-custom
        [formInput]="form"
        [editable]="true"
        [showLabel]="true"
        [type]="allType.StringSingle"
        [attributeDescription]="getFieldOptions('JobTitle')"
      >
      </app-form-field-custom>
    </div>

    <div class="row mt-2">
      <div class="col">
        <app-organizer
          #organizer
          class="w-100"
          deactivatedOrgIcon="fas fa-times"
          deactivatedOrgLabel="UI.LeadOrgUnavailable"
          [isFromPeople]="true"
          [leadCompany]="form.getRawValue()?.LeadCompany"
          [searchMode]="orgsSearchMode"
          [isPending]="
            entity?.leadCompany?.isPending &&
            entity?.leadCompany?.id == form.value?.LeadCompany?.id
          "
          [isOwner]="isOwner"
          [disabledSection]="false"
          [childClass]="'col-12 col-lg-6'"
          [labelClass]="'venture__label--head'"
          [labelSectionName]="getFieldOptions('Company')?.displayName"
          [editing]="!entity?.leadCompany"
          [(isLinkedFromParent)]="isLinkedToExistingOrg"
          [markOrgFormTouched]="markOrgFormTouched"
          [userId]="profile?.id"
          [otherOrganisationIds]="searchIgnoredLeadOrgIds"
          (orgFormChange)="updateForm($event)"
          (orgFormInvalidChanges)="updateLeadOrgFormError($event)"
          (isLinkedFromParentChange)="toggleExistingOrgLinking($event)"
          [nameAttributeDescription]="getFieldOptions('Company')"
          [logoAttributeDescription]="getFieldOptions('CompanyLogo')"
          [urlAttributeDescription]="getFieldOptions('CompanyLink')"
          [leadCompanyAttribute]="getFieldOptions('LeadCompany')"
          [showRequireHint]="true"
        >
          <div
            class="form-group"
            [class]="'col-12 col-lg-6'"
            *ngIf="organizer.isExternalOrg"
          >
            <app-form-field-custom
              class="group-item group-input"
              [formInput]="form"
              [editable]="true"
              [showLabel]="true"
              [attributeDescription]="getFieldOptions('CompanySize')"
            >
            </app-form-field-custom>
          </div>
        </app-organizer>

        <div *ngIf="showLeadOrgError()" class="alert alert-danger">
          <span>{{ 'UI.Common.Lead-Org-is-required' | translate }}</span>
        </div>
      </div>
    </div>
  </app-form-section>
</form>

<div class="modal-footer">
  <div class="d-flex flex-column w-100">
    <div class="d-flex flex-row justify-content-center">
      <button
        [disabled]="!canSaveForm || isSaving"
        type="button"
        class="btn btn-primary ml-4"
        (click)="onSave()"
        translate
      >
        <i *ngIf="isSaving" class="fas fa-spinner fa-spin mr-1"></i>
        Save
      </button>
    </div>
  </div>
</div>
