import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { BaseHttpService } from '@core/http/base-http.service';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { DropdownItemInterface } from '@shared/components/boostrap-dropdown/boostrap-dropdown.component';
import { EntityName } from '@shared/enums/entity-name.enum';
import { InfiniteScrollDataAdapter } from '@shared/helpers/infinite-scroll-data.adapter';
import {
  AttributeDescription,
  CustomAtrributeValue,
  LinkItem,
} from '@shared/interfaces/attribute-description.interface';
import { Entity } from '@shared/models/entity.model';
import { EditorComponent } from '@tinymce/tinymce-angular';

declare var formbricks: any;
declare var grapesjs: any;

@Component({
  selector: 'app-rich-editor',
  templateUrl: './rich-editor.component.html',
  providers: [],
  styles: [],
})
export class RichEditorComponent implements OnInit {
  editor: any = null;
  editorInstance: any;

  @Input() formInput: UntypedFormGroup = new UntypedFormGroup({});
  @Input() controlName: string;
  @Input() attributeDescription: AttributeDescription;
  @Input() selectionChoices: CustomAtrributeValue[];

  @Input() type: string;

  @Input() showLabel = false;
  @Input() labelClass = '';

  @Input() editable = true;
  @Input() selectedIndex = 0;
  @Input() currentValue;
  @Input() hideLink = false;
  @Input() shouldValidateOnTouch = false;
  @Input() styleClass = '';
  @Input() datepickerPlacement: PlacementArray;

  @Input() allowCropImg = false;
  @Input() aspectRatio = 1;

  @Input() showLanguageIndicator = false;

  @Input() language: string;
  @Input() customUploadButtonTemplate: TemplateRef<void>;

  @Input() displayFileLoading = true;

  @Input() disabled = false;

  @Input() httpService: BaseHttpService<Entity>;

  searchData$: InfiniteScrollDataAdapter;

  @Input() entityName: EntityName;
  @Input() options: {
    id?: number;
    placeholder?: string;
    defaultLabel?: string;
    dropdownItems?: DropdownItemInterface[];
    defaultItem?: CustomAtrributeValue;
    pastTime?: boolean;
    futureTime?: boolean;
    isViewAsText?: boolean;
    hideTimeOfDate?: boolean;
    shouldUseDefaultDate?: boolean;
    replaceAsStarKey?: string;
    enabledAddNew?: boolean;
    fixedItems?: LinkItem[];
    areRowsLimited?: boolean;
    isWideView?: boolean;
    showHiddenMarkBackground?: boolean;
    isOptional?: boolean;
    elementId?: string;

    showLanguageIndicator?: boolean;
    showLanguageSwitcher?: boolean;

    // TODO: Need Refactor -> Handle for custom attribute section first, apply for all form later
    readonly?: boolean;
    // TODO: Need Refactor -> Should use SystemType of attribute type Image, remember to check with DocumentType
    multiLocale?: boolean;
    allowEmojiOnRTE?: boolean;
    allowImgOnRTE?: boolean;
    hideQuillToolbar?: boolean;
    preventDisableControl?: boolean;
    isReplacedByStar?: boolean;
    allowCropImg?: boolean;
    aspectRatio?: number;
    smartTyping?: boolean;
    checkDuplicatedName?: boolean;
    hasTagSuggestions?: boolean;
    defaultDateTime?: string;
  } = {};

  @Output() valueChange = new EventEmitter<any>();

  useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  isSmallScreen = window.matchMedia('(max-width: 1023.5px)').matches;
  init: EditorComponent['init'] = {
    base_url: '/wp-content/themes/jip/assets/js/integration/tinymce/',
    suffix: '.min',
    selector: 'textarea#open-source-plugins',
    plugins:
      'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion template',
    editimage_cors_hosts: ['picsum.photos'],
    // menubar: 'file edit view insert format tools table help',
    menubar: false,
    toolbar:
      'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image podcastEmbed | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl | template',
    file_picker_callback: this.filePickerCallback.bind(this),
    autosave_ask_before_unload: true,
    autosave_interval: '30s',
    autosave_prefix: '{path}{query}-{id}-',
    autosave_restore_when_empty: false,
    autosave_retention: '2m',
    image_advtab: true,
    link_list: [{ title: 'My page 1', value: 'https://www.tiny.cloud' }],
    image_list: [{ title: 'My page 1', value: 'https://www.tiny.cloud' }],
    image_class_list: [
      { title: 'None', value: '' },
      { title: 'Some class', value: 'class-name' },
    ],
    importcss_append: true,
    height: 600,
    image_caption: true,
    quickbars_selection_toolbar:
      'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
    noneditable_class: 'mceNonEditable',
    toolbar_mode: 'sliding',
    contextmenu: 'link image table',
    skin: this.useDarkMode ? 'oxide-dark' : 'oxide',
    content_css: this.useDarkMode ? 'dark' : 'default',
    promotion: false,
    content_style:
      'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
    templates: [
      {
        title: 'Date modified example',
        description:
          'Adds a timestamp indicating the last time the document modified.',
        content:
          '<p>Last Modified: <time class="mdate">This will be replaced with the date modified.</time></p>',
      },
      {
        title: 'Replace values example',
        description:
          'These values will be replaced when the template is inserted into the editor content.',
        content: '<p>Name: {$username}, StaffID: {$staffid}</p>',
      },
      {
        title: 'Replace values preview example',
        description:
          'These values are replaced in the preview, but not when inserted into the editor content.',
        content:
          '<p>Name: {$preview_username}, StaffID: {$preview_staffid}</p>',
      },
      {
        title: 'Replace values preview and content example',
        description:
          'These values are replaced in the preview, and in the content.',
        content: '<p>Name: {$inboth_username}, StaffID: {$inboth_staffid}</p>',
      },
    ],
    automatic_uploads: true,
    file_picker_types: 'image media',
    media_url_resolver: (data, resolve) => {
      const youtubeRegex =
        /https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/g;
      const spotifyRegex =
        /https:\/\/open\.spotify\.com\/episode\/([a-zA-Z0-9]+)/g;
      const vimeoRegex = /https:\/\/vimeo\.com\/([0-9]+)/g;
      const dailymotionRegex =
        /https:\/\/www\.dailymotion\.com\/video\/([a-zA-Z0-9]+)/g;

      const convertedContent = data.url
        .replace(youtubeRegex, (match, p1) => {
          return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${p1}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        })
        .replace(spotifyRegex, (match, p1) => {
          return `<iframe src="https://open.spotify.com/embed/episode/${p1}?utm_source=oembed" width="100%" height="152" frameborder="0" allow="encrypted-media"></iframe>`;
        })
        .replace(vimeoRegex, (match, p1) => {
          return `<iframe src="https://player.vimeo.com/video/${p1}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;
        })
        .replace(dailymotionRegex, (match, p1) => {
          return `<iframe frameborder="0" width="480" height="270" src="https://www.dailymotion.com/embed/video/${p1}" allowfullscreen allow="autoplay"></iframe>`;
        });

      resolve({ html: convertedContent });
    },
  };

  ngOnInit(): void {}

  onEditorInit(event: any): void {
    this.editorInstance = event.editor; // Save a reference to the editor instance
  }

  filePickerCallback(
    cb: (url: string, meta: any) => void,
    value: string,
    meta: any
  ) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');

    input.addEventListener('change', (e) => {
      const fileInput = e.target as HTMLInputElement;
      if (fileInput.files) {
        const file = fileInput.files[0];

        const reader = new FileReader();
        reader.addEventListener('load', () => {
          if (typeof reader.result === 'string') {
            const id = 'blobid' + new Date().getTime();
            const blobCache = this.editorInstance.editorUpload.blobCache;
            const base64 = reader.result.split(',')[1];
            const blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            cb(blobInfo.blobUri(), { title: file.name });
          }
        });
        reader.readAsDataURL(file);
      }
    });

    input.click();
  }

  get formControl(): AbstractControl {
    if (this.formInput) {
      return this.formInput.controls[this.controlName];
    }
  }

  get isInvalid(): boolean {
    return (
      this.formControl &&
      this.formControl.invalid &&
      (!this.shouldValidateOnTouch ||
        this.formControl.dirty ||
        this.formControl.touched) &&
      this.isManageError()
    );
  }

  isManageError(): boolean {
    return (
      this.formControl.hasError('required') ||
      this.formControl.hasError('maxlength') ||
      this.formControl.hasError('integerOnly') ||
      this.formControl.hasError('pattern') ||
      this.formControl.hasError('notEmptyOrWhitespace') ||
      this.formControl.hasError('duplicate') ||
      this.formControl.hasError('nameDuplicated')
    );
  }
}
