import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { PeopleActionEnum } from '@src/app/shared/enums/people.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { PeopleAction } from '@src/app/shared/interfaces/people.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { ArrayUtils } from '@src/app/shared/utils/array-utils';
import { PeopleCardsComponent } from '../people-cards.component';

@Component({
  selector: 'app-stakeholder-cards',
  templateUrl: './stakeholder-cards.component.html',
  styles: [],
})
export class StakeholderCardsComponent
  extends PeopleCardsComponent
  implements OnChanges
{
  @Input() toItems: UserInterface[] = [];
  @Input() entityId: number;
  @Input() hideProfileImage = false;
  
  @Output() itemsRoleUpdate = new EventEmitter();
  @Output() toItemsChange = new EventEmitter();
  @Output() stackHolderClicked = new EventEmitter();

  extraActions = [
    {
      action: PeopleActionEnum.Promote,
      label: 'Initiator',
      tooltip: 'UI.Promote-To-Initiator-Hint',
      faIcon: 'fas fa-user-check',
    },
  ];

  promoteStakeholder(peopleAction: PeopleAction): void {
    const person = peopleAction.people;
    if (person && peopleAction.action === PeopleActionEnum.Promote) {
      this.httpService
        .promoteStakeholder(this.entityId, person.id)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          const itemsRoleUpdate = ArrayUtils.removeItem(
            this.items,
            person,
            'id'
          );
          this.itemsRoleUpdate.emit(itemsRoleUpdate);

          this.toItems = this.toItems ?? [];
          const toItemsChange = [...this.toItems, person];
          this.toItemsChange.emit(toItemsChange);

          this.setDisplayItems();
        });
    }
  }

  updateDirectPeopleRole(
    person: UserInterface,
    ignoreValidateRole = false
  ): void {
    const isAdmin = false;
    this.httpService
      .updatePeopleRole(this.entityId, person, isAdmin, ignoreValidateRole)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        if (res && typeof res === 'string') {
          this.openConfirmIgnoreValidateRoleDialog(
            person,
            'UI.Venture.ConfirmEditRoleStudentFromAnotherVenture'
          );
        } else {
          this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
        }
      });
  }

  confirmToIgnoreValidateRole(person: UserInterface): void {
    this.updateDirectPeopleRole(person, true);
  }

  onStakeHolderClick(): void {
    alert('emit stake holder click');
    this.stackHolderClicked.emit();
  }
}
