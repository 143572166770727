import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/http/base-http.service';
import { transformToTagModaByKey } from '@shared/components/tag-field/tag-field.component';
import { OrganizationInterface } from '@shared/interfaces/organization.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { MetaInformation } from '@src/app/shared/interfaces/attribute-description.interface';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrganizationHttpService extends BaseHttpService<OrganizationInterface> {
  create(dto: any): Observable<OrganizationInterface> {
    if (!dto) {
      return of(null);
    }
    let url = `${this.apiUrl}/${dto.entityCreateFromOrgId ?? ''}`;
    url = url.replace(/\/$/, ''); // Remove trailing slash

    return this.http.post<OrganizationInterface>(url, dto);
  }

  getAttributeDescription(params): Observable<MetaInformation> {
    return super.getAttributeDescription(params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getApiRootPath(): string {
    return 'organizations';
  }

  gets(params = null): Observable<OrganizationInterface[]> {
    return super.gets(params).pipe(
      map((pagedList: any) => {
        return pagedList.items.map((org: OrganizationInterface) =>
          this.tranformData(org)
        );
      })
    );
  }

  read(
    id: number,
    headers?: HttpHeaders,
    params?: HttpParams
  ): Observable<OrganizationInterface> {
    return super
      .read(id, headers, params)
      .pipe(map((org) => this.tranformData(org)));
  }

  paginate(
    params: HttpParams = null,
    innerPath = null
  ): Observable<ApiGetResponse<OrganizationInterface>> {
    return super.paginate(params, innerPath).pipe(
      map((res: ApiGetResponse<OrganizationInterface>) => {
        return super.handlePaginateResponse(res);
      })
    );
  }

  paginateX(
    params: any,
    innerPath = null
  ): Observable<ApiGetResponse<OrganizationInterface>> {
    return super.paginateX(params, innerPath).pipe(
      map((res: ApiGetResponse<OrganizationInterface>) => {
        return super.handlePaginateResponse(res);
      })
    );
  }

  checkPartnerOrganizationTeam(orgChallengeId: number): Observable<boolean> {
    if (orgChallengeId && !isNaN(orgChallengeId)) {
      const url = `${this.apiUrl}/check-partner-organization-team`;
      return this.http.get<boolean>(url, {
        params: { id: orgChallengeId.toString() },
      });
    }
    return of(false);
  }

  tranformData(org: OrganizationInterface): OrganizationInterface {
    super.tranformData(org);
    if (org) {
      org.expertisesTag = this.getExpertiesTags(org);
      org.keyOfferingTags = this.transformKeyOfferingTags(org);
    }
    return org;
  }

  paginateAsPost(
    params: any = null,
    innerPath: any = null,
    queryParam = null
  ): Observable<ApiGetResponse<OrganizationInterface>> {
    return super.paginateAsPost(params, innerPath, queryParam).pipe(
      map((res: ApiGetResponse<OrganizationInterface>) => {
        return this.handlePaginateResponse(res);
      })
    );
  }

  private getExpertiesTags(
    item: OrganizationInterface
  ): { text: string; weight: number }[] {
    if (item?.expertises) {
      return item.expertises.map((expertise) => {
        return {
          text: expertise.description,
          weight: expertise.priority + 10,
        };
      });
    }
    return [];
  }

  private transformKeyOfferingTags(
    item: OrganizationInterface
  ): { display: string; value: number; readonly?: boolean }[] {
    if (item.keyOfferings) {
      return transformToTagModaByKey(
        item.keyOfferings,
        true,
        'description',
        'description'
      );
    }
    return [];
  }

  checkDuplicateName(name: string): Observable<unknown> {
    const url = `${this.apiUrl}/check-org-name-exist`;
    return this.http.get(url, {
      params: {
        name,
      },
    });
  }

  getPartnerOrgs(params): Observable<ApiGetResponse<OrganizationInterface>> {
    const url = `partner-org`;

    return this.paginateX(params, url);
  }

  deactivateOrg(id) {
    const url = `${this.apiUrl}/${id}/deactivate`;

    return this.http.delete(url);
  }

  updatePartnerOrganizations(id, payload) {
    const url = `${this.apiUrl}/${id}/update-partner-org`;

    return this.http.post(url, payload);
  }

  getPopularOrgs(params) {
    const url = `${this.apiUrl}/popular-org`;
    return this.http.get(url, { params });
  }

  updateOrgCommunity(id, payload) {
    const url = `${this.apiUrl}/${id}/update-org-community`;
    return this.http.post(url, payload);
  }
}

export const ORGANIZATION_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES = [
  'ResidentOf',
  'InnovationFocus',
  'Competency',
  'CompanyType',
  'CountryOfOrigin',
];
