<div
  [ngClass]="{
    'd-flex align-items-center flex-wrap': horizontalSingleSelection
  }"
>
  <ng-container *ngIf="editable; else viewAsTextRef">
    <ng-container *ngFor="let item of items; first as isFirst">
      <div
        class="organization__radio-item mb-3"
        [ngClass]="{
          'ml-4': horizontalSingleSelection && !isFirst
        }"
      >
        <input
          appRequireLogin
          [value]="item.codeId"
          class="organization__radio"
          [id]="item.codeId"
          [name]="controlName"
          [checked]="item.codeId === items[currentIndex].codeId"
          type="radio"
          [class.disabled-input]="!editable"
          (change)="onRadioChange($event, item)"
        />
        <label
          class="radio-info d-flex flex-column"
          [for]="item.codeId"
          [class.disabled-action]="!editable"
        >
          <span class="radio-text">
            {{ item.value }}
          </span>
        </label>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #viewAsTextRef>
  {{ items[currentIndex]?.value }}
</ng-template>
