import { Component, Input } from '@angular/core';
import { PopupBlockerDetectionService } from '@src/app/core/services/popup-blocker-detection.service';

@Component({
  selector: 'app-popup-blocker-detection-notification',
  templateUrl: './popup-blocker-detection-notification.component.html',
})
export class PopupBlockerDetectionNotificationComponent {
  @Input() guideUrl = '';
  isPopupBlocked$ = this.popupBlockerDetectionService.isPopupBlocked$;

  constructor(
    private popupBlockerDetectionService: PopupBlockerDetectionService
  ) {}

  removeNotification() {
    this.popupBlockerDetectionService.isPopupBlocked$.next(false);
  }
}
