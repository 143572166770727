import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { SessionService } from '@src/app/core/session.service';
import { PeopleActionEnum } from '@src/app/shared/enums/people.enum';
import {
  PeopleAction,
  PeopleInterface,
} from '@src/app/shared/interfaces/people.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { MetadataTableComponent } from '../metadata-table.component';

@Component({
  selector: 'app-metadata-table-people',
  templateUrl: '../metadata-table.component.html',
})
export class MetadataTablePeopleComponent extends MetadataTableComponent<PeopleInterface> {
  entityName = this.EntityName.Person;
  peopleFilterPropertyName: string[] = [
    'Id',
    'DateOfBirth',
    'JobTitle',
    'LastName',
    'MiddleName',
    'FirstName',
    'Image',
    'Location',
    'Gender',
    'Company',
    'CompanyLink',
    'CompanyLogo',
    'CompanySize',
    'Education',
    'MotivationStatement',
    'Phone',
  ];
  readonlyField = ['Id'];

  @Output() peoplePromoted = new EventEmitter<PeopleAction>();
  @Output() peopleRemoved = new EventEmitter<UserInterface>();

  constructor(
    protected sessionService: SessionService,
    public baseHttpService: EventHttpService,
    protected cd: ChangeDetectorRef
  ) {
    super(sessionService, baseHttpService, cd);
  }

  onPromoteAdmin(id): void {
    const entity = this.getEntityById(id);
    const peopleAction = {
      action: PeopleActionEnum.Promote,
      people: entity,
    };

    this.peoplePromoted.emit(peopleAction);
  }

  onRemovePerson(id): void {
    const entity = this.getEntityById(id);
    this.peopleRemoved.emit(entity);
  }

  protected getDefaultLstFilterPropertyName(): string[] {
    return this.peopleFilterPropertyName;
  }
}
