import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenService } from '@core/authen/authen.service';
import {
  CheckEntityStateCountInterface,
  CheckOrganizationAdminState,
} from '@shared/interfaces/check-entity-state-count.interface';
import { LikeInterface } from '@shared/interfaces/like.interface';
import { StringUtils } from '@shared/utils/string-utils';
import { JoinInRequestInterface } from '@src/app/components/dialogs/join-in-dialog/join-in-dialog.component';
import {
  EntityName,
  EntityStateCount,
} from '@src/app/shared/enums/entity-name.enum';
import {
  AttributeDescription,
  AttributeValuePatchDto,
  MetaInformation,
} from '@src/app/shared/interfaces/attribute-description.interface';
import { PrivateInfo } from '@src/app/shared/interfaces/base/base-entity-edit.interface';
import { CommentInterface } from '@src/app/shared/interfaces/comment.interface';
import { FollowerInterface } from '@src/app/shared/interfaces/follower.interface';
import { JoinState } from '@src/app/shared/interfaces/join.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { StreamOption } from '@src/app/shared/interfaces/stream.interface';
import {
  TimestampCreatePayload,
  TimestampResponse,
} from '@src/app/shared/interfaces/timestamp.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseHttpService<T> {
  apiUrl = '';
  attributesObsever$: ReplaySubject<any>;
  submissionAttributeObserver: ReplaySubject<any>;

  constructor(
    protected http: HttpClient,
    protected authenService: AuthenService
  ) {
    this.apiUrl = this.getApiRootPath();
  }

  tranformData(item): T {
    return item;
  }

  getApiRootPath(): string {
    return '';
  }

  read(id: number, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    if (!id) return of(null);

    const url = `${this.apiUrl}/${id}`;

    return this.http
      .get<T>(url, { headers, params })
      .pipe(map((item) => this.tranformData(item)));
  }

  create(dto: any, params: any = {}): Observable<T> {
    if (dto) {
      const url = `${this.apiUrl}`;
      return this.http.post<T>(url, dto, { params });
    } else {
      return of(null);
    }
  }

  update(id, dto: T): Observable<T> {
    if (dto) {
      const url = `${this.apiUrl}/${id}`;
      return this.http.put<T>(url, dto);
    } else {
      return of(null);
    }
  }

  patch(
    id: number,
    dto: T,
    headers?: HttpHeaders,
    params?: HttpParams
  ): Observable<T> {
    if (dto) {
      const dtoArray = [];

      for (const key of Object.keys(dto)) {
        dtoArray.push({
          value: dto[key],
          path: key,
          op: 'replace',
        });
      }
      const url = `${this.apiUrl}/${id}`;

      return this.http.patch<T>(url, dtoArray, { headers, params });
    } else {
      return of(null);
    }
  }

  delete(id: number): Observable<boolean> {
    if (id) {
      const url = `${this.apiUrl}/${id}`;
      return this.http.delete<boolean>(url);
    } else {
      return of(false);
    }
  }

  updateCustomAttribute(
    id: number,
    dto: any,
    headers?: HttpHeaders
  ): Observable<any> {
    if (dto) {
      const dtoCustomAttributeArray = {
        values: [],
      };

      for (const key of Object.keys(dto)) {
        const attrVal: AttributeValuePatchDto = {
          propertyName: StringUtils.toUpperCaseFirstLetter(key),
          describedValue: {
            value: dto[key],
          },
        };
        dtoCustomAttributeArray.values.push(attrVal);
      }
      const url = `${this.apiUrl}/${id}/attributes`;
      return this.http.post<any>(url, dtoCustomAttributeArray, { headers });
    } else {
      return of(null);
    }
  }

  search(term: string): Observable<T[]> {
    if (term) {
      term = term.trim();
      const options = term
        ? { params: new HttpParams().set('name', term) }
        : {};
      return this.http.get<T[]>(this.apiUrl, options);
    } else {
      return of(null);
    }
  }

  getByEntity(entityId, entityPath = ''): Observable<any> {
    const url = `${this.apiUrl}/${entityId}${entityPath}`;
    return this.http.get<any>(url);
  }

  putByEntity(entityId, dto, entityPath = ''): Observable<any> {
    const url = `${this.apiUrl}/${entityId}${entityPath}`;
    return this.http.put<ApiGetResponse<T>>(url, dto);
  }

  postByEntity(entityId, dto, entityPath = ''): Observable<any> {
    const url = `${this.apiUrl}/${entityId}${entityPath}`;
    return this.http.post<ApiGetResponse<T>>(url, dto);
  }

  gets(params: HttpParams = null): Observable<T[]> {
    return this.http.get<T[]>(this.apiUrl, { params }).pipe(
      map((res: any) => {
        return res.items
          ? res.items.map((item: T) => this.tranformData(item))
          : res;
      })
    );
  }

  paginate(
    params: HttpParams = null,
    innerEntity = null
  ): Observable<ApiGetResponse<T>> {
    return this.handleBasePaginate(params, innerEntity);
  }

  paginateX(params: any, innerEntity = null): Observable<ApiGetResponse<T>> {
    return this.handleBasePaginate(params, innerEntity);
  }

  paginateAsPost(
    params: any,
    innerEntity = null,
    queryParam = null
  ): Observable<ApiGetResponse<T>> {
    const url = innerEntity ? this.apiUrl + '/' + innerEntity : this.apiUrl;

    return this.http
      .post<ApiGetResponse<T>>(`${url}/list`, params, { params: queryParam })
      .pipe(
        map((res: ApiGetResponse<T>) => {
          return this.handlePaginateResponse(res);
        })
      );
  }

  checkEntityStateCount(
    entityName: string,
    ids: any[],
    entityStateCount: string
  ): Observable<CheckEntityStateCountInterface[]> {
    if (
      entityName === EntityName.Challenge &&
      entityStateCount !== EntityStateCount.Comment
    ) {
      entityName = EntityName.Organization;
    }
    let url = '';
    switch (entityStateCount) {
      case EntityStateCount.Liking:
        url = `likings/check-liked/${entityName}`;
        break;
      case EntityStateCount.Following:
        url = `followings/check-followed/${entityName}`;
        break;
      case EntityStateCount.Comment:
        url = `comments/check-comments/${entityName}`;
        break;
      case EntityStateCount.Venture:
        url = `ventures/check-ventures/${entityName}`;
        break;
      case EntityStateCount.Event:
        url = `events/check-events/${entityName}`;
        break;
      case EntityStateCount.Participant:
        url = `events/participants/check-number`;
        break;

      default:
        return;
    }
    if (ids?.length > 0) {
      const params =
        entityStateCount === EntityStateCount.Participant ||
        entityStateCount === EntityStateCount.Comment
          ? { ids }
          : { id: ids };
      return this.http.get<CheckEntityStateCountInterface[]>(url, { params });
    }
    return of([]);
  }

  checkUserIsOrgAdminOfEntity(
    ids: number[]
  ): Observable<CheckOrganizationAdminState[]> {
    const url = `${this.apiUrl}/check-organization-admin`;
    const convertIdsToString = ids.map((id) => id.toString());
    return this.http.get<CheckOrganizationAdminState[]>(url, {
      params: { ids: convertIdsToString },
    });
  }

  getCustomAttributeDescription(
    leadCompanyId: string,
    submittedOrganizations: string[] = []
  ): Observable<AttributeDescription[]> {
    const dto = {};
    if (leadCompanyId) {
      const appendLeadCompanyId = 'LeadCompanyId';
      dto[appendLeadCompanyId] = leadCompanyId;
    }
    if (submittedOrganizations.length > 0) {
      const appendSubmittedOrganization = 'SubmittedOrganization';
      dto[appendSubmittedOrganization] = submittedOrganizations;
    }
    return this.http.get<AttributeDescription[]>(`${this.apiUrl}/attributes`, {
      params: dto,
    });
  }

  getFilter(
    entityName: string,
    organizationId: string
  ): Observable<AttributeDescription[]> {
    const dto = {
      EntityName: entityName,
    };
    if (organizationId) {
      // tslint:disable-next-line: no-string-literal
      dto['OrganizationId'] = organizationId;
    }
    return this.http.get<AttributeDescription[]>('filters', { params: dto });
  }

  getJoinStates(ids: string[]): Observable<JoinState[]> {
    const url = `${this.apiUrl}/stakeholders/check-join`;
    if (ids?.length > 0) {
      return this.http.get<JoinState[]>(url, { params: { ids } });
    }
    return of([]);
  }

  //#region HANDLE PARTNER ORG
  getPartnerOrganizationAttribute(): Observable<AttributeDescription[]> {
    const url = `${this.apiUrl}/attributes/partner-organization`;
    return this.http.get<AttributeDescription[]>(url);
  }
  //#endregion End Handle Partner Org

  paginateByEntity(
    entityPath: string,
    entityId: number,
    params = null,
    entityUrl?: string
  ): Observable<ApiGetResponse<T>> {
    const url = `${entityUrl || this.apiUrl}/${entityId}${entityPath}`;
    params = params || new HttpParams();

    return this.http.get<ApiGetResponse<T>>(url, { params });
  }

  getAttributeDescription(
    params = new HttpParams()
  ): Observable<MetaInformation> {
    if (!this.attributesObsever$) {
      this.attributesObsever$ = new ReplaySubject<MetaInformation>();
      this.loadAttributeDescription(params).subscribe((data) => {
        this.attributesObsever$.next(data);
      });
    }
    return this.attributesObsever$.asObservable();
  }

  getSubmissionAttributes(
    params = new HttpParams()
  ): Observable<MetaInformation> {
    if (!this.submissionAttributeObserver) {
      this.submissionAttributeObserver = new ReplaySubject<MetaInformation>();
      this.loadAttributeDescription(params, 'submission-attributes').subscribe(
        (data) => this.submissionAttributeObserver.next(data)
      );
    }
    return this.submissionAttributeObserver.asObservable();
  }

  paginatePendingStakeHolders(
    entityId: number,
    params: HttpParams
  ): Observable<ApiGetResponse<UserInterface>> {
    if (entityId) {
      const url = `${this.apiUrl}/${entityId}/stakeholders/pending`;
      params = params || new HttpParams();
      return this.http.get<ApiGetResponse<UserInterface>>(url, { params });
    } else {
      return of(null);
    }
  }

  approveStakeHolder(
    entityId: number,
    person: UserInterface,
    ignoreValidateRole = false
  ): Observable<any> {
    if (!person) {
      return of(null);
    }
    const dto = {
      entityId: person.id,
      role: person.role?.codeId,
      ignoreValidateRole,
    };
    return this.putByEntity(entityId, dto, '/stakeholder/approve');
  }

  getComments(id): Observable<CommentInterface[]> {
    return this.http.get<CommentInterface[]>(`${this.apiUrl}/${id}/comments`);
  }

  getDemands(): any {
    return [];
  }

  paginateFollowers(
    id: number,
    params: HttpParams,
    apiPath = 'followings'
  ): Observable<ApiGetResponse<FollowerInterface>> {
    if (id) {
      const url = `${this.apiUrl}/${id}/${apiPath}`;
      params = params || new HttpParams();

      return this.http
        .get<ApiGetResponse<FollowerInterface>>(url, { params })
        .pipe(
          map((res: ApiGetResponse<FollowerInterface>) => {
            res.items = res.items.map((item) => {
              const follower = item.owner;
              if (follower) {
                const { firstName, lastName } = follower;

                if (firstName && lastName) {
                  follower.name = `${firstName} ${lastName}`;
                } else if (firstName) {
                  follower.name = firstName;
                } else if (lastName) {
                  follower.lastName = lastName;
                }
              }

              return item;
            });

            return res;
          })
        );
    } else {
      return of(null);
    }
  }

  paginateLikes(
    id: number,
    params: HttpParams,
    apiPath = 'likings'
  ): Observable<ApiGetResponse<LikeInterface>> {
    if (id) {
      const url = `${this.apiUrl}/${id}/${apiPath}`;
      params = params || new HttpParams();

      return this.http.get<ApiGetResponse<LikeInterface>>(url, { params }).pipe(
        map((res: ApiGetResponse<LikeInterface>) => {
          res.items = res.items.map((item) => {
            const liker = item.owner;
            if (liker) {
              const { firstName, lastName } = liker;

              if (firstName && lastName) {
                liker.name = `${firstName} ${lastName}`;
              } else if (firstName) {
                liker.name = firstName;
              } else if (lastName) {
                liker.lastName = lastName;
              }
            }

            return item;
          });

          return res;
        })
      );
    } else {
      return of(null);
    }
  }

  private loadAttributeDescription(
    params = new HttpParams(),
    path = 'attributes'
  ): Observable<MetaInformation> {
    return this.http
      .get<AttributeDescription[]>(`${this.apiUrl}/${path}`, { params })
      .pipe(
        map((items) => {
          const result: MetaInformation = {
            entityDescription: { attributeDescriptions: items },
            relatedEntitiesDescription: null,
          };
          return result;
        })
      );
  }

  checkSubmitVenture(challengeId: number): Observable<any> {
    if (challengeId) {
      const url = `challenges/${challengeId}/check-submit-venture`;
      return this.http.get(url);
    } else {
      return of(null);
    }
  }

  checkDuplicateName(name: string) {
    return new Observable();
  }

  checkPrivateEntity(id: number): Observable<PrivateInfo> {
    return of({
      isMember: true,
      isPrivate: true,
    });
  }

  //#region HANDLE PARTICIPANTS
  demoteInitiator(entityId: number, id: number): Observable<any> {
    const dto = {
      initiatorId: id,
    };
    return this.postByEntity(entityId, dto, '/demote-admin');
  }

  promoteStakeholder(entityId: number, personId: number): Observable<any> {
    const dto = {
      stakeholderId: personId,
    };
    return this.postByEntity(entityId, dto, '/promote-admin');
  }

  updatePeopleRole(
    entityId: number,
    person: UserInterface,
    isAdmin = false,
    ignoreValidateRole = false
  ): Observable<any> {
    if (!person) {
      return of(null);
    }
    const dto = {
      entityId: person.id,
      role: person.role?.codeId,
      ignoreValidateRole,
    };
    const url = isAdmin ? '/admin/role' : '/stakeholder/role';
    return this.putByEntity(entityId, dto, url);
  }

  getStakeHolderCustomAttribute(
    entityId: number
  ): Observable<AttributeDescription[]> {
    const url = `${this.apiUrl}/${entityId}/attributes/stakeholder`;
    return this.http.get<AttributeDescription[]>(url);
  }

  rejectStakeHolder(entityId: number, person: UserInterface): Observable<any> {
    if (!person) {
      return of(null);
    }
    const dto = {
      entityId: person.id,
    };
    if (person.role?.codeId) {
      const appendRole = 'role';
      dto[appendRole] = person.role?.codeId;
    }
    return this.putByEntity(entityId, dto, '/stakeholder/reject');
  }

  //#region HANDLE TIMESTAMP
  createTimestamp(
    entityId: number,
    timestampPayload: TimestampCreatePayload
  ): Observable<number> {
    if (entityId && !isNaN(entityId)) {
      const url = `${this.apiUrl}/${entityId}/timestamp`;
      return this.http.post<number>(url, timestampPayload);
    } else {
      return of(null);
    }
  }

  getTimestampById(
    timestampId: string,
    params?: HttpParams
  ): Observable<TimestampResponse<T>> {
    if (timestampId) {
      const url = `${this.apiUrl}/timestamp/${timestampId}`;
      return this.http.get<TimestampResponse<T>>(url, { params });
    } else {
      return of(null);
    }
  }

  removeMyself(myId: number): Observable<boolean> {
    const url = `${this.apiUrl}/${myId}/stakeholders/remove-myself`;
    return this.http.post<boolean>(url, {});
  }
  //#endregion End Handle Participants

  //#region HANDLE INTERACTION
  follow(id: number): Observable<any> {
    if (id) {
      const url = `${this.apiUrl}/${id}/followings`;
      return this.http.post(url, null);
    } else {
      return of(null);
    }
  }

  unfollow(id: number): Observable<any> {
    if (id) {
      const url = `${this.apiUrl}/${id}/followings`;
      return this.http.delete(url);
    } else {
      return of(null);
    }
  }

  like(id: number): Observable<number> {
    if (id) {
      const url = `${this.apiUrl}/${id}/likings`;
      return this.http.post<number>(url, null);
    } else {
      return of(null);
    }
  }

  unlike(id: number): Observable<any> {
    if (id) {
      const url = `likings/${id}`;
      return this.http.delete(url);
    } else {
      return of(null);
    }
  }

  join(id: number, dto: JoinInRequestInterface): Observable<any> {
    if (id) {
      const url = `${this.apiUrl}/${id}/stakeholders/join`;
      return this.http.post(url, dto);
    } else {
      return of(null);
    }
  }

  share(id: number, message: string): Observable<any> {
    if (id) {
      const url = `${this.apiUrl}/${id}/news`;
      const payload = {
        comment: message,
      };
      return this.http.post(url, payload);
    } else {
      return of(null);
    }
  }

  publish(id: number): Observable<any> {
    if (id) {
      const url = `${this.apiUrl}/${id}/publish`;
      return this.http.post(url, null);
    } else {
      return of(null);
    }
  }

  unpublish(id: number): Observable<any> {
    if (id) {
      const url = `${this.apiUrl}/${id}/unpublish`;
      return this.http.post(url, null);
    } else {
      return of(null);
    }
  }
  //#endregion End Handle Interaction

  protected handlePaginateResponse(res: ApiGetResponse<T>): ApiGetResponse<T> {
    const items = res?.items || [];
    items.forEach((item) => this.tranformData(item));
    return res;
  }

  private handleBasePaginate(
    params: any,
    innerEntity = null
  ): Observable<ApiGetResponse<T>> {
    const url = innerEntity ? this.apiUrl + '/' + innerEntity : this.apiUrl;

    return this.http.get<ApiGetResponse<T>>(url, { params }).pipe(
      map((res: ApiGetResponse<T>) => {
        return this.handlePaginateResponse(res);
      })
    );
  }
  //#endregion End Hanlde Timestamp

  getStreamsByEntityId(id: number): Observable<StreamOption[]> {
    const url = `${this.apiUrl}/${id}/streams`;
    return this.http.get<StreamOption[]>(url);
  }

  getStreamsByStreamId(ids: number[]): Observable<StreamOption[]> {
    const url = `${this.apiUrl}/streams`;
    return this.http.get<StreamOption[]>(url, {
      params: {
        streams: ids,
      },
    });
  }

  getSortOptions(
    entityName: string,
  ): Observable<AttributeDescription[]> {
    return this.http.get<AttributeDescription[]>(`filters/sort-options/${entityName}`);
  }
}
