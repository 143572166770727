import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-flip-words',
  templateUrl: './flip-words.component.html',
    encapsulation: ViewEncapsulation.None,
  styles: ``
})
export class FlipWordsComponent implements OnInit {
    @Input() flipWord: string[] = [];
    currentWord: string = '';
    private timer = 3000;
    private index = 0;

    ngOnInit(): void {
        this.currentWord = this.flipWord.length > 0 ? '<span class="flip-word" translate>' + this.flipWord[0] + '</span>' : '';
        setInterval(() => {
            this.getNextWord();
        }, this.timer);
    }

    getNextWord(): void {
        this.index++;
        if (this.index >= this.flipWord.length) {
            this.index = 0;
        }
        this.currentWord = '<span class="flip-word" translate>' + this.flipWord[this.index] + '</span>';
    }
}