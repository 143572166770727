import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '@components/base/base-widget/base-widget.component';
import { SearchEntitiesService } from '@components/search-results/services/search-entities.service';
import { CustomFormService } from '@core/form/custom-form.service';
import { VentureHttpService } from '@core/http/venture.http.service';
import { GlobalFilterStoredService } from '@core/services/global-filter-stored.service';
import { PaginationSettingService } from '@core/services/pagination-setting.service';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { ToBoolean } from '@shared/decorators/to-boolean';
import { GlobalEventBus } from '@shared/enums/event-bus.enum';
import { VentureSubmissionStatus } from '@shared/enums/venture.enum';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import {
  FilterPanePayload,
  VentureGlobalFilterCriteria,
} from '@shared/interfaces/filters/global-filter.interface';
import { ApiGetResponse } from '@shared/interfaces/responses/ApiResponse.interface';
import { VentureInterface } from '@shared/interfaces/venture.interface';
import {
  PageSizeConfig,
  PaginationFilterStored,
  PaginationSettingPlace,
} from '@shared/models/pagination.model';
import { StringUtils } from '@shared/utils/string-utils';
import { LoadingService } from '@src/app/core/services/loading.service';
import { TemplateName } from '@src/app/shared/constants/visibility-config.const';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { EventBusService } from 'ngx-eventbus';
import { Observable } from 'rxjs';
import { filter, first, share, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-venture-tiles',
  templateUrl: './venture-tiles.component.html',
  providers: [SearchEntitiesService],
})
export class VentureTilesComponent
  extends BaseWidgetComponent
  implements OnInit, OnDestroy
{
  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() show_open_for: boolean;

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() show_filter_pane: boolean;

  // tslint:disable-next-line:variable-name
  @Input() org_id: string;

  @Input() state: VentureSubmissionStatus;

  @Input() filterPaneUi: string;

  data$: Observable<ApiGetResponse<VentureInterface>>;

  globalPaneEvent: any;

  isLoading = false;

  skeletonCount = [];

  TemplateName = TemplateName;

  readonly EntityName = EntityName;

  readonly entityPath = environment.jipUrl.venture;

  readonly entityCreationPath = `${this.entityPath}/create`;

  constructor(
    public readonly searchEntitiesService: SearchEntitiesService,
    public readonly ventureService: VentureHttpService,
    protected readonly sessionService: SessionService,
    private readonly eventBus: EventBusService,
    private readonly filterStored: GlobalFilterStoredService,
    private readonly paginationSettingService: PaginationSettingService,
    private readonly customFormService: CustomFormService,
    private readonly loadingService: LoadingService
  ) {
    super(sessionService);

    this.data$ = this.searchEntitiesService.entities$.pipe(
      filter((arr: any) => arr.length),
      switchMap((res) => res[0].content),
      share()
    ) as Observable<ApiGetResponse<VentureInterface>>;

    this.searchEntitiesService.isSearchLoading$.subscribe((loading) => {
      this.isLoading = loading;
    });
  }

  ngOnInit(): void {
    this.configSearchService();
    this.sessionService.isOnListPage = true;
    this.listenFilterPaneEvent();
    this.skeletonCount = [...Array(Number(this.columns))];
  }

  private configSearchService(): void {
    const widgetPageSize =
      Number(this.page_size) || PageSizeConfig.ThreeItemsFirstPage;

    this.searchEntitiesService.entities = [{ key: EntityName.Venture }];
    this.searchEntitiesService.currentFilter = EntityName.Venture;
    this.searchEntitiesService.pageSizeMedium = widgetPageSize;

    // Payload
    const filterDTO = this.searchEntitiesService
      .filterDTO as VentureGlobalFilterCriteria;
    const keepExternalFilter = !this.org_id && this.isHeaderVisible;

    filterDTO.pageSize = widgetPageSize;
    filterDTO.organizationIds = this.org_id ? [Number(this.org_id)] : [];
    if (this.state) filterDTO.ventureState = this.state;

    this.searchEntitiesService.generateSearchResults(keepExternalFilter).then();
  }

  private listenFilterPaneEvent(): void {
    this.globalPaneEvent = this.eventBus.addEventListener({
      name: GlobalEventBus.FilterPaneEvent,
      callback: async () => {
        this.getVentureWithAttributes();
      },
    });
  }

  private getVentureWithAttributes(): void {
    this.filterStored
      .getFilterPaneSource()
      .pipe(first(), untilDestroyed(this))
      .subscribe((filterPaneSource) => {
        const attributeValues: FilterPanePayload[] = [];

        for (const key of Object.keys(filterPaneSource)) {
          const attrDes = this.customFormService.getCustomAttributeFieldOptions(
            StringUtils.toUpperCaseFirstLetter(key)
          );
          const dto: FilterPanePayload = {
            propertyName: StringUtils.toUpperCaseFirstLetter(key),
            attributeType:
              attrDes?.originalAttributeType || attrDes?.attributeType,
            values: filterPaneSource[key] || [],
          };
          attributeValues.push(dto);
        }

        this.searchEntitiesService.filterDTO.attributeValues = attributeValues;
        this.searchEntitiesService.filterDTO.pageIndex = 1;

        this.searchEntitiesService.refreshList();

        this.setLatestFilterPaneSourceToPaginationFilterStored(
          filterPaneSource,
          PaginationSettingPlace.VentureMainList
        ).then();
      });
  }

  protected async setLatestFilterPaneSourceToPaginationFilterStored(
    filterDto: Record<string, any>,
    paginationSettingPlace: PaginationSettingPlace
  ): Promise<void> {
    const paginationFilterStored = new PaginationFilterStored(
      filterDto || {},
      paginationSettingPlace,
      PageSizeConfig.EightItemsFirstPage,
      true
    );
    await this.paginationSettingService.setPaginationFilterStored(
      paginationFilterStored
    );
  }

  ngOnDestroy(): void {
    /**/
  }
}
