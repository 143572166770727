<div
        *ngIf="formInput && editable"
        class="form-field__body"
        [ngSwitch]="type"
        [formGroup]="formInput"
>
    <editor
            [init]="init"
            (onInit)="onEditorInit($event)"
            [formControlName]="controlName"
            class="w-100"
    >
    </editor>
</div>
