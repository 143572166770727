import { Component } from '@angular/core';
import { InternalIcon } from '@src/app/shared/enums/internal-icon.enum';

@Component({
  selector: 'app-ai-indicator',
  templateUrl: './ai-indicator.component.html',
})
export class AiIndicatorComponent {
  InternalIcon = InternalIcon;
}
