import {
  Component,
  EventEmitter,
  Input,
  OnChanges, Output,
  SimpleChanges
} from '@angular/core';
import { CustomAtrributeValue } from '@shared/interfaces/attribute-description.interface';
import { ArrayUtils } from '@shared/utils/array-utils';

@Component({
  selector: 'app-multi-selection',
  templateUrl: './multi-selection.component.html',
})
export class MultiSelectionComponent implements OnChanges {
  @Input() selectionChoices: CustomAtrributeValue[];
  @Input() selectedItems: CustomAtrributeValue[];
  @Input() editable = false;
  @Input() onlyShowSelectedOptionsInViewMode = false;
  @Input() isGridView = false;

  @Output() selectItem = new EventEmitter();

  displayItems: MulitiSelectionItem[];

  ngOnChanges(changes: SimpleChanges): void {
    this.updateItems();
  }

  private updateItems(): void {
    if (this.selectionChoices) {
      this.displayItems = this.selectionChoices.map((item) => {
        const selected = ArrayUtils.hasItem(this.selectedItems, item, 'codeId');
        return { ...item, selected };
      });
    }
  }

  toggleItem(item: MulitiSelectionItem): void {
    item.selected = !item.selected;

    this.emitOutput();
  }

  private emitOutput(): void {
    const selectedItems = this.displayItems.filter((item) => !!item.selected);
    this.selectItem.emit(selectedItems);
  }
}

export interface MulitiSelectionItem extends CustomAtrributeValue {
  selected: boolean;
}
