<div *ngIf="showDialogHeader" class="modal-header">
  <button
    (click)="activeModal.dismiss(false)"
    aria-describedby="modal-title"
    aria-label="Close"
    class="btn-close close font-size-2xl"
    type="button"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <ngx-file-drop
    (onFileDrop)="dropped($event)"
    [accept]="acceptType"
    [hidden]="imageFile"
    [multiple]="false"
    contentClassName="content"
    [dropZoneClassName]="'file-drop'"
  >
    <ng-template
      let-openFileSelector="openFileSelector"
      ngx-file-drop-content-tmp
    >
      <div
        *ngIf="useSmallDropZone"
        class="d-flex align-items-center justify-content-between pointer-cursor"
        (click)="openFileSelector()"
      >
        <i class="img-icon fas fa-images fa-2x"></i>

        <div
          class="font-size-base py-2 px-4 text-center d-flex flex-column"
          translate
        >
          <span class="text-left" translate type="button">
            {{ 'UI.Common.UploadFileLabel.Prefix' | translate }}
            {{ 'UI.Common.UploadFileLabel.Link' | translate }}
          </span>
          <span>{{ 'UI.Common.UploadFileLabel.Suffix' | translate }}</span>
        </div>
      </div>

      <div
        *ngIf="!useSmallDropZone"
        class="d-flex flex-column align-items-center"
      >
        <i class="img-icon fas fa-images fa-10x mb-4"></i>

        <div class="font-size-xl p-4 text-center" translate>
          UI.Common.UploadFileLabel.Prefix
          <span
            (click)="openFileSelector()"
            class="text-underline"
            translate
            type="button"
          >
            UI.Common.UploadFileLabel.Link</span
          > {{ 'UI.Common.UploadFileLabel.Suffix' | translate }}
        </div>
      </div>
    </ng-template>
  </ngx-file-drop>

  <angular-cropper
    *ngIf="imageFile"
    #cropperComponent
    [imageUrl]="imageUrl"
    [cropperOptions]="cropperOptions"
  >
  </angular-cropper>

  <app-range-slider
    *ngIf="imageFile"
    (ngModelChange)="onZoom($event)"
    [(ngModel)]="scale"
  ></app-range-slider>

  <div
    *ngIf="imageFile"
    class="modal-footer d-flex justify-content-center mt-3"
  >
    <button (click)="clearImg()" class="mb-0 btn btn-outline-primary">
      {{ 'Cancel' | translate | uppercase }}
    </button>

    <button
      (click)="crop()"
      class="mb-0 ml-4 btn btn-primary"
      [disabled]="isCropping"
    >
      <i *ngIf="isCropping" class="fas fa-spinner fa-spin mr-2"></i>
      {{ 'UI.Common.OK' | translate | uppercase }}
    </button>
  </div>

  <ng-container>
    <div
      *ngIf="showMaxFileSizeErrorMessage"
      class="alert alert-danger mx-3"
      translate
    >
      UI.ImageUpload.FileSizeErrorMessage
    </div>
  </ng-container>
</div>
