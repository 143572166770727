import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbDateParserFormatter, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ATTRIBUTE_TYPE_SUPPORT_EDIT_IN_TABLE } from '@shared/constants/metadata-table.const';
import {
  DEFAULT_IMAGE_CLASS_VALUE,
  MetadataRecord,
  ResizeEventInterface,
  SortEventInterface,
  TableSettingStored,
} from '@shared/interfaces/metadata-table.interface';
import { VentureInterface } from '@shared/interfaces/venture.interface';
import { SortCriteria } from '@shared/models/pagination.model';
import { BaseEntityEditComponent } from '@src/app/components/base/base-detail/base-entity-edit/base-entity-edit.component';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { SessionService } from '@src/app/core/session.service';
import { CustomDateParser2Formatter } from '@src/app/shared/components/boostrap-datepicker/boostrap-datepicker.component';
import { LST_RELATED_ENTITY_AS_MULTISTRING } from '@src/app/shared/constants/multi-string.const';
import {
  AttributeType,
  PatternType,
  SystemType,
  UIControlId,
} from '@src/app/shared/enums/attribute-type.enum';
import { DateFormat } from '@src/app/shared/enums/date.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { PartnerStatusEnum } from '@src/app/shared/enums/partner-status.enum';
import { StyleMode } from '@src/app/shared/enums/style-mode.enum';
import {
  AttributeDescription,
  AttributeValue,
} from '@src/app/shared/interfaces/attribute-description.interface';
import { BaseEntityEditInterface } from '@src/app/shared/interfaces/base/base-entity-edit.interface';
import { ArrayUtils } from '@src/app/shared/utils/array-utils';
import { CustomMetadataUtils } from '@src/app/shared/utils/custom-metadata-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { ColumnMode } from '@siemens/ngx-datatable';
import { VenturePropertyName } from '../../enums/property-name.enum';
import { StorageEnum } from '../../enums/storage.enum';
import { Entity } from '../../models/entity.model';

@Component({
  selector: 'app-metadata-table',
  templateUrl: './metadata-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParser2Formatter },
  ],
})
export class MetadataTableComponent<T extends BaseEntityEditInterface>
  implements OnChanges, OnDestroy
{
  @Input() entityDatas: T[] = [];
  @Input() entityName = '';
  @Input() showColumnsByPropertyName: string[] = [];
  @Input() isLoading = false;
  @Input() editable = true;
  @Input() lstAdditionalAttrDes: AttributeDescription[] = [];
  @Input() parentEntityId: number;
  @Input() parentHttpService: BaseHttpService<Entity>;
  @Input() isParentEntityOwner = false;
  @Input() showSubmitMenu: boolean;
  @Input() parentEntity: T;
  @Input() enableExtraAction: boolean;

  @Output() appendSortParams = new EventEmitter<SortCriteria>();
  @Output() reloadData = new EventEmitter();

  metadatas: MetadataRecord[] = [];

  rows: Record<string, any>[] = [];
  columns: Record<string, any>[] = [];

  selectedAttributes = [];
  readonlyField = ['Id'];
  lstTableSettingStored: TableSettingStored[] = [];

  //#region REGISTER ENUM
  ColumnMode = ColumnMode;
  AttributeType = AttributeType;
  PartnerStatusEnum = PartnerStatusEnum;
  EntityName = EntityName;
  SystemType = SystemType;
  StyleMode = StyleMode;
  UIControlId = UIControlId;
  DateFormat = DateFormat;
  //#endregion End Register Enum

  constructor(
    protected sessionService: SessionService,
    public baseHttpService: BaseHttpService<Entity>,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.bindingData();
  }

  shouldShowVentureConstitutionState(item: VentureInterface): boolean {
    if (item) {
      return item.canViewNdaContent;
    }
  }

  getEntityUrl(entityName: string): string {
    return StringUtils.getEntityUrlByEntityName(entityName);
  }

  onSort(event: SortEventInterface): void {
    if (event) {
      const sortCriteria: SortCriteria = {
        propertyName: event.sorts[0].prop,
        sortingDirection: event.sorts[0].dir?.toUpperCase(),
      };
      this.appendSortParams.emit(sortCriteria);
    }
  }

  onResize(event: ResizeEventInterface): void {
    if (this.lstTableSettingStored) {
      const tableSettingStored = this.lstTableSettingStored.find(
        (x) => x.entityName === this.entityName
      );
      if (tableSettingStored) {
        const needUpdateColumn = tableSettingStored.columnData?.find(
          (x) => x.prop === event.column?.prop
        );
        if (needUpdateColumn) {
          needUpdateColumn.width = event.newValue;
          this.saveLstTableSettingStored();
        }
      }
    }
  }

  noopComparator(): number {
    return 0;
  }

  editCell(
    popover: NgbPopover,
    entityId: number,
    attrDes: AttributeDescription,
    event: Event
  ): void {
    const currentRow = this.rows.find((x) => x.Id === entityId);
    const cellValue = currentRow ? currentRow[attrDes.propertyName] : null;

    if (this.shouldNotEditOnPopover(attrDes)) {
      if (this.isCellValueAvailable(cellValue)) {
        event.stopPropagation();
      }
      return;
    }

    if (attrDes && this.hasEditPermission(attrDes, entityId)) {
      if (this.isSupportedRelatedEntityType(attrDes)) {
        this.handleEditRelatedEntity(popover, attrDes);
      } else {
        popover.open();
      }
      event.stopPropagation();
    } else {
      popover.disablePopover = true;
      if (
        this.shouldNotOpenEntityDetailAsNewTab(attrDes) &&
        this.isCellValueAvailable(cellValue)
      ) {
        event.stopPropagation();
      }
    }
  }

  openEntityDetailAsNewTab(entityId: number): void {
    const entityDetailUrl = this.sessionService.appendLanguagePath(
      `${this.getEntityUrl(this.entityName)}/${entityId}`
    );
    window.open(entityDetailUrl, '_blank');
  }

  onValueUpdated(value: any, propName: string, rowIndex: number): void {
    if (propName) {
      const updatedRows = [...this.rows];
      updatedRows[rowIndex][propName] = value;
      this.rows = updatedRows;
      const entity = this.entityDatas.find(
        (x) => x.id === updatedRows[rowIndex].Id
      );
      if (entity) {
        CustomMetadataUtils.setNewAttributeValueByPropertyName(
          entity.attributeDescriptions,
          entity.attributeValues,
          propName,
          value
        );
      }
      this.cd.markForCheck();

      if (propName === VenturePropertyName.CurrentSubmission) {
        this.reloadData.emit();
      }
    }
  }

  protected bindingData(): void {
    this.metadatas = this.mappingToMetadata(this.entityDatas);
    if (this.metadatas.length > 0) {
      if (this.showColumnsByPropertyName.length === 0) {
        this.showColumnsByPropertyName = this.getDefaultLstFilterPropertyName();
      }
      const allExistedAttrDes: AttributeDescription[] =
        this.getLstAttrDesWithAllDedicatedType(
          this.metadatas,
          this.showColumnsByPropertyName
        );
      this.columns = this.getHandledColumns(allExistedAttrDes);

      const updatedMetadatas: MetadataRecord[] = this.getUpdatedMetadatas(
        this.metadatas,
        allExistedAttrDes
      );
      this.rows = this.getHandledRowDatas(updatedMetadatas);
    }
  }

  protected getHandledColumns(
    handledLstAttrDes: AttributeDescription[]
  ): Record<string, any>[] {
    if (handledLstAttrDes?.length > 0) {
      this.getLstTableSettingStored();
      const tableSettingStored = this.lstTableSettingStored?.find(
        (x) => x.entityName === this.entityName
      );

      let newTableSettingStored: TableSettingStored = null;

      if (!tableSettingStored) {
        newTableSettingStored = new TableSettingStored(this.entityName, []);
      }

      const handledColumns = handledLstAttrDes.map(
        (attrDes: AttributeDescription) => {
          const attrType = attrDes.isHtml
            ? AttributeType.RTE
            : attrDes.attributeType;

          const resultColumn: Record<string, any> = {
            propertyName: attrDes.propertyName,
            displayName: attrDes.displayName,
            sortable: attrDes.sortable,
            attributeType: attrType,
            resizeable: true,
            canAutoResize: false,
            draggable: false,
            attrDes,
            width: 150,
            canShow: this.canShowColumn(attrDes.propertyName),
          };

          this.customUpdateColumn(resultColumn);

          if (tableSettingStored) {
            const columnDataStored = tableSettingStored.columnData?.find(
              (x) => x.prop === resultColumn.propertyName
            );
            if (columnDataStored) {
              resultColumn.width = columnDataStored.width;
            } else {
              tableSettingStored.columnData.push({
                prop: resultColumn.propertyName,
                width: resultColumn.width,
              });
            }
          } else {
            newTableSettingStored.columnData.push({
              prop: resultColumn.propertyName,
              width: resultColumn.width,
            });
          }

          return resultColumn;
        }
      );

      if (!tableSettingStored) {
        this.lstTableSettingStored.push(newTableSettingStored);
      }

      this.saveLstTableSettingStored();

      return handledColumns;
    }
    return [];
  }

  protected getHandledRowDatas(
    updatedMetadatas: MetadataRecord[]
  ): Record<string, any>[] {
    if (updatedMetadatas?.length > 0) {
      const dataRows: Record<string, any>[] = [];
      updatedMetadatas.forEach((metadataRecord: MetadataRecord) => {
        if (
          metadataRecord.attributeDescriptions?.length > 0 &&
          metadataRecord.attributeValues?.length > 0
        ) {
          const row: Record<string, any> = {};
          metadataRecord.attributeValues.forEach((attrVal: AttributeValue) => {
            const propName = attrVal.propertyName;
            const value = CustomMetadataUtils.getAttributeValueByPropertyName(
              metadataRecord.attributeDescriptions,
              metadataRecord.attributeValues,
              propName
            );
            row[`${propName}`] = value;
          });
          dataRows.push(row);
        }
      });

      return dataRows;
    }
    return [];
  }

  protected mappingToMetadata(entityDatas: T[]): MetadataRecord[] {
    if (entityDatas?.length > 0) {
      return entityDatas.map((item: T) => {
        this.updateSpecialMetadataItemValue(item);

        const metadataRecord: MetadataRecord = {
          attributeDescriptions: item.attributeDescriptions,
          attributeValues: item.attributeValues,
        };
        return metadataRecord;
      });
    }
    return [];
  }

  protected getLstAttrDesWithAllDedicatedType(
    metadatas: MetadataRecord[],
    lstFilterPropertyName: string[] = []
  ): AttributeDescription[] {
    if (metadatas?.length > 0) {
      let lstAttrDesCombined: AttributeDescription[] = [];
      const lstSystemAttrDes: AttributeDescription[] =
        metadatas[0].attributeDescriptions?.filter(
          (x) => x.type === PatternType.System || !x.type
        ); // Some attribute have type null

      const lstDedicatedAttrDes: AttributeDescription[] = [];

      metadatas.forEach((metadataRecord: MetadataRecord) => {
        const lstDedicatedAttrDesInRecord: AttributeDescription[] =
          metadataRecord.attributeDescriptions?.filter(
            (x) => x.type === PatternType.Dedicated
          );

        lstDedicatedAttrDesInRecord.forEach((attrDes: AttributeDescription) => {
          const lstDedicatedPropertyName: string[] = lstDedicatedAttrDes.map(
            (x) => x.propertyName
          );

          if (!lstDedicatedPropertyName.includes(attrDes.propertyName)) {
            lstDedicatedAttrDes.push(attrDes);
          }
        });
      });

      lstAttrDesCombined = [...lstSystemAttrDes, ...lstDedicatedAttrDes];

      if (lstFilterPropertyName.length > 0) {
        return this.getLstAttrDesCombinedFilterByPropertyName(
          lstAttrDesCombined,
          lstFilterPropertyName
        );
      }

      return lstAttrDesCombined;
    }
    return [];
  }

  protected getLstAttrDesCombinedFilterByPropertyName(
    lstAttrDesCombined: AttributeDescription[],
    lstFilterPropertyName: string[]
  ): AttributeDescription[] {
    const result: AttributeDescription[] = [];
    lstFilterPropertyName.forEach((propertyName: string) => {
      const attrDes: AttributeDescription = lstAttrDesCombined.find(
        (x) => x.propertyName === propertyName
      );
      if (attrDes) {
        result.push(attrDes);
      }
    });
    return result;
  }

  protected getUpdatedMetadatas(
    metadatas: MetadataRecord[],
    handledLstAttrDes: AttributeDescription[] = []
  ): MetadataRecord[] {
    const newMetadatas: MetadataRecord[] = [];
    metadatas.forEach((metaRecord: MetadataRecord) => {
      const newLstAttrVal: AttributeValue[] = [];

      this.showColumnsByPropertyName.forEach((propertyName: string) => {
        const newAttrVal: AttributeValue = metaRecord.attributeValues?.find(
          (x) => x.propertyName === propertyName
        );
        if (newAttrVal) {
          newLstAttrVal.push(newAttrVal);
        } else {
          // Handle for record that don't have dedicated type
          const undefinedAttrVal: AttributeValue = {
            propertyName,
            describedValue: undefined,
          };
          newLstAttrVal.push(undefinedAttrVal);
        }
      });

      const metadataRecord: MetadataRecord = {
        attributeDescriptions: handledLstAttrDes,
        attributeValues: newLstAttrVal,
      };
      newMetadatas.push(metadataRecord);
    });
    return newMetadatas;
  }

  //#region FUNCTION CAN OVERRIDE FROM INHERIT ENTITY
  getImgCustomClass(propertyName: string): string {
    return DEFAULT_IMAGE_CLASS_VALUE;
  }

  getEditCellCustomClass(propertyName: string): string {
    return '';
  }

  getBooleanTypeValue(value: boolean | string, propertyName = ''): string {
    if (value !== undefined && value !== null && value !== '') {
      return value ? 'Checked' : 'Unchecked';
    }
    return '';
  }

  isSendMessToMemberShown(entityId: number): boolean {
    return false;
  }

  afterCreateTimestamp(entityId: number, rowIndex: number): void {
    /**/
  }

  afterLeaveEntity(isLeaveSuccess: boolean): void {
    if (isLeaveSuccess) {
      this.reloadData.emit();
    }
  }

  isSettingShown(entityId: number): boolean {
    return false;
  }

  canEditCustomContition(attrDes: AttributeDescription, value: any): boolean {
    return true;
  }

  //#region PERMISSION
  hasEditPermission(attrDes: AttributeDescription, entityId: number): boolean {
    const currentRow = this.rows.find((x) => x.Id === entityId);
    if (currentRow && currentRow[attrDes.propertyName] === undefined) {
      return false;
    }
    return (
      this.editable &&
      // TODO: remove readonlyField part when BE update
      (attrDes.readonly
        ? false
        : !this.readonlyField.includes(attrDes?.propertyName)) &&
      this.sessionService.isLogin &&
      (this.isSupportedRelatedEntityType(attrDes) ||
        ATTRIBUTE_TYPE_SUPPORT_EDIT_IN_TABLE.includes(
          attrDes?.attributeType as AttributeType
        ))
    );
  }

  hasCreateTimeStampPermission(entityId: number): boolean {
    return true;
  }

  hasLeaveEntityPermission(entityId: number): boolean {
    const entity = this.entityDatas.find((x) => x.id === entityId);
    return this.isTeam(entity);
  }
  //#endregion End Permission

  protected isSupportedRelatedEntityType(
    attrDes: AttributeDescription
  ): boolean {
    return attrDes.attributeType === AttributeType.RelatedEntity
      ? this.isMultiStringRelatedEntity(attrDes)
      : false;
  }

  protected isMultiStringRelatedEntity(attrDes: AttributeDescription): boolean {
    const multiStringRE = LST_RELATED_ENTITY_AS_MULTISTRING[this.entityName];
    return multiStringRE?.propertyNames.includes(attrDes.propertyName);
  }

  protected getDefaultLstFilterPropertyName(): string[] {
    return [];
  }

  protected isOwner(entity: T): boolean {
    return BaseEntityEditComponent.hasPermission(
      this.sessionService.currentUser,
      entity
    );
  }

  protected isTeam(entity: T): boolean {
    return ArrayUtils.hasItem(
      entity?.stakeholders,
      this.sessionService.currentUser,
      'id'
    );
  }

  protected canShowColumn(propertyName: string): boolean {
    return propertyName !== 'Id';
  }

  protected customUpdateColumn(resultColumn: Record<string, any>): void {
    /**/
  }
  protected updateSpecialMetadataItemValue(item: T): void {
    /**/
  }
  protected handleEditRelatedEntity(
    popover: NgbPopover,
    attrDes: AttributeDescription
  ): void {
    // Custom handle for special related entity
    popover.open();
  }

  protected shouldNotEditOnPopover(attrDes: AttributeDescription): boolean {
    // Image edit direct in table
    return attrDes.attributeType === AttributeType.Image;
  }

  protected shouldNotOpenEntityDetailAsNewTab(
    attrDes: AttributeDescription
  ): boolean {
    return false;
  }
  //#endregion

  private isCellValueAvailable(cellValue: any): boolean {
    return Array.isArray(cellValue) ? cellValue?.length > 0 : !!cellValue;
  }

  private getLstTableSettingStored(): void {
    try {
      this.lstTableSettingStored =
        JSON.parse(localStorage.getItem(StorageEnum.listTableSettingStored)) ??
        [];
    } catch (error) {
      this.lstTableSettingStored = [];
    }
  }

  private saveLstTableSettingStored(): void {
    const lstTableSettingStored = JSON.stringify(this.lstTableSettingStored);
    localStorage.setItem(
      StorageEnum.listTableSettingStored,
      lstTableSettingStored
    );
  }

  ngOnDestroy(): void {
    /**/
  }

  onSubmitModalClosed(hasUpdated: boolean): void {
    hasUpdated && this.reloadData.emit();
  }

  getEntityById(id: number): any {
    return this.entityDatas?.find((x) => x.id === id);
  }

  onSetting(rowId, parentEntity) {
    // Implement in inherit entity
  }

  onPromoteAdmin(id) {
    // Implement in inherit entity
  }

  onRemovePerson(id) {
    // Implement in inherit entity
  }
}
