import {
  Component,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SecurityContext,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseHttpService } from '@core/http/base-http.service';
import { CentralConfigService } from '@core/services/central-config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { InternalIcon } from '@shared/enums/internal-icon.enum';
import { untilDestroyed } from '@shared/functions/until-destroyed';
import { JoinState } from '@shared/interfaces/join.interface';
import { ScrollingHandlerService } from '@shared/services/scrolling-handler.service';
import { getCurrentFragment } from '@shared/utils/common.utils';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { CustomFormService } from '@src/app/core/form/custom-form.service';
import { UnsavedFormCheckService } from '@src/app/core/form/unsaved-form-check.service';
import { GlobalFilterStoredService } from '@src/app/core/services/global-filter-stored.service';
import { LoadingService } from '@src/app/core/services/loading.service';
import { PaginationSettingService } from '@src/app/core/services/pagination-setting.service';
import { SessionService } from '@src/app/core/session.service';
import { placeholderImg } from '@src/app/shared/constants/common';
import { ToBoolean } from '@src/app/shared/decorators/to-boolean';
import { DateFormat } from '@src/app/shared/enums/date.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { GlobalEventBus } from '@src/app/shared/enums/event-bus.enum';
import { StorageEnum } from '@src/app/shared/enums/storage.enum';
import { UrlParam } from '@src/app/shared/enums/url-param.enum';
import {
  AttributeDescription,
  MetaInformation,
} from '@src/app/shared/interfaces/attribute-description.interface';
import { BaseEntityInterface } from '@src/app/shared/interfaces/base/base-entity.interface';
import {
  EntityGlobalFilterCriteria,
  GlobalFilterCriteria,
} from '@src/app/shared/interfaces/filters/global-filter.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import {
  PageSizeConfig,
  PaginationFilterStored,
  PaginationSettingPlace,
} from '@src/app/shared/models/pagination.model';
import { UserConfigManagementService } from '@src/app/shared/services/user-config-management.service';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { EventBusService } from 'ngx-eventbus';
import { Event as EventBus } from 'ngx-eventbus/lib/service/event';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BaseComponentWithServiceComponent } from './base-component-with-service/base-component-with-service.component';
import { PageHeaderService } from '@src/app/components/master-layout/page-header/services/page-header.service';

@Component({
  selector: 'app-base-list',
  template: '<h1>BaseListComponent</h1>',
})
export class BaseListComponent<T extends BaseEntityInterface>
  extends BaseComponentWithServiceComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() orgId: number;
  @Input() fromWidget = false;

  // Config from Elementor widget UI
  // tslint:disable-next-line:variable-name
  @Input() page_size: string;

  @Input() columns: string;

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() show_paginator: boolean;

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() show_creation_btn: boolean;

  // tslint:disable-next-line:variable-name
  @Input() current_template_slug: string;

  // tslint:disable-next-line:variable-name
  @Input() @ToBoolean() is_open_in_new_tab: boolean;

  // tslint:disable-next-line:variable-name
  @Input() bg_color: string;

  // tslint:disable-next-line:variable-name
  @Input() global_org_id: string; // Central config

  @Input() @ToBoolean() isLoadWithSkeleton: boolean;

  @Output() changeContent = new EventEmitter();

  // #region VARIABLES
  portalName = environment.portalName;
  placeholderImg = placeholderImg;

  page = 1;
  pageSize = 10;
  collectionSize = 0;

  isLoading = false;

  profile$: Observable<UserInterface>;
  entitybase = '';
  entityCreatepath = '';

  items: T[] = [];
  selectedEntityId: any;
  selectedEntity: T;
  entityName: EntityName;
  EntityName = EntityName;
  DateFormat = DateFormat;
  InternalIcon = InternalIcon;

  // global filter
  entityGlobalFilterCriteria: EntityGlobalFilterCriteria;
  globalFilterEvent: EventBus;
  listItemsChanged: EventBus;
  listItemAdded: EventBus;
  createBtnClickEvent: EventBus;
  createPostBtnClickEvent: EventBus;
  filterPaneSource: Record<string, any> = {};
  sortingPayload = {};

  isProduction = environment.production;
  isDemo = environment.demo;

  hasFilterByOrg = true;
  // #endregion End Variables

  //#region SERVICES
  protected customFormService: CustomFormService;
  protected modalService: NgbModal;
  protected eventBus: EventBusService;
  protected filterStoredService: GlobalFilterStoredService;
  protected unsavedFormCheckService: UnsavedFormCheckService;
  protected domSanitizer: DomSanitizer;
  protected paginationSettingService: PaginationSettingService;
  protected globalLoadingService: LoadingService;
  protected centralConfigService: CentralConfigService;
  protected scrollingHandler: ScrollingHandlerService;
  protected userConfig: UserConfigManagementService;
  protected pageHeaderService: PageHeaderService;
  //#endregion End Services

  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    const pageIndex = Number(StringUtils.getParamFromUrl(UrlParam.PageNumber));
    if (!isNaN(pageIndex) && pageIndex > 0) {
      this.page = pageIndex;
    } else {
      this.page = 1;
    }

    this.loadData();
  }

  constructor(
    public listService: BaseHttpService<any>,
    protected translateService: TranslateService,
    protected sessionService: SessionService,
    protected authenService: AuthenService,
    protected injector: Injector
  ) {
    super(sessionService);

    this.customFormService = injector.get<CustomFormService>(CustomFormService);
    this.modalService = injector.get<NgbModal>(NgbModal);
    this.eventBus = injector.get<EventBusService>(EventBusService);
    this.filterStoredService = injector.get<GlobalFilterStoredService>(
      GlobalFilterStoredService
    );
    this.unsavedFormCheckService = injector.get<UnsavedFormCheckService>(
      UnsavedFormCheckService
    );
    this.domSanitizer = injector.get<DomSanitizer>(DomSanitizer);
    this.paginationSettingService = injector.get<PaginationSettingService>(
      PaginationSettingService
    );
    this.globalLoadingService = injector.get<LoadingService>(LoadingService);
    this.centralConfigService = injector.get(CentralConfigService);
    this.scrollingHandler = injector.get(ScrollingHandlerService);
    this.userConfig = injector.get(UserConfigManagementService);
    this.pageHeaderService = injector.get(PageHeaderService);

    this.entitybase = this.getEntityBase();
    this.entityCreatepath = this.entitybase + '/create';
    this.sessionService.isOnListPage = true;

    this.profile$ = this.authenService.getProfile();
  }

  getEntityBase(): string {
    return '';
  }

  getFieldOptions(key, item: T): AttributeDescription {
    return item?.attributeDescriptions
      ? FormUtils.getFieldOptions(item.attributeDescriptions, key)
      : this.customFormService.getFieldOptions(key);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateWidgetConfig(changes);

    super.ngOnChanges(changes);
  }

  ngOnInit(): void {
    this.listenGlobalFilterEvent();
    this.listenToSortEntityList();
    if (!this.fromWidget) {
      this.listenToCreateBtnClick();
    }

    const pageIndex = Number(StringUtils.getParamFromUrl(UrlParam.PageNumber));
    if (!isNaN(pageIndex) && pageIndex > 0) {
      this.page = pageIndex;
    }

    if (
      [EntityName.Venture, EntityName.Organization].includes(this.entityName)
    ) {
      this.handleLoading(true);
      this.listenFilterPaneSource();
    } else {
      this.loadData();
    }

    this.sessionService.apiReady$
      .pipe(untilDestroyed(this))
      .subscribe((ready) => {
        // Messages page no longer has any attributes
        if (ready && this.entitybase !== '') {
          this.listService
            .getAttributeDescription()
            .pipe(untilDestroyed(this))
            .subscribe((descriptions: MetaInformation) => {
              this.customFormService.setControlConfig(descriptions);
              this.syncAttributeDescriptions();
            });
        }
      });

    this.setOrgId();

    this.orgId = +this.global_org_id || this.orgId;
  }

  emitContentUpdated(): void {
    setTimeout(() => {
      this.changeContent.emit();
    }, 100);
  }

  loadData(): void {
    this.handleLoading(true);

    this.sessionService.apiReady$
      .pipe(untilDestroyed(this))
      .subscribe(async (apiReady) => {
        if (!apiReady) {
          return;
        }

        let payload = await this.filterStoredService.getFilterCriteria();
        const subOrgId = StringUtils.getParamFromUrl(
          UrlParam.SubOrganizationId
        );
        const currentFiltertokenJson = await this.userConfig.get(
          StorageEnum.currentFilterToken
        );

        const { innovationSpaceId } = this.centralConfigService;

        if (innovationSpaceId || !this.sessionService.isHeaderVisible) {
          payload.isMyInvolvement = false;
          payload.isMyOrgs = false;
        }

        if (innovationSpaceId) {
          payload.organizationIds = [innovationSpaceId];
        }

        if (
          location.pathname !== payload.filteringPath ||
          !this.sessionService.isHeaderVisible
        ) {
          // do not search by keyword if reload different page
          payload.keyword = '';
        }

        if (
          subOrgId &&
          (innovationSpaceId || currentFiltertokenJson.length > 0)
        ) {
          payload.threadId = +subOrgId;
        } else {
          delete payload.threadId;
        }

        payload = { ...payload, ...this.sortingPayload };

        this.updatePageFilters(payload);
        this.filterData();
      });
  }

  updatePageFilters(payload: GlobalFilterCriteria): void {
    const filterCriteria: EntityGlobalFilterCriteria = {
      pageIndex: this.page,
      pageSize: this.pageSize,
      ...payload,
    };

    this.entityGlobalFilterCriteria = filterCriteria;
  }

  updateItemInList(itemToUpdate, key = 'id'): void {
    if (itemToUpdate && this.items) {
      const foundIndex = this.items.findIndex(
        (item) => item[key] === itemToUpdate[key]
      );

      if (foundIndex > -1) {
        const item = this.listService.tranformData(itemToUpdate);
        this.items[foundIndex] = item;
      }
    }
  }

  createEntity(event: Event, handler?: any): void {
    if (this.sessionService.isLogin && !this.sessionService.isTokenExpired()) {
      if (handler) {
        handler();
      } else if (this.entityCreatepath) {
        this.goTo(this.entityCreatepath);
      }
    } else {
      if (this.sessionService.isLogin && this.sessionService.isTokenExpired()) {
        this.sessionService.setCookie(StorageEnum.isTokenExpired, true);
        this.sessionService.onSessionExpire();
        return;
      }

      event.stopPropagation();
      event.preventDefault();

      const callbackHref =
        !handler && this.entityCreatepath
          ? this.sessionService.appendLanguagePath(this.entityCreatepath)
          : location.href;
      this.sessionService.setLoginCallBackpage(callbackHref);

      this.unsavedFormCheckService.clearAll();
      this.goTo(environment.jipUrl.login);
    }
  }

  filterData(): void {
    this.sessionService.apiReady$
      .pipe(untilDestroyed(this))
      .subscribe((apiReady) => {
        if (!apiReady) {
          return;
        }

        this.paginateFilteredItems(this.entityGlobalFilterCriteria);
      });
  }

  goTo(url: string): void {
    FormUtils.navigateTo(this.sessionService.appendLanguagePath(url));
  }

  showEntityData(entity: T): void {
    if (entity) {
      this.selectedEntityId = null;
      this.selectedEntity = null;
      setTimeout(() => {
        this.selectedEntityId = entity.id;
        this.selectedEntity = entity;
      });
    }
  }

  // #region HANDLE PAGINATION
  paginateFilteredItems(filterCriteria: EntityGlobalFilterCriteria): void {
    if (
      !this.fromWidget &&
      !getCurrentFragment(window) &&
      this.show_paginator
    ) {
      // Only scroll top when have paginator
      window.scrollTo({ top: 0 });
    }

    this.handleLoading(true);

    const filterDto: EntityGlobalFilterCriteria =
      this.getFilterDto(filterCriteria);

    const obsListResult$: Observable<ApiGetResponse<T>> =
      this.getPagingMethod(filterDto);

    if (obsListResult$) {
      obsListResult$
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            if (this.entityName !== EntityName.Challenge) {
              this.isLoadWithSkeleton = false;
            }
            this.handleLoading(false);
          })
        )
        .subscribe((res: ApiGetResponse<T>) => {
          this.updateEntityList(res);
          if (this.show_paginator) {
            this.scrollingHandler.scrollToFragmentIfAny();
          }
        });

      if (filterDto.pageIndex <= 1) {
        StringUtils.removeParamFromUrl(UrlParam.PageNumber, null, true);
        return;
      }

      StringUtils.setQueryParamOnUrl(
        UrlParam.PageNumber,
        filterDto.pageIndex.toString(),
        null
      );
    }
  }

  protected getFilterDto(
    filterCriteria: EntityGlobalFilterCriteria
  ): EntityGlobalFilterCriteria {
    delete filterCriteria.filteringPath;

    let filterDto: EntityGlobalFilterCriteria = {
      ...filterCriteria,
    };

    if (this.orgId) {
      filterDto = this.getFilterDataFollowOrgId(filterDto, this.orgId);
    }

    if (!this.hasFilterByOrg) {
      delete filterDto.organizationIds;
    }

    return filterDto;
  }

  protected getPagingMethod(
    filterDto: EntityGlobalFilterCriteria
  ): Observable<ApiGetResponse<T>> {
    return this.listService.paginateX(filterDto);
  }

  protected getFilterDataFollowOrgId(
    filterDto: EntityGlobalFilterCriteria,
    orgId: number
  ): EntityGlobalFilterCriteria {
    const newfilterDto: EntityGlobalFilterCriteria = {
      ...filterDto,
    };
    newfilterDto.isMyInvolvement = false;
    newfilterDto.isMyOrgs = false;
    newfilterDto.keyword = '';
    newfilterDto.organizationIds = [orgId];
    return newfilterDto;
  }

  protected updateEntityList(
    res: ApiGetResponse<T>,
    shouldLoadCount = true
  ): void {
    this.mappingResponseToListData(res);
    this.collectionSize = res?.rowCount;

    if (this.entityName && shouldLoadCount) {
      this.runEntityStateCount(this.entityName, this.items, this.listService);
    }
    this.afterUpdateEntityList();
    this.emitContentUpdated();
  }

  afterUpdateEntityList(): void {
    /**inherit */
  }

  protected mappingResponseToListData(res: ApiGetResponse<T>): void {
    this.items = res?.items;
  }

  paginateItems(): void {
    this.paginateFilteredItems(this.entityGlobalFilterCriteria);
  }

  onPageChange(pageNumber: number): void {
    this.page = pageNumber;
    this.loadData();
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.loadData();
  }
  // #endregion End Handle Pagination

  // #region SYNC COUNT STATE
  protected syncAttributeDescriptions(): void {
    /* */
  }

  protected syncEntityJoinStates(): void {
    const stringIds = this.items.map((i) => String(i.id));

    this.listService
      .getJoinStates(stringIds)
      .pipe(untilDestroyed(this))
      .subscribe((joinStates: JoinState[]) => {
        this.items = this.items.map((item) => {
          const foundState = joinStates.find(
            (state) => state.entityId === item.id
          );
          item.hasJoined = foundState ? foundState.hasJoined : false;
          item.canJoin = foundState ? foundState.canJoin : false;
          item.isPending = foundState ? foundState.isPending : false;
          return item;
        });
      });
  }
  // #endregion End SYNC COUNT STATE

  // #region  HANDLE INTERACTION
  toggleFavorite(entity: T): void {
    if (entity) {
      if (!entity.isFavorite) {
        this.listService
          .follow(entity.id)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            entity.isFavorite = !entity.isFavorite;
            entity.followerCount = entity.followerCount + 1;
          });
      } else {
        this.listService
          .unfollow(entity.id)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            entity.isFavorite = !entity.isFavorite;
            entity.followerCount =
              entity.followerCount > 0 ? entity.followerCount - 1 : 0;
            if (this.entityGlobalFilterCriteria?.isMyInvolvement) {
              this.filterData();
            }
          });
      }
    }
  }

  toggleLike(entity: T): void {
    if (entity) {
      if (!entity.isLiked) {
        this.listService
          .like(entity.id)
          .pipe(untilDestroyed(this))
          .subscribe((newLikeId: number) => {
            entity.isLiked = !entity.isLiked;
            entity.likesCount = entity.likesCount + 1;
            entity.likeId = newLikeId;
          });
      } else {
        this.listService
          .unlike(entity.likeId)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            entity.isLiked = !entity.isLiked;
            entity.likesCount =
              entity.likesCount > 0 ? entity.likesCount - 1 : 0;
            entity.likeId = null;
          });
      }
    }
  }

  // #endregion End Hanlde Interaction

  enhanceDescriptionHtmlString(html: string): string {
    let resultHtmlString = '';
    const sanitizeHtml = this.domSanitizer.sanitize(SecurityContext.HTML, html);
    if (sanitizeHtml) {
      resultHtmlString =
        StringUtils.stripAttributesFromHtmlString(sanitizeHtml);
      // breakline element
      resultHtmlString = StringUtils.replaceTagFromHtmlString(
        resultHtmlString,
        ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote'],
        'p'
      );
      resultHtmlString = StringUtils.replaceTagFromHtmlString(
        resultHtmlString,
        ['em', 'u', 'strong', 'b', 'a'],
        'span'
      );
    }
    return resultHtmlString;
  }

  onCommentModalClosed(): void {
    this.syncCommentsState();
  }

  protected setOrgId(): void {
    this.filterStoredService
      .getCurrentFilterToken()
      .pipe(untilDestroyed(this))
      .subscribe((res: number) => (this.orgId = res));
  }

  protected handleLoading(
    isLoading: boolean,
    loadingSkeleton: boolean = this.isLoadWithSkeleton
  ): void {
    this.isLoading = isLoading;
    const isOnChallengePage = window.location.href.includes(
      environment.jipUrl.challenges
    );
    if (loadingSkeleton && !isOnChallengePage) return;
    this.globalLoadingService.setLoadingState(isLoading);
  }

  protected async setLatestFilterPaneSourceToPaginationFilterStored(
    filterDto: Record<string, any>,
    paginationSettingPlace: PaginationSettingPlace
  ): Promise<void> {
    const paginationFilterStored = new PaginationFilterStored(
      filterDto || {},
      paginationSettingPlace,
      PageSizeConfig.EightItemsFirstPage,
      true
    );
    await this.paginationSettingService.setPaginationFilterStored(
      paginationFilterStored
    );
  }

  private listenGlobalFilterEvent(): void {
    // change item
    this.listItemsChanged = this.eventBus.addEventListener({
      name: GlobalEventBus.AddNewItemToList,
      callback: (item) => {
        // Only update for news & message page first
        if (
          this.entityName === EntityName.Message ||
          this.entityName === EntityName.News
        ) {
          if (this.items) {
            this.items.unshift(item);
          } else {
            this.items = [item];
          }
        }
      },
    });

    // add new item
    this.listItemAdded = this.eventBus.addEventListener({
      name: GlobalEventBus.ListItemUpdate,
      callback: (item: T) => {
        this.updateItemInList(item);
      },
    });

    // change filter
    this.globalFilterEvent = this.eventBus.addEventListener({
      name: GlobalEventBus.GlobalFilterEvent,
      callback: ({
        filterCriteria: payload,
        filterPaneOnly,
        isFromFilterBtn,
      }: {
        filterCriteria: GlobalFilterCriteria;
        filterPaneOnly?: boolean;
        isFromFilterBtn?: boolean;
      }) => {
        if (this.entityName !== EntityName.News) {
          this.handleLoading(true);

          if (this.sessionService.hasFilterPane && isFromFilterBtn) {
            this.page = 1;
          }
        }
        this.updatePageFilters({ ...payload, ...this.sortingPayload });
        this.entityName !== EntityName.News && this.filterData();
      },
    });
  }

  private listenToCreateBtnClick(): void {
    this.createBtnClickEvent = this.eventBus.addEventListener({
      name: GlobalEventBus.EntityCreatePathUpdate,
      callback: (clickEvent: Event) => {
        this.createEntity(clickEvent);
      },
    });
  }

  private listenToSortEntityList(): void {
    this.eventBus.addEventListener({
      name: GlobalEventBus.SortEntityList,
      callback: async (payload) => {
        const filterCriteria =
          await this.filterStoredService.getFilterCriteria();
        const newFilterCriteria = {
          ...filterCriteria,
          ...payload,
          pageIndex: this.page,
          pageSize: this.pageSize,
        };

        this.sortingPayload = payload;
        this.entityGlobalFilterCriteria = newFilterCriteria;
        this.paginateFilteredItems(this.entityGlobalFilterCriteria);
      },
    });
  }

  private listenFilterPaneSource(): void {
    this.filterStoredService
      .getFilterPaneSource()
      .pipe(untilDestroyed(this))
      .subscribe((currentFilterPane: {}) => {
        this.filterPaneSource = currentFilterPane;
      });
  }

  private updateWidgetConfig(changes: SimpleChanges): void {
    if (changes.page_size?.currentValue) {
      this.pageSize = +this.page_size;
    }
  }

  ngOnDestroy(): void {
    if (this.eventBus) {
      this.eventBus.removeEventListener(this.globalFilterEvent);
      this.eventBus.removeEventListener(this.listItemsChanged);
      this.eventBus.removeEventListener(this.listItemAdded);
      this.eventBus.removeEventListener(this.createBtnClickEvent);
      this.eventBus.removeEventListener(this.createPostBtnClickEvent);
    }
  }
}
