import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { StakeholderCardsComponent } from '@src/app/components/peoples/components/people-cards/stakeholder-cards/stakeholder-cards.component';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { PageSizeConfig } from '@src/app/shared/models/pagination.model';

@Component({
  selector: 'app-organization-community',
  templateUrl: './organization-community.component.html',
})
export class OrganizationCommunityComponent
  extends StakeholderCardsComponent
  implements OnChanges
{
  @Input() toItems: UserInterface[] = [];
  @Input() stakeHoldersCollectionSize = 0;
  @Input() isLoading = false;
  @Input() isGridView = true;
  @Input() isTableExpanded = false;

  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() pageIndexChange = new EventEmitter<number>();
  @Output() isGridViewChange = new EventEmitter<boolean>();
  @Output() isTableExpandedChange = new EventEmitter<boolean>();

  @ViewChild('modalRef') modalRef: ElementRef;

  pageSizeConfig = PageSizeConfig;

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (
      changes.isTableExpanded &&
      changes.isTableExpanded.previousValue !== undefined
    ) {
      if (changes.isTableExpanded.currentValue) {
        this.modalService
          .open(this.modalRef, {
            centered: true,
            backdrop: 'static',
            windowClass: 'modal-fullscreen',
          })
          .result.finally(() => {
            this.emitTableExpanded(false);
          });

        if (this.isGridView) {
          this.isGridView = false;
          this.isGridViewChange.emit(this.isGridView);
        }
      }
    }
  }

  setDisplayItems(text = null): void {
    this.searchName = text;

    if (
      (!this.showSearchButton && !this.filteringKeyword) ||
      !this.searchName
    ) {
      this.displayItems = Array.isArray(this.items) ? [...this.items] : [];
    } else {
      this.displayItems = this.filterPeople(this.items, this.searchName);
    }

    this.collectionSize = this.stakeHoldersCollectionSize;
    this.onPaging();
  }

  onPageSizeChange(event) {
    this.pageSize = event;
    this.pageSizeChange.emit(event);
  }

  onPageIndexChange(event) {
    this.pageIndexChange.emit(event);
  }

  onShowExpandTable() {
    this.modalService.open(this.modalRef, {
      centered: true,
      backdrop: 'static',
      windowClass: 'modal-fullscreen',
    });
  }

  emitTableExpanded(value) {
    this.isTableExpanded = value;
    this.isTableExpandedChange.emit(this.isTableExpanded);
  }
}
