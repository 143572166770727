import { Component, Injector, OnInit } from '@angular/core';
import { BaseEventRegistrationComponent } from '@components/base/base-event-registration/base-event-registration.component';
import { environment } from '@env/environment';
import { UrlParam } from '@shared/enums/url-param.enum';
import { EventUtils } from '@shared/utils/event-utils';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { EventHttpService } from '@src/app/core/http/event-http.service';
import { EventPaymentService } from '@src/app/core/http/event-payment.service';
import { SessionService } from '@src/app/core/session.service';
import { FormUtils } from '@src/app/shared/utils/form-utils';
@Component({
  selector: 'app-event-registration',
  templateUrl: './event-registration.component.html',
})
export class EventRegistrationComponent
  extends BaseEventRegistrationComponent
  implements OnInit
{
  readonly UrlParam = UrlParam;
  eventPaymentService: EventPaymentService;

  constructor(
    public eventHttpService: EventHttpService,
    protected authService: AuthenService,
    protected sessionService: SessionService,
    protected injector: Injector
  ) {
    super(eventHttpService, authService, sessionService, injector);
    this.eventPaymentService = this.injector.get(EventPaymentService);
  }

  setLoginCallback(eventId: number): void {
    const eventDetail = this.sessionService.appendLanguagePath(
      `${environment.jipUrl.event}/${eventId}`
    );

    this.sessionService.setLoginCallBackpage(eventDetail);
  }

  showSuccessMessage(): void {
    this.toastService.showSuccess('UI.Toast.SentSuccessfully');
  }

  handleAfterGetEntityDetail(event) {
    super.handleAfterGetEntityDetail(event);
    this.handleAutoRegisterForLoggedInUser(event);
  }

  handleAutoRegisterForLoggedInUser(event) {
    const hasPaymentEvent = event.hasPayment;
    const isPassedEvent = EventUtils.isPassedEvent(event);
    const isFullybookedEvent = EventUtils.isEventFullyBooked(event);

    if (this.sessionService.isLogin) {
      if (hasPaymentEvent) {
        this.eventPaymentService.handleEventPayment(event);
      } else {
        if (isPassedEvent || isFullybookedEvent) {
          FormUtils.navigateToWithLanguagePath(
            this.sessionService,
            `${environment.jipUrl.event}/?${UrlParam.NotFound}=true`
          );
        } else {
          this.redirectToEvents();
        }
      }
    }
  }
}
