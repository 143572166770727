import { Injectable } from '@angular/core';
import { SortCriteria } from '@src/app/shared/models/pagination.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SortingVenturesService {
  private sortingAttribute$ = new BehaviorSubject<SortCriteria>(null);

  getSortingAttribute() {
    return this.sortingAttribute$.asObservable();
  }

  setSortingAttribute(value) {
    this.sortingAttribute$.next(value);
  }
}
