<ng-container *ngIf="attributeDescription">
  <div
    [class]="{
      'hidden-content-mark':
        !!options?.showHiddenMarkBackground &&
        !attributeDescription?.isVisibleInSystem
    }"
  >
    <!--    Form control label part-->
    <div
      *ngIf="showLabel && attributeDescription?.displayName"
      class="venture__label widget__label d-flex justify-content-between"
      [ngClass]="labelClass"
    >
      <div>
        {{ attributeDescription?.displayName }}

        <i
          *ngIf="
            attributeDescription.helpTextId?.includes(
              UIHelpTextPosition.Tooltip
            )
          "
          class="fas fa-info-circle pointer-cursor"
          appTooltip
          triggers="click"
          [ngbTooltip]="htmlTooltipContent"
        ></i>
      </div>
      <ng-template #htmlTooltipContent>
        <quill-view
          class="ql-editor-only-view"
          [content]="attributeDescription.helpTextId | translate"
        ></quill-view>
      </ng-template>

      <app-language-indicator
        *ngIf="showLanguageIndicator"
      ></app-language-indicator>
      <app-language-switcher
        *ngIf="options.showLanguageSwitcher"
        (languageSelected)="onLanguageSelected($event)"
        (languageRemoved)="onLanguageRemoved($event)"
        [className]="'p-1'"
      >
      </app-language-switcher>
    </div>

    <!--    Form control value part-->
    <div
      *ngIf="
        attributeDescription?.isVisibleInSystem ||
          options?.showHiddenMarkBackground ||
          formControl?.value !== null ||
          !!currentValue;
        else hiddenContentRef
      "
      class="form-field__content"
    >
      <div class="form-field__prefix" [class.field-disabled]="!editable">
        <ng-content select="[part=prefix]"></ng-content>
      </div>

      <div
        *ngIf="formInput && (editable || !isViewAsText())"
        class="form-field__body"
        [ngSwitch]="type"
        [formGroup]="formInput"
        [class.mw-100]="type === allType.RTE"
      >
        <div *ngSwitchCase="allType.Link">
          <ng-container
            *ngIf="isStringLink(formControl?.value); else linkAsObject"
          >
            <input
              class="form-control"
              [formControlName]="controlName"
              [placeholder]="options.placeholder || ''"
              [pattern]="attributeDescription.regularExpression"
            />
          </ng-container>

          <ng-template #linkAsObject>
            <app-link
              [formControlName]="controlName"
              [placeholder]="options.placeholder"
              [regularExpression]="attributeDescription.regularExpression"
            ></app-link>
          </ng-template>
        </div>

        <div *ngSwitchCase="allType.Map">
          <app-google-map-autocomplete
            [mapData]="formControl?.value"
            [placeholder]="options.placeholder"
            [hintSearch]="'UI.Map.Hint-input-search' | translate"
            [editting]="editable"
            (locationSelect)="setValue($event)"
          ></app-google-map-autocomplete>
        </div>

        <div *ngSwitchCase="allType.MultiLink">
          <app-multi-link
            [editable]="editable"
            [fixedItems]="options?.fixedItems || []"
            [items]="selectionChoices || []"
            [placeholder]="options?.placeholder"
            [enabledAddNew]="options?.enabledAddNew"
            [regex]="attributeDescription.regularExpression"
            (selectItem)="setValue($event)"
          ></app-multi-link>
        </div>

        <div *ngSwitchCase="allType.SocialLink">
          <app-social-link
            [items]="selectionChoices || []"
            [regex]="attributeDescription.regularExpression"
            [formControlSocialLink]="formControl"
            (selectItem)="setValue($event)"
          ></app-social-link>
        </div>

        <!-- TODO: Need refactor -> Remove StringSingle type later, should using UIControlId -->
        <div *ngSwitchCase="allType.StringSingle">
          <input
            class="form-control"
            [formControlName]="controlName"
            [placeholder]="options.placeholder || ''"
            [pattern]="attributeDescription.regularExpression"
            [id]="elementId"
            [readonly]="readonly"
          />
          <ng-container *ngIf="formControl?.pending">
            <div class="input-spinner-container">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
          </ng-container>
        </div>

        <div *ngSwitchCase="allType.String">
          <ng-container [ngSwitch]="attributeDescription.uiControlId">
            <ng-container *ngSwitchCase="UIControlId.TextInputV1">
              <input
                class="form-control"
                [formControlName]="controlName"
                [placeholder]="options.placeholder || ''"
                [pattern]="attributeDescription.regularExpression"
                [id]="elementId"
                [readonly]="readonly"
              />
            </ng-container>

            <ng-container *ngSwitchCase="UIControlId.TextAreaV1">
              <textarea
                class="form-control"
                [formControlName]="controlName"
                [placeholder]="getStringTypePlaceholder() | translate"
                [id]="elementId"
                [readonly]="readonly"
              ></textarea>
            </ng-container>

            <ng-container *ngSwitchCase="UIControlId.PasswordInputV1">
              <div class="input-group">
                <input
                  class="form-control"
                  [formControlName]="controlName"
                  [placeholder]="
                    !isInputFocused
                      ? (attributeDescription.helpTextId | translate)
                      : ''
                  "
                  [pattern]="attributeDescription.regularExpression"
                  [id]="elementId"
                  [type]="passwordIsShow ? 'text' : 'password'"
                  (focus)="onInputFocus()"
                  (blur)="onInputBlur()"
                />
                <div class="input-group-append">
                  <a
                    href=""
                    class="input-group-text"
                    (click)="togglePassword($event)"
                  >
                    <i
                      class="fa"
                      [class]="{
                        'fa-eye-slash': passwordIsShow,
                        'fa-eye': !passwordIsShow
                      }"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <textarea
                class="form-control"
                [formControlName]="controlName"
                [placeholder]="getStringTypePlaceholder() | translate"
                [id]="elementId"
                [readonly]="readonly"
              ></textarea>
            </ng-container>
          </ng-container>
        </div>

        <div *ngSwitchCase="allType.MultiString">
          <app-tag-field-with-suggestion
            *ngIf="options.hasTagSuggestions"
            (itemsChange)="setValue($event)"
            [editable]="editable"
            [items]="formControl?.value"
            [placeholder]="(editable ? options.placeholder : '') | translate"
            [searchData$]="searchData$"
            [editable]="editable"
            (tagRemoved)="onTagSuggestionRemove($event)"
          ></app-tag-field-with-suggestion>

          <app-tag-field
            *ngIf="!options.hasTagSuggestions"
            (itemsChange)="setValue($event)"
            [editable]="editable"
            [items]="formControl?.value"
            [placeholder]="(editable ? options.placeholder : '') | translate"
          ></app-tag-field>
        </div>

        <div *ngSwitchCase="allType.Integer">
          <input
            type="text"
            mask="0*"
            min="0"
            class="form-control"
            [hidden]="!editable"
            [formControlName]="controlName"
            [placeholder]="options.placeholder || ''"
          />
        </div>

        <div
          *ngSwitchCase="allType.Double"
          class="d-flex align-items-center justify-content-start"
        >
          <input
            type="text"
            min="0"
            mask="separator.2"
            thousandSeparator=","
            decimalMarker="."
            class="form-control"
            [hidden]="!editable"
            [formControlName]="controlName"
            [placeholder]="options.placeholder || ''"
            [class.w-50]="attributeDescription.helpTextId"
          />

          <span *ngIf="attributeDescription.helpTextId" class="ml-2">
            {{ attributeDescription.helpTextId | translate }}
          </span>
        </div>

        <div *ngSwitchCase="allType.PhoneNumber">
          <input
            class="form-control"
            [formControlName]="controlName"
            [placeholder]="options.placeholder || ''"
            [pattern]="attributeDescription.regularExpression"
          />
        </div>

        <div *ngSwitchCase="allType.SingleSelection">
          <ng-container [ngSwitch]="attributeDescription.selectionType">
            <ng-container *ngSwitchCase="SelectionType.DropdownList">
              <app-boostrap-dropdown
                [editable]="!readonly && editable"
                [items]="selectionChoices"
                [currentIndex]="selectedIndex"
                [defaultLabel]="options.defaultLabel"
                (selectItem)="setValue($event)"
                [styleClass]="styleClass"
                [smartTyping]="options.smartTyping"
                [frequentlyUsedChoice]="
                  attributeDescription.frequentlyUsedChoice
                "
                [masterChoiceId]="attributeDescription.choice.masterCodeId"
              >
              </app-boostrap-dropdown>
            </ng-container>

            <ng-container *ngSwitchCase="SelectionType.Radio">
              <app-radio-selection
                [editable]="!readonly && editable"
                [items]="selectionChoices"
                [currentIndex]="selectedIndex"
                [styleClass]="styleClass"
                [controlName]="controlName"
                [horizontalSingleSelection]="options.horizontalSingleSelection"
                (selectItem)="setValue($event)"
              >
              </app-radio-selection>
            </ng-container>
          </ng-container>
        </div>

        <div *ngSwitchCase="allType.MultipleSelection">
          <app-multi-selection
            [selectedItems]="formControl?.value"
            [editable]="!readonly && editable"
            [selectionChoices]="selectionChoices"
            [onlyShowSelectedOptionsInViewMode]="options.onlyShowSelectedOptionsInViewMode"
            [isGridView]="options.showMultipleSelectionInGrid"
            (selectItem)="setValue($event)"
          >
          </app-multi-selection>
        </div>

        <div *ngSwitchCase="allType.VenturePhase">
          <app-venture-phase-detail
            (selectItem)="setValue($event)"
            [editable]="editable"
            [currentIndex]="selectedIndex"
            [allPhases]="selectionChoices"
          ></app-venture-phase-detail>
        </div>

        <div *ngSwitchCase="allType.VentureStatus">
          <app-venture-state
            (selectItem)="setValue($event)"
            [editable]="editable"
            [currentIndex]="selectedIndex"
            [items]="selectionChoices"
          >
          </app-venture-state>
        </div>

        <div *ngSwitchCase="allType.Image">
          <app-file-upload
            #imageUpload
            (srcUrlChange)="setValue($event)"
            [id]="options?.id"
            [multiLocale]="options.multiLocale"
            [inputId]="elementId"
            [allowCropImg]="allowCropImg"
            [locale]="language"
            [aspectRatio]="aspectRatio"
            [displayFileLoading]="displayFileLoading"
          >
            <ng-container *ngIf="!customUploadButtonTemplate">
              <i
                class="fas fa-camera fa-lg align-middle"
                [ngClass]="styleClass"
              ></i>
            </ng-container>
            <ng-container
              *ngIf="customUploadButtonTemplate"
              [ngTemplateOutlet]="customUploadButtonTemplate"
            >
            </ng-container>
          </app-file-upload>
        </div>

        <div *ngSwitchCase="allType.Files">
          <app-attachments-upload
            [files]="formControl?.value"
            (getFiles)="setValue($event)"
            [editable]="editable"
          >
            <i class="fas fa-camera"></i>
          </app-attachments-upload>
        </div>

        <div *ngSwitchCase="allType.MoodboardGroup">
          <app-moodboard-group
            [moodboardKey]="controlName"
            [groupTitle]="attributeDescription.displayName"
            [currentItems]="formControl?.value"
            (valueChange)="setValue($event)"
          ></app-moodboard-group>
        </div>

        <div *ngSwitchCase="allType.DateTime">
          <app-boostrap-datepicker
            [editable]="!readonly && editable"
            [currentTime]="formControl?.value"
            [pastTime]="options.pastTime"
            [futureTime]="options.futureTime"
            [hideTimeOfDate]="options?.hideTimeOfDate"
            [shouldUseDefaultDate]="options?.shouldUseDefaultDate"
            [placeholder]="attributeDescription.placeholder"
            [isOptional]="options?.isOptional"
            (dateTimeChange)="setValue($event)"
            [inputId]="elementId"
            [placement]="datepickerPlacement"
            [defaultDateTime]="options?.defaultDateTime"
          >
          </app-boostrap-datepicker>
        </div>

        <div *ngSwitchCase="allType.RTE">
          <quill-editor
            (onEditorCreated)="getEditorInstance($event)"
            [formControlName]="controlName"
            [placeholder]="options.placeholder"
            [readOnly]="!editable"
            [trimOnValidation]="true"
            (onContentChanged)="onRTEContentChanged($event)"
            (paste)="onRTEPasted($event)"
            [class.hidden-toolbar]="options.hideQuillToolbar"
            class="w-100"
          >
            <div quill-editor-toolbar>
              <span class="ql-formats">
                <span class="ql-formats">
                  <select class="ql-header">
                    <option value="1">Heading</option>
                    <option value="2">Subheading</option>
                    <option selected>Normal</option>
                  </select>
                </span>

                <span class="ql-formats">
                  <select class="ql-color"></select>
                </span>

                <span class="ql-formats">
                  <select class="ql-font">
                    <option selected>Sans Serif</option>
                    <option value="serif">Serif</option>
                    <option value="monospace">Monospace</option>
                  </select>
                </span>

                <span class="ql-formats">
                  <button
                    class="ql-bold"
                    aria-label="Bold"
                    type="button"
                  ></button>
                  <button
                    class="ql-italic"
                    aria-label="Italic"
                    type="button"
                  ></button>
                  <button
                    class="ql-underline"
                    aria-label="Underline"
                    type="button"
                  ></button>
                </span>

                <span class="ql-formats">
                  <button
                    class="ql-blockquote"
                    aria-label="Blockquote"
                    type="button"
                  ></button>
                </span>

                <span class="ql-formats">
                  <button
                    class="ql-list"
                    value="ordered"
                    aria-label="Ordered List"
                    type="button"
                  ></button>
                  <button
                    class="ql-list"
                    value="bullet"
                    aria-label="Unordered List"
                    type="button"
                  ></button>
                </span>

                <span class="ql-formats">
                  <button class="ql-indent" value="+1"></button>
                  <button class="ql-indent" value="-1"></button>
                </span>

                <span class="ql-formats">
                  <button
                    aria-label="Insert Link"
                    class="ql-link"
                    type="button"
                  ></button>
                </span>

                <span class="ql-formats" *ngIf="options.allowEmojiOnRTE">
                  <app-emojis-picker
                    (emojiSelected)="onEmojisClicked($event)"
                    btnStyleClass="ql-snow ql-picker"
                  ></app-emojis-picker>
                </span>

                <span class="ql-formats" *ngIf="options.allowImgOnRTE">
                  <app-file-upload
                    #fileUpload
                    (srcUrlChange)="
                      imageInputDisplay.setLoading(false);
                      imageInputDisplay.setImage($event);
                      imageInputDisplay.setImageUrl($event);
                      uploadChange($event)
                    "
                    (fileInputted)="
                      imageInputDisplay.setImage($event);
                      imageInputDisplay.setLoading(true)
                    "
                    [allowCropImg]="options.allowCropImg"
                    [aspectRatio]="options.aspectRatio"
                  >
                    <button class="ql-snow ql-picker">
                      <i class="fas fa-image fa-lg"></i>
                    </button>
                  </app-file-upload>
                </span>
              </span>
            </div>
          </quill-editor>

          <app-image-input-display
            (imageRemoved)="clearMessageImage()"
            #imageInputDisplay
          ></app-image-input-display>
        </div>

        <div *ngSwitchCase="allType.Boolean">
          <ng-container [ngSwitch]="attributeDescription.uiControlId">
            <ng-container *ngSwitchCase="UIControlId.ConfirmationV1">
              <app-check-box-confirmation
                [confirmationContent]="
                  attributeDescription.helpTextId | translate
                "
                [disabled]="readonly || !editable"
                [elementId]="attributeDescription.propertyName"
                [content]="attributeDescription?.displayName"
                [isChecked]="formControl?.value"
                (selectItem)="setValue($event)"
              ></app-check-box-confirmation>
            </ng-container>

            <ng-container *ngSwitchCase="UIControlId.RadioV1">
              <app-radio-boolean
                [trueText]="'Yes'"
                [falseText]="'No'"
                [controlName]="controlName"
                [isChecked]="formControl?.value"
                [editable]="editable"
                (selectItem)="setValue($event)"
              >
              </app-radio-boolean>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <app-check-box
                [disabled]="readonly || !editable"
                [elementId]="attributeDescription.propertyName"
                [content]="attributeDescription?.displayName"
                [tooltip]="
                  attributeDescription.helpTextId?.includes(
                    UIHelpTextPosition.Tooltip
                  )
                    ? attributeDescription.helpTextId
                    : undefined
                "
                [isChecked]="formControl?.value"
                (selectItem)="setValue($event)"
              ></app-check-box>
            </ng-container>
          </ng-container>
        </div>

        <div class="field-default" *ngSwitchDefault></div>
      </div>

      <div
        *ngIf="!editable && isViewAsText()"
        class="field-readonly w-100 width-custom"
        [class.white-space--pre-wrap]="
          type === allType.String &&
          attributeDescription.uiControlId === UIControlId.TextAreaV1
        "
      >
        <div *ngIf="requireFormatting(); else other">
          <ng-container *ngIf="type == allType.SingleSelection">
            <ng-container [ngSwitch]="attributeDescription.selectionType">
              <ng-container *ngSwitchCase="SelectionType.DropdownList">
                <app-boostrap-dropdown
                  [editable]="!readonly && editable"
                  [items]="selectionChoices"
                  [currentIndex]="selectedIndex"
                  [defaultLabel]="options.defaultLabel"
                  [replaceAsStarKey]="options.replaceAsStarKey"
                  (selectItem)="setValue($event)"
                >
                </app-boostrap-dropdown>
              </ng-container>

              <ng-container *ngSwitchCase="SelectionType.Radio">
                <app-radio-selection
                  [editable]="!readonly && editable"
                  [items]="selectionChoices"
                  [currentIndex]="selectedIndex"
                  [styleClass]="styleClass"
                  [controlName]="controlName"
                  [horizontalSingleSelection]="
                    options.horizontalSingleSelection
                  "
                  (selectItem)="setValue($event)"
                >
                </app-radio-selection>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="type == allType.Link">
            <a
              *ngIf="!hideLink"
              target="_blank"
              [value]="formControl?.value"
              appUrlPrefix
            >
              {{ formControl?.value }}
            </a>
          </ng-container>

          <ng-container *ngIf="type == allType.DateTime">
            {{ formControl?.value | localizedDate : 'fullDate' }}
          </ng-container>

          <ng-container *ngIf="type === allType.RTE">
            <ng-container *ngIf="options.areRowsLimited; else unfoldedText">
              <app-rich-text-toggle
                *ngIf="formControl?.value"
                [text]="formControl?.value"
                [controlName]="controlName"
                [isWideView]="options.isWideView"
              ></app-rich-text-toggle>
            </ng-container>
            <ng-template #unfoldedText>
              <quill-view [content]="formControl?.value"></quill-view>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="type === allType.Double">
            <span>{{ formControl?.value }}</span>
            <span *ngIf="attributeDescription.helpTextId" class="ml-2">
              {{ attributeDescription.helpTextId | translate }}
            </span>
          </ng-container>
        </div>

        <ng-template #other>
          <ng-container *ngIf="options.areRowsLimited; else formControlVal">
            <app-rich-text-toggle
              *ngIf="formControl?.value"
              [text]="formControl?.value"
              [controlName]="controlName"
              [isWideView]="options.isWideView"
            ></app-rich-text-toggle>
          </ng-container>

          <ng-template #formControlVal>
            {{
              options.isReplacedByStar
                ? (formControl?.value | star)
                : formControl?.value
            }}
          </ng-template>
        </ng-template>
      </div>

      <div class="form-field__postfix" [class.field-disabled]="!editable">
        <ng-content select="[part=postfix]"></ng-content>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isInvalid && !!customInvalidCondition">
  <div
    *ngIf="
      type != allType.Image &&
      type !== allType.RTE &&
      type !== allType.SocialLink
    "
    class="alert alert-danger"
  >
    <div *ngIf="formControl.hasError('required'); else othersError">
      <ng-container *ngTemplateOutlet="requiredError"></ng-container>
    </div>

    <ng-template #othersError>
      <div *ngIf="formControl.hasError('maxlength')">
        <ng-container *ngTemplateOutlet="maxLengthError"></ng-container>
      </div>

      <div *ngIf="formControl.hasError('integerOnly')">
        <span translate>Please enter a valid integer</span>
      </div>

      <div *ngIf="formControl.hasError('notEmptyOrWhitespace')">
        {{ 'cannot be empty or whitespace' | translate }}
      </div>

      <div *ngIf="formControl.hasError('nameDuplicated')">
        {{ formControl.getError('nameDuplicated').message }}
      </div>

      <div *ngIf="formControl.hasError('pattern')">
        <ng-container
          *ngIf="
            attributeDescription.validationErrorTextId;
            else defaultPatternError
          "
        >
          <span translate>
            {{ attributeDescription.validationErrorTextId }}</span
          >
        </ng-container>

        <ng-template #defaultPatternError>
          <span class="pr-1" translate>Please enter a valid value</span>

          <ng-container [ngSwitch]="type">
            <ng-template [ngSwitchCase]="allType.PhoneNumber"
              >e.g. +41 (0) 12 3456789</ng-template
            >

            <ng-template [ngSwitchCase]="allType.Link"
              >e.g. www.building-excellence.ch</ng-template
            >
          </ng-container>
        </ng-template>
      </div>

      <div *ngIf="formControl.hasError('duplicate')">
        {{ 'UI.ErrorMessage.DuplicatePhaseName' | translate }}
      </div>
    </ng-template>
  </div>

  <div *ngIf="type === allType.RTE">
    <div *ngIf="richText.required" class="alert alert-danger">
      <ng-container *ngTemplateOutlet="requiredError"></ng-container>
    </div>

    <div *ngIf="richText.maxlength" class="alert alert-danger">
      <ng-container *ngTemplateOutlet="maxLengthError"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #requiredError>
  {{ 'Field is required.' | translate }}
</ng-template>

<ng-template #maxLengthError>
  <span translate>Maximum Length:</span>
  {{ attributeDescription?.maximumLength }}
</ng-template>

<ng-template #hiddenContentRef>
  <app-hidden-content></app-hidden-content>
</ng-template>
