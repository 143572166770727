import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { GetInTouchDialogComponent } from '@app/components/dialogs/get-in-touch-dialog/get-in-touch-dialog.component';
import { PendingPeopleCardsComponent } from '@components/peoples/components/people-cards/pending-people-cards/pending-people-cards.component';
import { OrganizationHttpService } from '@core/http/organization-http.service';
import {
  VENTURE_CHARACTERISTICS,
  VENTURE_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES,
  VENTURE_REQUIRED_KEY,
  VentureHttpService,
} from '@core/http/venture.http.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ChangeNdaInformModalComponent } from '@shared/components/organisms/change-nda-inform-modal/change-nda-inform-modal.component';
import { VentureSubmitionInformModalComponent } from '@shared/components/organisms/venture-submition-inform-modal/venture-submition-inform-modal.component';
import { OrganizerComponent } from '@shared/components/organizer/organizer.component';
import { VentureOrganizationsComponent } from '@shared/components/venture-organizations/venture-organizations.component';
import { TemplateName } from '@shared/constants/visibility-config.const';
import { DeactiveShowType } from '@shared/enums/deactive-show-type.enum';
import { VenturePropertyName } from '@shared/enums/property-name.enum';
import { UrlParam } from '@shared/enums/url-param.enum';
import { MetadataRecord } from '@shared/interfaces/metadata-table.interface';
import { OrganizationInterface } from '@shared/interfaces/organization.interface';
import { UserInterface } from '@shared/interfaces/user.interface';
import { VentureInterface } from '@shared/interfaces/venture.interface';
import { getActiveSectionIds } from '@shared/utils/venture.utils';
import { BaseEntityEditComponent } from '@src/app/components/base/base-detail/base-entity-edit/base-entity-edit.component';
import { ConfirmationComponent } from '@src/app/components/confirmation/confirmation.component';
import { ShareEntityToPeopleDialogComponent } from '@src/app/components/dialogs/share-entity-to-people-dialog/share-entity-to-people-dialog.component';
import { TimestampSectionComponent } from '@src/app/components/entity-section/timestamp-section/timestamp-section.component';
import { AuthenService } from '@src/app/core/authen/authen.service';
import { ChallengeHttpService } from '@src/app/core/http/challenge-http.service';
import { SendMessageHttpService } from '@src/app/core/http/send-messages-http.service';
import { TagsHttpService } from '@src/app/core/http/tags-http.service';
import { NotificationService } from '@src/app/core/notification.service';
import { SessionService } from '@src/app/core/session.service';
import { CustomDateParser2Formatter } from '@src/app/shared/components/boostrap-datepicker/boostrap-datepicker.component';
import { FormFieldCustomComponent } from '@src/app/shared/components/form-custom-attributes/form-field-custom/form-field-custom.component';

import { CentralConfigService } from '@src/app/core/services/central-config.service';
import { transformToTagModaByKey } from '@src/app/shared/components/tag-field/tag-field.component';
import { maxPageSize } from '@src/app/shared/constants/common';
import {
  AttributeType,
  SystemType,
} from '@src/app/shared/enums/attribute-type.enum';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { OrgsSearchMode } from '@src/app/shared/enums/org-search-mode.enum';
import { SessionKeyEnum } from '@src/app/shared/enums/session.key.enum';
import { TokenTypes } from '@src/app/shared/enums/token-type';
import {
  VentureMessageRights,
  VentureSectionIndex,
  VentureStateCodeId,
} from '@src/app/shared/enums/venture.enum';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { InfiniteScrollDataAdapter } from '@src/app/shared/helpers/infinite-scroll-data.adapter';
import {
  AttributeDescription,
  AttributeValue,
  Choice,
  CustomAtrributeValue,
} from '@src/app/shared/interfaces/attribute-description.interface';
import { ChallengeInterface } from '@src/app/shared/interfaces/challenge.interface';
import { ImageInterface } from '@src/app/shared/interfaces/file.interface';
import { LeadCompany } from '@src/app/shared/interfaces/lead-company.interface';
import { ApiGetResponse } from '@src/app/shared/interfaces/responses/ApiResponse.interface';
import { LinkWrapperPipe } from '@src/app/shared/pipes/link-wrapper.pipe';
import { ArrayUtils } from '@src/app/shared/utils/array-utils';
import { DateValidators } from '@src/app/shared/utils/form-fields-validation';
import { FormUtils } from '@src/app/shared/utils/form-utils';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, from, of } from 'rxjs';
import { filter, finalize, skip, take } from 'rxjs/operators';
import { PublishVentureConfirmationDialogComponent } from '../publish-venture-confirmation-dialog/publish-venture-confirmation-dialog.component';

@Component({
  selector: 'app-venture-edit',
  templateUrl: './venture-edit.component.html',
  providers: [
    VentureHttpService,
    { provide: NgbDateParserFormatter, useClass: CustomDateParser2Formatter },
  ],
})
export class VentureEditComponent
  extends BaseEntityEditComponent<VentureInterface>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() globalOrgId: number; // Central config

  @Input() uiTemplateConfig: TemplateName = TemplateName.Default;

  @ViewChild('timestampSection') timestampSection: TimestampSectionComponent;
  @ViewChild(PendingPeopleCardsComponent)
  pendingPeopleComp: PendingPeopleCardsComponent;

  @ViewChild(VentureOrganizationsComponent)
  ventureOrgCmpRef: VentureOrganizationsComponent;

  @ViewChild('organizerComponent') organizerComponent: OrganizerComponent;

  @ViewChild('businessArea') businessArea: FormFieldCustomComponent;

  @ViewChild('imageControl')
  imageControl!: FormFieldCustomComponent;

  createOrgBaseUrl = environment.jipUrl.organizations;
  contentExcerptError = 'contentExcerptError';
  VENTURE_CHARACTERISTICS = VENTURE_CHARACTERISTICS;
  VENTURE_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES =
    VENTURE_CUSTOM_ATTRIBUTE_ORDER_OF_PROPERTY_NAMES;

  isLinkedToExistingOrg: boolean;
  markOrgFormTouched: boolean;

  isEditingOrg = false;

  isOpenVenture = true;

  showFollowersModal = false;
  showLikesModal = false;
  canEditLeadCompany = false;
  organizationsToSubmit: LeadCompany[] = [];
  deactiveShowTypeEnum = DeactiveShowType;

  isPendingPeopleLoading = false;
  customUserRoles: CustomAtrributeValue[] = [];
  customAdminRoles: CustomAtrributeValue[] = [];

  VentureSectionIndex = VentureSectionIndex;

  timestampId = '';

  filteringKeyword: string;

  entityBase: string = environment.jipUrl.venture;

  shouldShowHiddenMarkBackground: boolean;

  tenantName = '';

  ventureMessageRightSelectedIndex = 0;

  searchData$: InfiniteScrollDataAdapter;

  defaultBanner: ImageInterface = null;

  TemplateName = TemplateName;

  readonly TokenTypes = TokenTypes;

  get leadCompany(): AbstractControl {
    return this.form?.controls?.LeadCompany;
  }

  get hasDefaultBanner(): boolean {
    return !!this.defaultBanner?.url;
  }

  get shouldHideDemandSection(): boolean {
    return (
      !this.isCreating &&
      (this.uiTemplateName === TemplateName.Reduced3 ||
        this.uiTemplateName === TemplateName.Reduced4)
    );
  }

  //#region INJECTOR
  private linkWrapperPipe: LinkWrapperPipe;
  private notificationService: NotificationService;
  private tagHttpService: TagsHttpService;
  private centralConfigService: CentralConfigService;
  //#endregion End Injector

  constructor(
    public baseHttpService: VentureHttpService,
    protected authenService: AuthenService,
    protected sessionService: SessionService,
    protected injector: Injector,
    private challengeHttpService: ChallengeHttpService,
    private translateService: TranslateService,
    private cookieService: CookieService,
    protected sendMessageHttpService: SendMessageHttpService
  ) {
    super(baseHttpService, authenService, sessionService, injector);
    this.linkWrapperPipe = injector.get<LinkWrapperPipe>(LinkWrapperPipe);

    this.notificationService =
      injector.get<NotificationService>(NotificationService);
    this.tagHttpService = injector.get<TagsHttpService>(TagsHttpService);
    this.centralConfigService =
      injector.get<CentralConfigService>(CentralConfigService);

    this.entityName = EntityName.Venture;
    this.orgsSearchMode = OrgsSearchMode.ForLead;
    this.tenantName = this.cookieService.get(SessionKeyEnum.TENANT_NAME);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.listenToUiTemplateChange();
  }

  protected handleSyncEntityStateCount(): void {
    this.syncLikingsState();
    this.syncFollowingsState();
    this.syncCommentsState();
    this.syncEntityJoinStates();
    this.syncCheckUserIsOrgAdminOfEntity();
  }

  customInit(): void {
    this.ventureDemands = this.baseHttpService.getDemands();
    this.entityRoot = environment.jipUrl.venture;

    this.formSections = [
      {
        fieldsName: ['Title'], // 0
      },
      {
        fieldsName: ['Phase', 'CbChartaPhase'],
      },
      {
        fieldsName: ['Excerpt', 'Content', 'BestPractices', 'LinkAttatchFiles'],
      },
      {
        fieldsName: ['Demands', 'Terms', 'isNDARequired'],
      },
      {
        fieldsName: ['LeadCompany'],
      },
      {
        fieldsName: [...VENTURE_CHARACTERISTICS, 'BusinessAreas'],
      },
      {
        fieldsName: ['Stakeholders'],
      },
      {
        fieldsName: [
          'LinkCompanyHomePage',
          'LinkCollaborationSpaces',
          'LinkAttatchFiles',
        ],
      },
      {
        fieldsName: ['SubmittedOrganizations'],
      },
      {
        fieldsName: ['Status'],
      },
      {
        fieldsName: ['SendMessageRights'],
      },
    ];

    this.addIsPrivateToFormSection();
    this.listenFilterChanged();
  }

  // #region HANDLE ISPRIVATE ATTRIBUTE DESCRIPTION
  addIsPrivateToFormSection(): void {
    if (this.isCreating) {
      this.formSections[VentureSectionIndex.Description].fieldsName.push(
        'IsPrivate'
      );
    }
  }

  createFormUsingMetaInfo(descriptions: any): void {
    if (this.isCreating && descriptions) {
      const isPrivateIndex =
        descriptions.entityDescription?.attributeDescriptions.findIndex(
          (x) => x.propertyName === 'IsPrivate'
        );
      if (isPrivateIndex !== -1) {
        this.modifyIsPrivateAttributeDescriptionOnCreating(
          descriptions.entityDescription?.attributeDescriptions[isPrivateIndex]
        );
      }
    }
    super.createFormUsingMetaInfo(descriptions);
  }

  private modifyIsPrivateAttributeDescriptionOnCreating(
    attrDes: AttributeDescription
  ): void {
    const isPrivateDefaultValue: CustomAtrributeValue = {
      codeId: 'DefaultValue',
      value: this.translateService.instant('UI.Dropdown.DefaultValue'),
      order: 0,
    };

    const publishTrans = this.translateService.instant('Published');
    const unpublishTrans = this.translateService.instant('Unpublished');

    const isPrivateSelections: CustomAtrributeValue[] = [
      isPrivateDefaultValue,
      {
        codeId: 'false',
        value: publishTrans,
        order: 1,
      },
      {
        codeId: 'true',
        value: unpublishTrans,
        order: 2,
      },
    ];

    const isPrivateChoice: Choice = {
      codeId: 'IsPrivate',
      name: 'Select Privacy Options',
      selections: isPrivateSelections,
    };

    attrDes.selectionType = 'DropdownList';
    attrDes.attributeType = AttributeType.SingleSelection;
    attrDes.required = true;
    attrDes.apiType = 'Selection';
    attrDes.systemType = SystemType.Selection;
    attrDes.choice = isPrivateChoice;
    attrDes.defaultValue = isPrivateDefaultValue;
  }
  // #endregion End Handle IsPrivate Attribute Description

  ngOnChanges(changes): void {
    const timestampId = StringUtils.getParamFromUrl(UrlParam.TimestampId);
    if (timestampId) {
      this.timestampId = timestampId;
      return;
    }

    super.ngOnChanges(changes);
    if (changes.isCreating && this.isCreating) {
      this.sessionService.apiReady$
        .pipe(untilDestroyed(this))
        .subscribe((ready) => {
          if (ready) {
            this.handleSubmitToChallenge();
            this.handleSubmitToLeadOrg();
          }
        });
    }
  }

  listenFilterChanged(): void {
    if (this.uiTemplateConfig === TemplateName.Default) return;

    this.filterStoredService
      .getCurrentFilterToken()
      .pipe(
        skip(1), // Skip the first request since it is already handled by the Baseclass
        filter(() => !!this.entity?.id),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.handleLoading(true);

        this.getLatestEntity(this.entity.id);
      });
  }

  private handleSubmitToChallenge(): void {
    /* Note: a Challenge is similar to an Organisation except for some additional info e.g. Challenge Timeline */
    const id = Number(StringUtils.getParamFromUrl(UrlParam.OrganizationId));

    if (id && !isNaN(id)) {
      this.challengeHttpService
        .read(id)
        .pipe(untilDestroyed(this))
        .subscribe((challenge: ChallengeInterface) => {
          this.submitVentureToChallenge(challenge);
        });
    }
  }

  private handleSubmitToLeadOrg(): void {
    const leadOrgId = Number(StringUtils.getParamFromUrl(UrlParam.LeadOrgId));

    if (leadOrgId && !isNaN(leadOrgId)) {
      const organizationService = this.injector.get(OrganizationHttpService);

      organizationService
        .read(leadOrgId)
        .pipe(untilDestroyed(this))
        .subscribe((org: OrganizationInterface) => {
          this.isLinkedToExistingOrg = true;

          this.organizerComponent?.onSelect(org);
        });
    }
  }

  private submitVentureToChallenge(challenge: ChallengeInterface): void {
    if (challenge?.id && challenge.showAsChallenge) {
      // 14851 Temporary remove check related to challenge
      // if (!challenge.canSubmitVentureToChallenge && this.sessionService.isLogin) {
      //   this.showReasonWhyCannotSubmitVentureToCall(challenge, 'UI.VentureEdit.CanOnlySubmitVentureToRelatedChallenge');
      //   return;
      // }

      if (challenge.challengeTimeline[0]?.isCurrent) {
        if (challenge.isPrivate) {
          this.handleSubmitToPrivateChallenge(challenge);
        } else {
          this.setSubmitOrg(challenge);
        }
      } else {
        this.goTo(
          `${environment.jipUrl.challenges}/${challenge.id}?${UrlParam.PreviousAction}=create-venture`
        );
      }
    }
  }

  private getEntityLink(
    translateText: string,
    entityName: string,
    challengeId: number
  ): string {
    const entityUrl = StringUtils.getEntityUrlByEntityName(entityName);
    if (!entityUrl) {
      return '';
    }
    const url = this.sessionService.appendLanguagePath(
      `${entityUrl}/${challengeId}`
    );

    return this.linkWrapperPipe.transform(url, translateText, '', 'underline');
  }

  private handleSubmitToPrivateChallenge(challenge: ChallengeInterface): void {
    this.baseHttpService
      .checkSubmitVenture(challenge.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.setSubmitOrg(challenge);
        },
        error: (errorRes: HttpErrorResponse) => {
          if (errorRes.error?.title) {
            this.notificationService
              .show('Information', errorRes.error?.title)
              .result.finally(() => {
                this.unsavedFormCheckService.clearAll();
                this.goToListPage();
              });
          } else {
            this.unsavedFormCheckService.clearAll();
            this.goToListPage();
          }
        },
      });
  }

  setSubmitOrg(challenge: ChallengeInterface): void {
    this.organizationsToSubmit = [
      {
        ...challenge,
        name: challenge.orgName,
        url: challenge.orgUrl,
      },
    ];
  }

  getRequiredKeys(): string[] {
    return VENTURE_REQUIRED_KEY;
  }

  protected getCustomvalidation(): ValidatorFn | ValidatorFn[] {
    const customValidator: ValidatorFn | ValidatorFn[] = [
      (control: UntypedFormGroup): { [key: string]: any } | null => {
        return FormUtils.hasContentInAnyControl(
          control,
          this.contentExcerptError,
          ['Excerpt', 'Content']
        );
      },
    ];

    const startDateField = 'StartDate';
    const deadlineField = 'Deadline';
    if (
      this.form &&
      this.form.get(startDateField) &&
      this.form.get(deadlineField)
    ) {
      customValidator.push(
        DateValidators.compareTwoDate(
          startDateField,
          true,
          deadlineField,
          `StartDateError`
        )
      );
      customValidator.push(
        DateValidators.compareTwoDate(
          deadlineField,
          false,
          startDateField,
          `DeadlineError`
        )
      );
    }
    return customValidator;
  }

  shouldShowContentExcerptError(): boolean {
    if (!this.form) {
      return false;
    }
    const excerpt = this.form.controls.Excerpt;
    const content = this.form.controls.Content;
    if (!excerpt || !content) {
      return false;
    }

    return (
      this.form.hasError(this.contentExcerptError) &&
      (excerpt.touched || excerpt.dirty || content.touched || content.dirty)
    );
  }

  shouldShowDescriptionErrorForCbcharta(): boolean {
    if (!this.form) {
      return false;
    }
    const excerpt = this.form.controls.Excerpt;

    if (!excerpt) {
      return false;
    }

    return (
      this.form.hasError(this.contentExcerptError) &&
      (excerpt.touched || excerpt.dirty)
    );
  }

  showLeadOrgError(): boolean {
    const leadCompany = this.form?.controls?.LeadCompany;
    if (!leadCompany || !(leadCompany.touched || leadCompany.dirty)) {
      return false;
    }

    if (this.isLinkedToExistingOrg) {
      return !leadCompany.value?.id;
    }

    return !leadCompany.value;
  }

  updateFormValue(): void {
    super.updateFormValue();
    if (this.entity) {
      this.isOpenVenture = !this.entity.isNDARequired;
      if (this.entity.demands) {
        const Demands = this.getDemandsFromEntity();
        this.form.patchValue({ Demands });
      }

      this.isLinkedToExistingOrg = !!this.entity.leadCompany?.id;

      if (this.entity?.id) {
        this.loadStakeholders();
      }
    }
  }

  protected afterFormChangedStatus(): void {
    this.checkCanChangeLeadOrg();
  }

  checkCanChangeLeadOrg(): void {
    if (this.entity) {
      this.canEditLeadCompany =
        !this.isCreating &&
        this.editable &&
        (!this.entity?.leadCompany?.id || !this.entity?.leadCompany?.isPrivate);
    }
  }

  getDtoField(name, force = false): object {
    switch (name) {
      case 'Image':
        return this.getImageDto();
      case 'Stakeholders':
      case 'VentureStakeholders':
        return { stakeholders: this.getStakeholdersDto() };
      case 'SubmittedOrganizations':
        return this.getSubmittedOrganizationsDto(name);
      default:
        return super.getDtoField(name, force);
    }
  }

  formatField(dto, key): void {
    switch (key) {
      case 'stakeholders':
        dto[key] = this.getStakeholdersDto();
        break;
      case 'linkCompanyHomePage':
        dto[key] = dto[key] ? dto[key][0] : null;
        break;
      case 'linkAttatchFiles':
        const items = dto[key] || [];
        dto[key] = items.map((item) => {
          return item?.id;
        });
        break;
      case 'leadCompany':
        const leadCompany: LeadCompany = dto[key];
        dto[key] = {
          ...leadCompany,
          link: StringUtils.getCorrectUrl(leadCompany.link),
          logo: leadCompany.logo?.url,
        };
        break;

      default:
        super.formatField(dto, key);
    }
  }

  getStakeholdersDto(): any {
    return this.stakeholders?.map((item) => {
      return item.id;
    });
  }

  getDemandsFromEntity(): any {
    const items = this.entity.demands || [];
    return transformToTagModaByKey(items, !this.editable, 'content', 'content');
  }

  getFormFieldValueFromEntity(name): any {
    switch (name) {
      case 'Demands':
        return this.getDemandsFromEntity();
      case 'LinkCompanyHomePage':
        return [
          {
            url: this.entity?.linkCompanyHomePage?.url,
            faIconClass: 'fa-home',
          },
        ];
      default:
        return super.getFormFieldValueFromEntity(name);
    }
  }

  getDto(): VentureInterface {
    const result = super.getDto();
    if (this.form && this.form.value) {
      Object.assign(result, this.getCustomSections());
    }

    return result;
  }

  getSectionInfo(index: number): any {
    const section = this.formSections[index];
    if (section) {
      const result = super.getSectionInfo(index);
      if (section.fieldsName.includes('Demands')) {
        Object.assign(result, this.getDemandsDto());
      }
      if (section.fieldsName.includes('isNDARequired')) {
        Object.assign(result, this.getNdaDto());
      }
      // always get image id from form input
      if (!this.isSaveFromCustomAttributeSection) {
        Object.assign(result, this.getImageDto());
      }
      return result;
    }

    return {};
  }

  getCustomSections(): any {
    const result = this.getImageDto();
    Object.assign(result, this.getDemandsDto());
    Object.assign(result, this.getNdaDto());
    return result;
  }

  getDemandsDto(): any {
    if (this.form.getRawValue().Demands) {
      return {
        demands: this.form.getRawValue().Demands.map((item) => {
          return {
            id: item.id ?? null,
            value: item.value,
          };
        }),
      };
    }

    return {};
  }

  getNdaDto(): any {
    return {
      isNDARequired: !this.isOpenVenture,
    };
  }

  updateLeadOrgFormValue(orgFormValue: any): void {
    this.form.patchValue({
      LeadCompany: orgFormValue,
      LeadCompanyLink: orgFormValue?.link,
      LeadCompanyLogo: orgFormValue?.logoId,
      LeadCompanyName: orgFormValue?.name,
    });

    if (orgFormValue) {
      FormUtils.markControlAsTouch(this.form, 'LeadCompany');
    }
  }

  onLeadOrgSelectedEvent(item?: OrganizationInterface): void {
    const customAttributeValues = item ? item.attributeValues : null;
    this.setDisplayUiTemplate(!item, customAttributeValues);

    this.generateCustomAttributeFormWhenCreate();
  }

  generateCustomAttributeFormWhenCreate(): void {
    if (this.isCreating && this.form) {
      const formValue = this.form.getRawValue();
      const leadCompanyId = formValue?.LeadCompany?.id?.toString();
      const submittedOrganizations =
        formValue?.SubmittedOrganizations?.map((x) => x.id?.toString()) || [];
      super.generateCustomAttributeFormWhenCreate(
        leadCompanyId,
        submittedOrganizations
      );
    }
  }

  removeEditingSectionIndex(index: number): void {
    super.removeEditingSectionIndex(index);
    this.checkCanChangeLeadOrg();
  }

  updateStakeholder(items: UserInterface[]): void {
    FormUtils.removeError(this.form, 'formError');
    this.isSubmitting = false;
    this.isSubmitted = false;

    const updatedstakeHolders = items ? items : [];
    this.stakeholders = [...updatedstakeHolders];

    if (!this.isCreating) {
      this.saveSection(VentureSectionIndex.Stakeholders)
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            this.isPendingPeopleLoading = false;
          })
        )
        .subscribe((res: boolean) => {
          if (!res && this.entity) {
            this.getLatestEntity(this.entity.id);
          }
        });
    } else {
      FormUtils.maskFormAsTouched(this.form);
    }
  }

  updateOrganisationPayload(values: number[]): void {
    this.form.patchValue({
      SubmittedOrganizations: values,
    });
    this.generateCustomAttributeFormWhenCreate();
  }

  getSubmittedOrganizationsDto(controlName): any {
    const items = this.form.getRawValue()[controlName] as any[];
    return {
      SubmittedOrganizations: items?.map((item) => {
        return item?.id ? item?.id : item;
      }),
    };
  }

  markOrgFormAsTouched(): void {
    this.markOrgFormTouched = true;
  }

  shouldShowVentureDetails(): boolean {
    if (this.isOwner || this.isCreating) {
      return true;
    }

    for (const key of VENTURE_CHARACTERISTICS) {
      if (
        this.getFieldOptions(key)?.isVisibleInSystem ||
        this.form?.getRawValue()[key]
      ) {
        return true;
      }
    }
  }

  publish(): void {
    const confirmMessage = 'UI.Venture.AskConfirmPublishAllStakeholders';
    const confirmAction = 'UI.Venture.ConfirmPublish';

    this.handlePublishVenture(confirmMessage, confirmAction, true);
  }

  unpublish(): void {
    const confirmMessage = 'UI.Venture.AskConfirmUnpublish';
    const confirmAction = 'UI.Venture.ConfirmUnpublish';

    this.handlePublishVenture(confirmMessage, confirmAction, false);
  }

  handlePublishVenture(
    confirmMessage: string,
    confirmAction: string,
    willPublish: boolean
  ): void {
    const modalRef = this.modalService.open(
      PublishVentureConfirmationDialogComponent,
      {
        centered: true,
        backdrop: 'static',
        size: 'lg',
        scrollable: false,
      }
    );

    modalRef.componentInstance.confirmContent = confirmMessage;
    modalRef.componentInstance.confirmAction = confirmAction;

    modalRef.result
      .then((accepted) => {
        if (this.baseHttpService.publish && this.entity && accepted) {
          if (willPublish) {
            this.baseHttpService
              .publish(this.id)
              .pipe(
                untilDestroyed(this),
                finalize(() => {
                  this.getLatestEntity(this.entity.id);
                })
              )
              .subscribe((res) => {
                this.entity.isPrivate = false;
              });
          } else {
            this.baseHttpService
              .unpublish(this.id)
              .pipe(
                untilDestroyed(this),
                finalize(() => {
                  this.getLatestEntity(this.entity.id);
                })
              )
              .subscribe((res) => {
                this.entity.isPrivate = true;
              });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getInTouch(): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const isEnableCommunication =
      this.sessionService.tenant.enableCommunication;

    const modalRef = this.modalService.open(GetInTouchDialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      scrollable: false,
      windowClass: `message-modal-custom ${
        !isEnableCommunication && 'service-not-available-dialog'
      }`,
    });

    modalRef.componentInstance.venture = this.entity;
  }

  reverseLinkToExistingOrg(): void {
    this.isLinkedToExistingOrg = !!this.entity?.leadCompany?.id;

    if (!this.entity.leadCompany) {
      this.organizerComponent?.resetCompanyInput();
    }
  }

  // Override permission, allow edit venture for both Admin & Team
  isInitiator(profile: UserInterface, entity: VentureInterface): boolean {
    return (
      BaseEntityEditComponent.hasPermission(profile, entity, this.initiators) ||
      ArrayUtils.hasItem(this.entity?.stakeholders, profile, 'id')
    );
  }

  handleAfterApprovePeople(person: UserInterface): void {
    this.getLatestEntity(this.entity.id);
  }

  handleAfterAddPeople(): void {
    if (!this.isCreating) {
      this.isPendingPeopleLoading = true;
    }
  }

  loadStakeholders(): void {
    this.baseHttpService
      .paginateByEntity(environment.jipUrl.stakeholders, this.entity?.id, {
        pageSize: maxPageSize,
      })
      .pipe(untilDestroyed(this))
      .subscribe((res: any) => {
        this.stakeholders = res?.items;
      });
  }

  handleCustomMetadata(
    attributeDescriptions: AttributeDescription[] = [],
    attributeValues: AttributeValue[] = []
  ): void {
    const adminRolePropertyName = 'AdminRole';
    this.customAdminRoles = [];
    this.customUserRoles = [];

    if (attributeDescriptions?.length > 0) {
      const adminRoleAttrDes = attributeDescriptions.find(
        (x) => x.propertyName === adminRolePropertyName
      );
      if (adminRoleAttrDes) {
        this.customAdminRoles = adminRoleAttrDes.choice.selections;

        const adminRoleAttrVal = attributeValues.find(
          (x) => x.propertyName === adminRolePropertyName
        );
        if (this.entity?.initiator) {
          this.entity.initiator.role =
            adminRoleAttrVal?.describedValue?.selection ??
            adminRoleAttrDes.defaultValue;
        }
        // Remove Admin Role from Dedicated Section
        attributeDescriptions = attributeDescriptions.filter(
          (x) => x.propertyName !== adminRolePropertyName
        );
        attributeValues = attributeValues.filter(
          (x) => x.propertyName !== adminRolePropertyName
        );
      }
    }

    if (this.businessArea) {
      this.businessArea.attributeDescription = this.getFieldOptions(
        'BusinessAreas',
        {
          attributeDescriptions,
        }
      );
    }

    super.handleCustomMetadata(attributeDescriptions, attributeValues);

    if (this.entity?.id) {
      this.baseHttpService
        .getStakeHolderCustomAttribute(this.entity?.id)
        .pipe(untilDestroyed(this))
        .subscribe((attrDesList: AttributeDescription[]) => {
          const roleAttrDes = attrDesList?.find(
            (x) => x.propertyName === 'Role'
          );
          this.customUserRoles = roleAttrDes
            ? roleAttrDes.choice?.selections
            : [];
        });
    }
  }

  openShareEntityToPeopleDialog(entity: VentureInterface): void {
    if (!this.sessionService.isLogin) {
      return;
    }

    const isEnableCommunication =
      this.sessionService.tenant.enableCommunication;

    const modalRef = this.modalService.open(
      ShareEntityToPeopleDialogComponent,
      {
        centered: true,
        backdrop: 'static',
        size: 'lg',
        scrollable: false,
        windowClass: `message-modal-custom ${
          !isEnableCommunication && 'service-not-available-dialog'
        }`,
      }
    );

    modalRef.componentInstance.entityName = this.entityName;
    modalRef.componentInstance.entity = entity;
  }

  isSendMessToMemberShown(): boolean {
    if (!this.isCreating) {
      if (this.isOwner && !this.isTeam) {
        return this.stakeholders?.length > 0 && this.canSendMessage();
      }
      return this.canSendMessage();
    }
    return false;
  }

  isInviteSectionDisabled(venture: VentureInterface): boolean {
    const disabledState: string[] = [
      VentureStateCodeId.Suspended,
      VentureStateCodeId.Closed,
      VentureStateCodeId.Deactivated,
    ];
    if (
      disabledState.includes(venture.status?.codeId) ||
      venture.isNDARequired
    ) {
      return true;
    }
    return false;
  }

  onCreateTimestampSuccess(result: boolean): void {
    if (result) {
      this.timestampSection.getLstTimestamp();
    }
  }

  deleteVenture(): void {
    const modalRef = this.modalService.open(ConfirmationComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.confirmLabel = 'Confirm';
    modalRef.componentInstance.message = 'UI.Venture.ConfirmDelete';
    modalRef.result.then((accepted: boolean) => {
      if (accepted) {
        this.baseHttpService
          .delete(this.entity?.id)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: () => {
              this.toastService.showSuccess(
                'UI.Venture.Toast.DeletedSuccessfully'
              );
              this.goToListPage();
            },
            error: () => {
              this.toastService.showError('UI.Toast.DeletedFailed');
            },
          });
      }
    });
  }

  get canDeleteVenture(): boolean {
    return (
      this.sessionService.isLogin &&
      ((this.isOwner && !this.isTeam) || this.profile?.isTenantAdmin)
    );
  }

  afterLeaveVenture(isLeaveSuccess: boolean): void {
    if (isLeaveSuccess) {
      this.getLatestEntity(this.id);
    }
  }

  onSearchPeople(event: string): void {
    this.filteringKeyword = event;
  }

  removePendingPerson(person: UserInterface): void {
    this.pendingPeopleComp.removeItem(person);
  }

  beforeSubmitVenture(entity: VentureInterface): Observable<boolean> {
    const submittingChallenges = this.form.get(
      VenturePropertyName.SubmittedOrganizations
    )?.value;
    const submittedItems = entity.submittedOrganizations;

    const hasNewChallenge = submittingChallenges.some(
      (challenge: LeadCompany) =>
        !submittedItems.find((item) => item.id === challenge.id)
    );

    if (entity.isNDARequired && hasNewChallenge) {
      const modalRef = this.modalService.open(
        VentureSubmitionInformModalComponent,
        {
          windowClass: '',
          centered: true,
          backdrop: 'static',
          size: 'md',
        }
      );

      return from(modalRef.result);
    }

    return of(true);
  }

  beforeChangeNDA(venture: VentureInterface): Observable<boolean> {
    if (this.isOpenVenture === !venture.isNDARequired || this.isOpenVenture) {
      return of(true);
    }

    if (venture.submittedOrganizations?.length > 0) {
      const modalRef = this.modalService.open(ChangeNdaInformModalComponent, {
        windowClass: '',
        centered: true,
        backdrop: 'static',
        size: 'md',
      });

      return from(modalRef.result);
    }

    return of(true);
  }

  beforeSelectLeadOrg(venture: VentureInterface): Observable<boolean> {
    const entity = this.getDto();

    if (venture.isNDARequired && entity.leadCompany?.id) {
      const modalRef = this.modalService.open(
        VentureSubmitionInformModalComponent,
        {
          windowClass: '',
          centered: true,
          backdrop: 'static',
          size: 'md',
        }
      );

      return from(modalRef.result);
    }
    return of(true);
  }

  onSubmit(): void {
    let { isNDARequired, leadCompany } = this.getDto();
    const { SubmittedOrganizations } = this.getDtoField(
      'SubmittedOrganizations'
    ) as { SubmittedOrganizations: LeadCompany[] };

    if (this.uiTemplateName === TemplateName.Reduced4) {
      isNDARequired = true;
    }

    if (isNDARequired && (SubmittedOrganizations?.length || leadCompany?.id)) {
      this.modalService
        .open(VentureSubmitionInformModalComponent, {
          windowClass: '',
          centered: true,
          backdrop: 'static',
          size: 'md',
        })
        .result.then((res: boolean) => {
          res && super.onSubmit();
        });
    } else {
      super.onSubmit();
    }
  }

  cancelSection(index: number): void {
    super.cancelSection(index);

    this.setDisplayUiTemplate(false);

    if (index === VentureSectionIndex.Demands) {
      this.isOpenVenture = !this.entity.isNDARequired;
    }
  }

  getActiveIds(uiTemplate: TemplateName): string[] {
    return getActiveSectionIds(uiTemplate);
  }

  checkBestPracticeAttribute(
    customMetadata: MetadataRecord,
    uiTemplate: TemplateName
  ): boolean {
    return (
      uiTemplate === TemplateName.Reduced &&
      !!customMetadata?.attributeDescriptions?.find(
        (x) => x.propertyName === 'BestPractices'
      )
    );
  }

  shouldShowCustomMetadataSection(
    customMetadataLength: number,
    isCustomAttributeFormGenerated: boolean
  ): boolean {
    const attributeDescriptions =
      this.customMetadata.attributeDescriptions.filter(
        (attr) => !attr.isCustomDisplayAttribute
      );

    return attributeDescriptions.length > 0 && isCustomAttributeFormGenerated;
  }

  characteristicsActiveTab(uiTemplate: TemplateName): number {
    return uiTemplate === TemplateName.Reduced ||
      uiTemplate === TemplateName.Reduced3
      ? 1
      : 0;
  }

  handleOnSubmit() {
    this.onSubmit();
    this.markOrgFormAsTouched();
  }

  onMessageRightChange(value) {
    this.form.patchValue({
      SendMessageRights: value,
    });

    if (!this.isCreating) {
      this.saveSection(VentureSectionIndex.SendMessageRights)
        .pipe(untilDestroyed(this))
        .subscribe();
    }
  }

  protected handleAfterGetEntityDetail(entity: VentureInterface): void {
    super.handleAfterGetEntityDetail(entity);
    this.changeMessageRightSelectedIndex(entity.sendMessageRights);
  }

  changeMessageRightSelectedIndex(value): void {
    const selectionChoices =
      this.getFieldOptions('SendMessageRights').choice.selections;
    if (!value || !selectionChoices) {
      return;
    }

    selectionChoices.forEach((item, index) => {
      if (typeof value === 'string') {
        if (
          value === item.order ||
          value === item.codeId ||
          value === item.value
        ) {
          this.ventureMessageRightSelectedIndex = index;
        }
      } else if (typeof value === 'object') {
        if (value.codeId === item.codeId) {
          this.ventureMessageRightSelectedIndex = index;
        }
      }
    });
  }

  canSendMessage(): boolean {
    if (!this.sessionService.isLogin) return true;
    let messageRight = this.entity.sendMessageRights;
    const isOwner = BaseEntityEditComponent.hasPermission(
      this.profile,
      this.entity
    );

    if (typeof messageRight === 'object') {
      messageRight = (this.entity.sendMessageRights as CustomAtrributeValue)
        .codeId;

      switch (messageRight) {
        case VentureMessageRights.Everybody:
        case VentureMessageRights.AdminAndCommunity:
          return isOwner || this.isTeam;
        case VentureMessageRights.AdminOnly:
          return isOwner;
        default:
          return false;
      }
    } else {
      return true;
    }
  }

  private listenToUiTemplateChange() {
    this.uiTemplateName$
      .pipe(untilDestroyed(this), take(2))
      .subscribe((uiTemplate: TemplateName) => {
        if (this.isCreating) {
          if (uiTemplate === TemplateName.Reduced3) {
            this.getDefaultBannerOfScreen4CareCustomTemplate();
          }

          const shouldSelectLeadOrgByDefault = [
            TemplateName.Reduced3,
            TemplateName.Reduced4,
          ].includes(uiTemplate);

          if (
            this.centralConfigService.innovationSpaceId &&
            shouldSelectLeadOrgByDefault
          ) {
            const organizationService = this.injector.get(
              OrganizationHttpService
            );

            organizationService
              .paginateX(
                {
                  ids: [this.centralConfigService.innovationSpaceId],
                },
                OrgsSearchMode.ForLead
              )
              .subscribe((res: ApiGetResponse<OrganizationInterface>) => {
                if (res.items && res.items.length > 0) {
                  this.organizerComponent?.onSelect(res.items[0]);
                }
              });
          }
        }
      });
  }

  getDefaultBannerOfScreen4CareCustomTemplate(): void {
    this.baseHttpService
      .getDefaultBanner()
      .pipe(untilDestroyed(this))
      .subscribe((res: ImageInterface) => {
        if (!res) return;
        this.defaultBanner = res;
        this.form.patchValue({
          Image: res,
        });
      });
  }

  resetImage() {
    this.form.patchValue({
      Image: this.defaultBanner,
    });
  }
}
