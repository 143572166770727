<ng-container *ngIf="columns.length > 0">
  <div class="metadata-table">
    <ngx-datatable
      class="material"
      [rows]="rows"
      [headerHeight]="56"
      [footerHeight]="50"
      [rowHeight]="80"
      [columnMode]="ColumnMode.force"
      [scrollbarH]="true"
      [externalSorting]="true"
      [loadingIndicator]="isLoading"
      (resize)="onResize($event)"
      (sort)="onSort($event)"
    >
      <ng-container *ngFor="let col of columns; let i = index">
        <ngx-datatable-column
          [prop]="col.propertyName"
          [name]="col.displayName"
          [resizeable]="col.resizeable"
          [canAutoResize]="col.canAutoResize"
          [draggable]="col.draggable"
          [width]="col.width"
          [minWidth]="100"
          [sortable]="col.sortable"
          [comparator]="noopComparator"
          *ngIf="col.canShow"
        >
          <ng-template
            let-value="value"
            let-row="row"
            let-rowIndex="rowIndex"
            ngx-datatable-cell-template
          >
            <ng-container [ngSwitch]="col.attributeType">
              <ng-template #editContent>
                <ng-container *ngIf="col.attrDes">
                  <app-metadata-table-cell-edit
                    *ngIf="
                      col.attrDes.attributeType !== AttributeType.RelatedEntity;
                      else relatedEntityEdit
                    "
                    [entityName]="entityName"
                    [entityId]="row.Id"
                    [attributeDescription]="col.attrDes"
                    [currentValue]="value"
                    [popover]="p"
                    [baseHttpService]="baseHttpService"
                    (valueUpdated)="
                      onValueUpdated($event, col.propertyName, rowIndex)
                    "
                  >
                  </app-metadata-table-cell-edit>
                  <ng-template #relatedEntityEdit>
                    <app-cell-edit-related-entity
                      [lstAdditionalAttrDes]="lstAdditionalAttrDes"
                      [parentEntityId]="parentEntityId"
                      [parentHttpService]="parentHttpService"
                      [entityName]="entityName"
                      [entityId]="row.Id"
                      [attributeDescription]="col.attrDes"
                      [currentValue]="value"
                      [popover]="p"
                      [baseHttpService]="baseHttpService"
                      (valueUpdated)="
                        onValueUpdated($event, col.propertyName, rowIndex)
                      "
                    >
                    </app-cell-edit-related-entity>
                  </ng-template>
                </ng-container>
              </ng-template>
              <ng-container *ngIf="p.isOpen()">
                <div class="fade modal-backdrop show h-100"></div>
              </ng-container>

              <div
                class="custom-cell-wrapper pointer-cursor"
                (click)="openEntityDetailAsNewTab(row.Id)"
              >
                <div
                  class="custom-cell-wrapper__edit"
                  [class.editing]="p.isOpen()"
                  [class.can-edit]="
                    hasEditPermission(col.attrDes, row.Id) &&
                    canEditCustomContition(col.attrDes, value)
                  "
                  [ngbPopover]="editContent"
                  [autoClose]="false"
                  triggers="manual"
                  #p="ngbPopover"
                  container="body"
                  [ngClass]="getEditCellCustomClass(col.propertyName)"
                  (click)="editCell(p, row.Id, col.attrDes, $event)"
                >
                  <ng-container *ngSwitchCase="AttributeType.Image">
                    <app-file-upload
                      [multiLocale]="
                        col.attrDes.systemType === SystemType.MultilingualImage
                      "
                      [disabled]="!hasEditPermission(col.attrDes, row.Id)"
                      (srcUrlChange)="
                        onValueUpdated($event, col.propertyName, rowIndex)
                      "
                      [id]="value.id"
                    >
                      <div
                        [ngClass]="getImgCustomClass(col.propertyName)"
                        class="m-0 m-auto"
                      >
                        <img
                          [src]="value.url.value || value.url"
                          appDefaultPlaceholder
                          appDynamicImgUrl
                        />
                      </div>
                    </app-file-upload>
                  </ng-container>

                  <ng-container *ngSwitchCase="AttributeType.DateTime">
                    <span
                      *ngIf="
                        entityName !== EntityName.Venture ||
                          !col.attrDes?.isCustomAttribute ||
                          shouldShowVentureConstitutionState(
                            getEntityById(row.Id)
                          );
                        else hiddenTempRef
                      "
                    >
                      {{ value | localizedDate : DateFormat.MetadataTable }}
                    </span>
                  </ng-container>

                  <ng-container *ngSwitchCase="AttributeType.SingleSelection">
                    <ng-container [ngSwitch]="col.propertyName">
                      <span *ngSwitchDefault> {{ value?.value }}</span>

                      <ng-container *ngSwitchCase="'PartnerStatus'">
                        <app-partner-status
                          [partnerStatus]="value"
                        ></app-partner-status>
                      </ng-container>

                      <ng-container *ngIf="entityName === EntityName.Venture">
                        <ng-container *ngSwitchCase="'Status'">
                          <ng-container
                            *ngIf="
                              shouldShowVentureConstitutionState(
                                getEntityById(row.Id)
                              );
                              else hiddenTempRef
                            "
                          >
                            {{ value?.value }}
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="AttributeType.MultipleSelection">
                    <div class="multiselect-type">
                      <div
                        *ngIf="
                          entityName !== EntityName.Venture ||
                            !col.attrDes?.isCustomAttribute ||
                            shouldShowVentureConstitutionState(
                              getEntityById(row.Id)
                            );
                          else hiddenTempRef
                        "
                        class="person-card__badge-wrapper person-card__info--ellipsis"
                      >
                        <div
                          class="badge"
                          *ngFor="let multiSelectItem of value"
                        >
                          {{ multiSelectItem.value }}
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngSwitchCase="AttributeType.RTE">
                    <app-rich-text-toggle
                      class="w-100"
                      [text]="value"
                      [showOverlayInsteadOfShowMore]="true"
                      [hideSeeMore]="true"
                      [minNumOfRows]="3"
                      [controlName]="col.propertyName + '-rte-' + rowIndex"
                      [defaultLineHeight]="21"
                    ></app-rich-text-toggle>
                  </ng-container>

                  <ng-container *ngSwitchCase="AttributeType.RelatedEntity">
                    <app-cell-view-related-entity
                      [entityName]="entityName"
                      [entityId]="row.Id"
                      [col]="col"
                      [value]="value"
                      [editable]="hasEditPermission(col.attrDes, row.Id)"
                    ></app-cell-view-related-entity>
                  </ng-container>

                  <ng-container *ngSwitchCase="AttributeType.Boolean">
                    <ng-container
                      *ngIf="
                        entityName !== EntityName.Venture ||
                          !col.attrDes?.isCustomAttribute ||
                          shouldShowVentureConstitutionState(
                            getEntityById(row.Id)
                          );
                        else hiddenTempRef
                      "
                    >
                      <div
                        *ngIf="
                          value !== undefined && value !== null && value !== ''
                        "
                        class="d-flex flex-column align-items-center justify-content-center boolean-attribute"
                      >
                        <ng-container *ngIf="col.propertyName !== 'IsPrivate'">
                          <ng-container
                            *ngIf="
                              col.attrDes.uiControlId ===
                                UIControlId.ConfirmationV1;
                              else normalCheckbox
                            "
                          >
                            <div
                              class="checkbox-circle d-flex align-items-center justify-content-center"
                              [ngClass]="{ 'checkbox-circle--checked': value }"
                            >
                              <i class="fas fa-check" *ngIf="value"></i>
                            </div>
                          </ng-container>
                          <ng-template #normalCheckbox>
                            <app-check-box
                              [isChecked]="value"
                              [disabled]="true"
                            ></app-check-box>
                          </ng-template>
                        </ng-container>

                        <span>
                          {{
                            getBooleanTypeValue(value, col.propertyName)
                              | translate
                          }}
                        </span>
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngSwitchCase="AttributeType.Link">
                    <a
                      target="_blank"
                      [value]="value"
                      appUrlPrefix
                      (click)="$event.stopPropagation()"
                    >
                      {{ value }}
                    </a>
                  </ng-container>

                  <ng-container *ngSwitchDefault>
                    <span class="truncate-line-3">{{ value }}</span>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </ngx-datatable-column>
      </ng-container>

      <!-- Tool -->
      <ngx-datatable-column
        [width]="32"
        [resizeable]="false"
        [draggable]="false"
        [sortable]="false"
        [frozenRight]="true"
      >
        <ng-template ngx-datatable-header-template>
          <i class="fa fa-ellipsis-v text-center"></i>
        </ng-template>
        <ng-template
          let-row="row"
          let-rowIndex="rowIndex"
          ngx-datatable-cell-template
        >
          <div
            class="custom-cell-wrapper cell-tool"
            (click)="$event.stopPropagation(); toolDrop.open()"
          >
            <div
              ngbDropdown
              #toolDrop="ngbDropdown"
              container="body"
              placement="left-top"
            >
              <a ngbDropdownAnchor>
                <i class="fa fa-ellipsis-v align-middle"></i>
              </a>
              <ul ngbDropdownMenu>
                <ng-container *ngIf="entityName === EntityName.Venture">
                  <li
                    *ngIf="isSendMessToMemberShown(row.Id)"
                    ngbDropdownItem
                    class="metadata-table__dropdown-item venture__actions-item p-2"
                  >
                    <app-btn-direct-message
                      [entityId]="row.Id"
                    ></app-btn-direct-message>
                  </li>
                </ng-container>

                <div class="dropdown-divider m-0"></div>

                <li
                  ngbDropdownItem
                  class="metadata-table__dropdown-item venture__actions-item"
                >
                  <a
                    class="link-unstyled w-100 p-2"
                    href="{{ getEntityUrl(entityName) }}/{{ row.Id }}"
                  >
                    <i class="fas fa-eye"></i>
                    <span class="venture__actions-label font-weight-bold">
                      Detail
                    </span>
                  </a>
                </li>

                <li
                  ngbDropdownItem
                  class="metadata-table__dropdown-item venture__actions-label font-weight-bold p-2 pl-0 pointer-cursor ml-0"
                  *ngIf="isSettingShown(row.Id)"
                  (click)="onSetting(row.Id, parentEntity)"
                >
                  <i class="fas fa-cog"></i> Settings
                </li>

                <li
                  ngbDropdownItem
                  class="metadata-table__dropdown-item venture__actions-label font-weight-bold p-2 pl-0 pointer-cursor ml-0"
                  *ngIf="enableExtraAction"
                  (click)="onPromoteAdmin(row.Id)"
                >
                  <i class="fa-user-check fas"></i> <span class="ml-1">Admin</span>
                </li>

                <li
                  ngbDropdownItem
                  class="metadata-table__dropdown-item venture__actions-label font-weight-bold p-2 pl-0 pointer-cursor ml-0"
                  *ngIf="enableExtraAction"
                  (click)="onRemovePerson(row.Id)"
                >
                  <i class="far fa-times fa-lg"></i> <span class="ml-1">Remove</span>
                </li>

                <li
                  *ngIf="showSubmitMenu"
                  class="ventwure__actions-item p-0"
                  ngbDropdownItem
                >
                  <a class="link-unstyled w-100 p-2 pointer-cursor">
                    <app-submit-ventures
                      appRequireLogin
                      [ventureId]="row.Id"
                      [venture]="getEntityById(row.Id)"
                      [modalHeader]="'UI.Common.SubmitToChallenge'"
                      (modalClosed)="onSubmitModalClosed($event)"
                    ></app-submit-ventures>
                  </a>
                </li>

                <ng-container *ngIf="entityName === EntityName.Venture">
                  <li
                    *ngIf="hasCreateTimeStampPermission(row.Id)"
                    ngbDropdownItem
                    class="metadata-table__dropdown-item venture__actions-item"
                  >
                    <app-btn-create-timestamp
                      class="w-100"
                      [httpService]="baseHttpService"
                      [entityId]="row.Id"
                      [styleMode]="StyleMode.Table"
                      (createSuccess)="afterCreateTimestamp(row.Id, rowIndex)"
                    ></app-btn-create-timestamp>
                  </li>

                  <li
                    *ngIf="hasLeaveEntityPermission(row.Id)"
                    ngbDropdownItem
                    class="metadata-table__dropdown-item venture__actions-item"
                  >
                    <a
                      class="link-unstyled w-100 p-2"
                      appLeaveEntity
                      [entityId]="row.Id"
                      [entityName]="entityName"
                      (afterLeaved)="afterLeaveEntity($event)"
                    >
                      <i class="fas fa-user-times"></i>
                      <span
                        class="venture__actions-label font-weight-bold"
                        [innerHTML]="
                          'UI.Common.LeaveThis'
                            | translate
                            | stringReplacement
                              : {
                                  '{entity}': (entityName | translate)
                                }
                        "
                      ></span>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</ng-container>

<ng-template #hiddenTempRef>
  <app-hidden-content
    [styleClass]="'venture-state-content'"
  ></app-hidden-content>
</ng-template>
