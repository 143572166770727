import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrgsSearchMode } from '@shared/enums/org-search-mode.enum';
import { ImageInterface } from '@shared/interfaces/file.interface';
import { LeadCompany } from '@shared/interfaces/lead-company.interface';
import { OrganizationInterface } from '@shared/interfaces/organization.interface';
import { FormUtils } from '@shared/utils/form-utils';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { SessionService } from '@src/app/core/session.service';
import { OrganizationSettingDialogComponent } from '@src/app/shared/components/molecules/organization-setting-dialog/organization-setting-dialog.component';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';
import { VentureSubmissionStatus } from '@src/app/shared/enums/venture.enum';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { UniqueNameValidator } from '@src/app/shared/utils/form-fields-validation';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { untilDestroyed } from '../../functions/until-destroyed';

@Component({
  selector: 'app-organizer',
  templateUrl: './organizer.component.html',
})
export class OrganizerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() editing: boolean;
  @Input() leadCompany: LeadCompany = {};
  @Input() isPending = false;
  @Input() isOwner = false;
  @Input() disabledSection: boolean;
  @Input() labelSectionName: string;
  @Input() labelClass: string;
  @Input() childClass = 'col-12';
  @Input() isLinkedFromParent: boolean;
  @Input() userId: number;
  @Input() searchMode: OrgsSearchMode = OrgsSearchMode.ForLead;
  @Input() otherOrganisationIds: number[] = [];
  @Input() advancedSetting: boolean;
  @Input() ignoreCompany: LeadCompany;

  @Input() nameAttributeDescription: AttributeDescription;
  @Input() logoAttributeDescription: AttributeDescription;
  @Input() urlAttributeDescription: AttributeDescription;
  @Input() leadCompanyAttribute: AttributeDescription;
  @Input() showRequireHint = false;

  @Input() isFromPeople = false;
  @Input() set markOrgFormTouched(isTouched: boolean) {
    if (isTouched) {
      this.orgForm.markAllAsTouched();
    }
  }
  @Input() showSubmitButton: boolean;
  @Input() beforeSubmitFn: () => Observable<boolean>;
  @Input() deactivatedOrgIcon: string;
  @Input() deactivatedOrgLabel = 'UI.UnsubscribedOrganization';
  @Input() ommitedOrgNameWhenCheckDuplicate = '';

  @Output() isLinkedFromParentChange = new EventEmitter<boolean>();
  @Output() orgFormChange = new EventEmitter<LeadCompany>();
  @Output() orgFormInvalidChanges = new EventEmitter<any>();
  @Output() orgSelected = new EventEmitter();

  @Output() submitVenture = new EventEmitter<void>();
  @Output() changeFormStatus = new EventEmitter();

  orgForm: UntypedFormGroup;
  selectedOrg: Partial<OrganizationInterface>;
  imgURL: string | ArrayBuffer;
  formHasValue = false;
  fillOrg = false;

  VentureSubmissionStatus = VentureSubmissionStatus;
  EntityName = EntityName;

  constructor(
    private fb: UntypedFormBuilder,
    private sessionService: SessionService,
    private readonly modalService: NgbModal,
    private orgHttpService: OrganizationHttpService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.initValidation();

    this.orgForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.isLinkedFromParent || this.isExternalOrg) {
        this.emitOrgInformation();
      }
      this.formHasValue = !!Object.values(this.orgForm.controls).some(
        (control) => control.value || control.value === 0
      );
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.leadCompany && this.leadCompany) {
      if (
        !FormUtils.isEqual(
          changes.leadCompany.currentValue,
          changes.leadCompany.previousValue,
          'id'
        )
      ) {
        this.updateFormValueAndStatus();
      }
    } else if (changes.editing && this.formHasValue) {
      this.isLinkedFromParent = true;
      this.fillOrg = true;
      this.changeControlStatusOnLinked();
    }

    if (changes.editing || changes.ommitedOrgNameWhenCheckDuplicate) {
      if (this.editing && this.isExternalOrg) {
        this.registerCheckDuplicateNameValidator(
          this.ommitedOrgNameWhenCheckDuplicate
        );
      }
    }
  }

  ngOnDestroy(): void {
    /**/
  }

  get orgUrl(): string {
    return (
      this.orgForm &&
      (this.orgForm.get('orgURL').value?.url ||
        this.orgForm.get('orgURL').value)
    );
  }

  getEntityName(): string {
    return this.leadCompany?.showAsChallenge
      ? environment.jipUrl.challenges
      : environment.jipUrl.organizations;
  }

  emitOrgInformation(): void {
    let orgBody: LeadCompany;

    if (this.orgForm.invalid) {
      if (this.leadCompanyAttribute && !this.leadCompanyAttribute.required) {
        if (this.isExternalOrg) {
          orgBody = {
            isActive: true,
            link: '',
            logoId: null,
            name: '',
          } as any;
          this.orgFormChange.emit(orgBody);
          return;
        }

        return;
      }
      this.orgFormChange.emit(null);
      this.orgFormInvalidChanges.emit(true);
      return;
    }

    if (!this.isLinkedFromParent) {
      orgBody = {
        link: this.orgURL?.value,
        name: this.orgName?.value,
        logoId: this.logoId?.value,
        isActive: true,
      };
      this.orgFormChange.emit(orgBody);
      return;
    }

    if (this.selectedOrg) {
      orgBody = {
        id: this.selectedOrg.id,
        name: this.selectedOrg.orgName,
        link: this.selectedOrg.orgUrl,
        logo: this.selectedOrg.logo,
        displayText: this.selectedOrg.displayText,
        isActive: this.selectedOrg.isActive,
        apiKey: this.selectedOrg.apiKey,
      };
      this.orgFormChange.emit(orgBody);

      if (!this.isExternalOrg) {
        this.selectedOrg = null;
      }
      return;
    }

    this.handleEmitExistedOrg();
  }

  updateFormValueAndStatus(): void {
    if ((this.leadCompany.name || this.leadCompany.orgName) && this.orgForm) {
      const orgURL =
        StringUtils.getCorrectUrl(this.leadCompany.link) ||
        StringUtils.getCorrectUrl(this.leadCompany.url) ||
        StringUtils.getCorrectUrl(this.leadCompany.orgUrl);

      this.orgForm.patchValue({
        orgName: this.leadCompany.name || this.leadCompany.orgName,
        orgURL,
        logoId: this.leadCompany?.logoId || this.leadCompany?.logo?.id,
      });
    }
    if (this.leadCompany.logo) {
      this.imgURL =
        this.leadCompany.logo?.url || (this.leadCompany.logo as any);
    }
    this.changeControlStatusOnLinked();
  }

  onSelect(item: OrganizationInterface): void {
    item.isActive = true;
    this.selectedOrg = item;
    this.fillOrg = true;
    this.registerCheckDuplicateNameValidator(item.orgName);

    if (this.selectedOrg && !this.selectedOrg.tagFieldType) {
      this.imgURL = this.selectedOrg?.logo?.url || this.selectedOrg?.logo;
      this.orgForm.patchValue({
        orgName: this.selectedOrg?.orgName,
        orgURL: this.selectedOrg?.orgUrl,
      });
      this.updateIsLinked(true);
      this.orgForm.markAllAsTouched();
      this.disableForm();
      this.emitOrgInformation();
    } else {
      this.updateIsLinked(false);
      this.orgForm.markAllAsTouched();
      this.updateFormWithIsLinked();
    }
    this.orgSelected.emit(item);
  }

  disableForm(): void {
    this.logoId.disable();
    this.orgName.disable();
    this.orgURL.disable();
  }

  activeForm(): void {
    this.logoId.enable();
    this.orgName.enable();
    this.orgURL.enable();
  }

  onRemove(): void {
    let initialOrgName = '';
    if (this.isExternalOrg) {
      initialOrgName = this.ommitedOrgNameWhenCheckDuplicate;
    }
    this.selectedOrg = null;
    this.leadCompany = null;
    this.imgURL = null;

    this.orgForm.patchValue({
      orgName: '',
      orgURL: '',
      logoId: null,
    });

    this.emitOrgInformation();
    this.orgSelected.emit();
    this.registerCheckDuplicateNameValidator(initialOrgName);
  }

  preview(file: ImageInterface): void {
    this.imgURL = file.url;
    this.logoId.setValue(file.id);
  }

  updateIsLinked(isLinked: boolean): void {
    this.isLinkedFromParent = isLinked;
    this.isLinkedFromParentChange.emit(isLinked);
  }

  updateFormWithIsLinked(): void {
    this.orgForm.patchValue({
      orgName: '',
      orgURL: '',
      logoId: null,
    });

    this.selectedOrg = null;
    this.imgURL = null;
    this.changeControlStatusOnLinked();
  }

  private handleEmitExistedOrg(): void {
    let orgBody: LeadCompany;

    if (this.leadCompany) {
      if (this.isExternalOrg) {
        orgBody = {
          ...this.leadCompany,
          link: this.orgURL?.value,
          name: this.orgName?.value,
          logoId: this.logoId?.value,
          logo: this.imgURL as any,
          isActive: true,
        };
      } else {
        orgBody = { ...this.leadCompany };
      }
    } else {
      orgBody = {
        link: this.orgURL?.value,
        name: this.orgName?.value,
        logoId: this.logoId?.value,
        isActive: true,
      };
    }
    this.orgFormChange.emit(orgBody);
  }

  private changeControlStatusOnLinked(): void {
    if (this.isLinkedFromParent && !this.isExternalOrg) {
      this.disableForm();
    } else {
      this.activeForm();
    }

    this.emitOrgInformation();
  }

  get logoId(): UntypedFormControl {
    return this.orgForm.get('logoId') as UntypedFormControl;
  }

  get orgName(): UntypedFormControl {
    return this.orgForm.get('orgName') as UntypedFormControl;
  }

  get orgURL(): UntypedFormControl {
    return this.orgForm.get('orgURL') as UntypedFormControl;
  }

  goToEntityPage(): void {
    if (this.leadCompany?.id) {
      const url = this.getEntityName() + '/' + this.leadCompany?.id;
      FormUtils.navigateToWithLanguagePath(this.sessionService, url);
    }
  }

  isActiveOrg(): boolean {
    if (this.isFromPeople) {
      if (
        this.leadCompany &&
        this.leadCompany.isActive !== undefined &&
        this.leadCompany.isActive !== null
      ) {
        return this.leadCompany.isActive;
      }
      return true;
    } else {
      return !!this.leadCompany;
    }
  }

  resetCompanyInput(): void {
    this.orgForm.reset();
    this.preview({});
  }

  get isExternalOrg(): boolean {
    return !this.leadCompany?.id;
  }

  onSubmitClicked(isSubmittedInput: HTMLInputElement): void {
    if (this.beforeSubmitFn) {
      this.beforeSubmitFn()
        .pipe(filter(Boolean))
        .subscribe(() => {
          this.submitVenture.emit();
          isSubmittedInput.checked = true; // Disable submit button
        });
    } else {
      this.submitVenture.emit();
      isSubmittedInput.checked = true; // Disable submit button
    }
  }

  private initForm(): void {
    this.orgForm = this.fb.group({
      orgName: '',
      orgURL: '',
      logoId: null,
    });
  }

  private initValidation(): void {
    const nameAtt = this.nameAttributeDescription;
    const logoAtt = this.logoAttributeDescription;
    const urlAtt = this.urlAttributeDescription;

    this.orgName.setValidators(
      nameAtt?.required ? [Validators.required] : null
    );
    this.logoId.setValidators(logoAtt?.required ? [Validators.required] : null);
    this.orgURL.setValidators(urlAtt?.required ? [Validators.required] : null);
  }

  openSettingModal(): void {
    const modalRef = this.modalService.open(
      OrganizationSettingDialogComponent,
      {
        centered: true,
        backdrop: 'static',
        size: 'sm',
        scrollable: false,
        windowClass: 'message-modal-custom',
      }
    );

    modalRef.componentInstance.organization = this.leadCompany;
    modalRef.componentInstance.leadOrg = this.ignoreCompany;
  }

  registerCheckDuplicateNameValidator(initialValue?: string) {
    this.orgForm.controls['orgName'].clearAsyncValidators();

    const validator = UniqueNameValidator.validateDuplicateNameFromApi(
      this.orgHttpService,
      initialValue
    );

    this.orgForm.controls['orgName'].setAsyncValidators(validator);
  }
}
