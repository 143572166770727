<div *ngIf="items?.length > 0" class="timeline-view">
  <ng-container
    *ngFor="let item of items; first as isFirst; last as isLast; index as index"
  >
    <ng-container *ngIf="!item.isOptional || item.phaseDate">
      <div
        class="timeline-view__bar"
        [class.active]="item.isCurrent"
        [class.current]="item.isCurrent"
        [class.event-point]="
          item.type === TimelineItemType.EventPoint &&
          isPhase(index, TimelineNameKey.Closing)
        "
        [class.flex-grow-1]="isPhase(index, TimelineNameKey.Awarding)"
        [class.timeline-view__bar--is-awarding]="
          isPhase(index, TimelineNameKey.Closing)
        "
      >
        <div
          class="timeline-view__circle-wrapper"
          [class.timeline-view__circle-wrapper--is-first]="isFirst"
        >
          <div
            class="timeline-view__circle"
            [ngClass]="{
              active: isActiveCircle(item, isFirst, index),
              'btn-text-primary': isActiveCircle(item, isFirst, index),
              'solid-color':
                isActiveCircle(item, isFirst, index) &&
                !(isFirst || isPhase(index, TimelineNameKey.Awarding))
            }"
            [class.timeline-view__circle--is-first]="isFirst"
            [class.timeline-view__circle--have-icon-inside]="
              isFirst ||
              isPhase(index, TimelineNameKey.Awarding) ||
              isPhase(index, TimelineNameKey.Closing)
            "
          >
            <ng-container *ngTemplateOutlet="cirleInsideRef"></ng-container>
          </div>
        </div>

        <ng-container
          *ngIf="
            item.type !== TimelineItemType.EventPoint ||
            isPhase(index, TimelineNameKey.Awarding)
          "
        >
          <div
            class="bar-background"
            [class.btn-primary]="item.isCurrent"
          ></div>
          <div
            class="bar-description"
            [class.ml-0]="isFirst"
            [class.btn-primary]="item.isCurrent"
          >
            <span class="bar-description--truncate">
              {{ item.name }}
            </span>
          </div>
        </ng-container>

        <ng-template #cirleInsideRef>
          <div
            class="phase-text d-flex flex-column"
            [class.phase-text--is-first]="isFirst"
            [class.phase-text--is-awarding]="
              isPhase(index, TimelineNameKey.Awarding) || isPhase(index, TimelineNameKey.Closing)
            "
          >
            <span class="phase-text--milestones widget__label">
              {{ item | call : getMilestones | translate }}
            </span>
            <span
              [class.btn-text-primary]="isActiveCircle(item, isFirst, index)"
            >
              {{ getPrepositions(item.nameKey) | translate }}
            </span>
            <span
              [class.btn-text-primary]="isActiveCircle(item, isFirst, index)"
              >{{
                getViewDate(
                  item.phaseDate,
                  !isFirst && !isPhase(index, TimelineNameKey.Awarding) && !isPhase(index, TimelineNameKey.Closing) 
                ) | localizedDate : DateFormat.Timeline
              }}</span
            >
          </div>
          <ng-container *ngIf="isFirst">
            <i
              class="fas fa-flag"
              [class.btn-text-primary]="isActiveCircle(item, isFirst, index)"
            ></i>
          </ng-container>

          <ng-container *ngIf="isPhase(index, TimelineNameKey.Awarding)">
            <i
              class="fas fa-trophy"
              [class.btn-text-primary]="isActiveCircle(item, isFirst, index)"
            ></i>
          </ng-container>

          <ng-container
            *ngIf="
              !isFirst &&
              !isPhase(index, TimelineNameKey.Awarding) &&
              !isPhase(index, TimelineNameKey.Closing)
            "
          >
            <i
              class="fas fa-caret-down"
              [class.btn-text-primary]="isActiveCircle(item, isFirst, index)"
            ></i>
          </ng-container>

          <ng-container *ngIf="isPhase(index, TimelineNameKey.Closing)">
            <app-internal-icon
              [name]="'xmark'"
              [height]="16"
              [className]="'filter-light-gray'"
              [class.filter-primary]="isActiveCircle(item, isFirst, index)"
            ></app-internal-icon>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</div>
