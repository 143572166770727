import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class PopupBlockerDetectionService {
  isPopupBlocked$ = new BehaviorSubject(false);

  handlePopupBlockerDetection(window: Window) {
    if (!window || window.closed || typeof window.closed == 'undefined') {
      this.isPopupBlocked$.next(true);
    }
  }
}
