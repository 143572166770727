<div class="mt-3">
  <div class="venture__label--head mb-2" translate>
    UI.Common.OtherOrganizations
  </div>

  <ng-container *ngIf="editing; else viewMode">
    <div class="form-group search">
      <app-organisation-search
        [excludedOrganisationIds]="ignoreOrgIds"
        [ignoreCompany]="ignoreCompany"
        [searchMode]="orgsSearchMode"
        [userId]="userId"
        (selectOrganisation)="selectOrganisation($event)"
      ></app-organisation-search>
    </div>
    <div
      *ngIf="organisations.length > 0"
      class="person-organisations container mt-4"
    >
      <div
        *ngFor="let org of organisations"
        class="person-organisations__item row align-items-center justify-content-between"
      >
        <div class="col-8">
          <div class="row align-items-center">
            <div
              class="organization__image"
              [ngStyle]="{
                'background-image': 'url(' + (org.logo?.url || org.logo) + ')'
              }"
            ></div>

            <h6 class="col mb-0 truncate-line-1">
              {{ org.orgName || org.name }}
            </h6>
          </div>
        </div>

        <div class="col mb-0">
          <div class="row align-items-center justify-content-end">
            <div
              class="px-2 col col-md-auto mb-0"
              [ngClass]="org.isPending ? 'visible' : 'invisible'"
            >
              <label class="flag flag-publish">
                <span class="truncate-line-1" translate>
                  <i class="fas fa-spinner mr-1"></i>Confirmation Pending
                </span>
              </label>
            </div>

            <a
              [href]="
                (org.showAsChallenge ? challengeRoot : orgRoot) + '/' + org?.id
              "
            >
              <button
                *ngIf="org?.isOwner"
                type="button"
                class="person-organisations__delete-btn btn btn-link"
              >
                <i class="fas fa-user-cog fa-lg"></i>
              </button>
            </a>

            <button
              *ngIf="!org?.isOwner"
              type="button"
              class="person-organisations__delete-btn btn btn-link"
              (click)="removeOrganisation(org)"
            >
              <i class="fas fa-trash fa-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #viewMode>
  <app-organization-card-list
    [displayItems]="organisations"
    [emptyPlacholderKey]="'PROFILE__Empty-submitted-orgs'"
  >
  </app-organization-card-list>
</ng-template>
