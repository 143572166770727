import { Component, Input } from '@angular/core';
import {
  TimelineItem,
  TimelineItemType,
  TimelineNameKey,
} from '@shared/interfaces/timeline-item.interface';
import { DateFormat } from '@src/app/shared/enums/date.enum';
import { InternalIcon } from '@src/app/shared/enums/internal-icon.enum';
import { TimeUtils } from '@src/app/shared/utils/time-utils';

@Component({
  selector: 'app-timeline-view',
  templateUrl: './timeline-view.component.html',
  styles: [],
})
export class TimelineViewComponent {
  @Input() items: TimelineItem[] = [];

  TimelineNameKey = TimelineNameKey;
  TimelineItemType = TimelineItemType;
  DateFormat = DateFormat;
  InternalIcon = InternalIcon;

  getMilestones(timelineItem: TimelineItem): string {
    switch (timelineItem.nameKey) {
      case TimelineNameKey.Submission:
        return 'UI.Common.Starting';

      case TimelineNameKey.Awarding:
      case TimelineNameKey.Closing:
        return timelineItem.name;

      default:
        return '';
    }
  }

  getPrepositions(timelineNameKey: TimelineNameKey): string {
    switch (timelineNameKey) {
      case TimelineNameKey.Submission:
        return 'UI.Common.FromChallengeTimeline';

      case TimelineNameKey.Awarding:
      case TimelineNameKey.Closing:
        return 'UI.Common.On';

      default:
        return 'UI.Common.Till';
    }
  }

  isPhase(index: number, timelineNameKey: TimelineNameKey): boolean {
    return this.items[index].nameKey === timelineNameKey;
  }

  getViewDate(stringDate: string, showAsLastDate = false): string {
    const date = new Date(stringDate);
    if (date && TimeUtils.isValidDate(date) && showAsLastDate) {
      return TimeUtils.addDays(date, -1)?.toISOString();
    }
    return stringDate;
  }

  isActiveCircle(item: TimelineItem, isFirst: boolean, index: number): boolean {
    if (isFirst) {
      return item.isCurrent;
    } else {
      return (
        this.isAfterCurrentPhase(index) ||
        (item.isCurrent && this.isPhase(index, TimelineNameKey.Awarding))
      );
    }
  }

  isAfterCurrentPhase(index: number): boolean {
    const previousPhase = this.items[index - 1];
    if (previousPhase?.nameKey === TimelineNameKey.JointCreating) {
      return !!previousPhase.phaseDate
        ? previousPhase.isCurrent
        : this.items[index - 2]?.isCurrent;
    }
    return previousPhase?.isCurrent;
  }
}
