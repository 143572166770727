<div
  *ngIf="editable; else viewOnly"
  ngbDropdown
  [container]="container"
  class="jip-dropdown"
  [ngClass]="styleClass"
>
  <ng-container *ngIf="!smartTyping; else smartDropdown">
    <button
      type="button"
      class="btn jip-dropdown__btn-select w-100 bg-white"
      id="dropdownBasic1"
      ngbDropdownToggle
    >
      <div class="d-flex align-items-center">
        <ng-container *ngTemplateOutlet="dropdownImg"></ng-container>
        <span class="jip-dropdown__selected-text">
          <ng-container *ngTemplateOutlet="normalText"></ng-container>
        </span>
      </div>
    </button>

    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <button
        *ngFor="let item of items; let index = index"
        ngbDropdownItem
        (click)="select(item, index)"
      >
        <div
          class="d-flex align-items-center"
          [ngClass]="{
            'justify-content-between': item.isAIOption
          }"
        >
          <ng-container *ngIf="item.img">
            <img
              appDefaultPlaceholder
              appDynamicImgUrl
              [src]="item.img"
              class="dropdown-img mr-2"
            />
          </ng-container>
          <span
            class="text-truncate"
            [class.font-weight-bold]="index === currentIndex"
            >{{ item.value }}</span
          >
          <span *ngIf="item.isAIOption" class="ml-2">
            <app-ai-indicator></app-ai-indicator>
          </span>
        </div>
      </button>
    </div>
  </ng-container>

  <ng-template #smartDropdown>
    <ng-select
      #selectComp
      [placeholder]="'UI.SmallFilter.Select' | translate"
      [items]="items"
      [multiple]="false"
      bindLabel="value"
      [closeOnSelect]="true"
      [virtualScroll]="true"
      [(ngModel)]="selectedItem"
      [searchFn]="customSearchFn"
      [editableSearchTerm]="true"
      notFoundText="No result found"
      (search)="onSearch($event)"
      groupBy="group"
      (change)="select($event)"
      (focus)="onFocus(selectComp)"
    >
      <ng-template ng-optgroup-tmp let-item="item">
        <div
          [ngClass]="{
            'frequently-choice': item.group,
            'frequently-choice-seperator': !item.group,
            'd-none': frequentlyUsedChoice.length === 0
          }"
        >
          {{ item.group || '' }}
        </div>
      </ng-template>
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        {{ item.value }}
      </ng-template>
    </ng-select>
  </ng-template>
</div>

<ng-template #viewOnly>
  <div class="d-flex align-items-center">
    <ng-container *ngTemplateOutlet="dropdownImg"></ng-container>
    <span class="jip-dropdown__selected-text">
      <ng-container
        *ngIf="
          replaceAsStarKey === items[currentIndex]?.codeId;
          else normalText
        "
      >
        *
      </ng-container>
    </span>
  </div>
</ng-template>

<ng-template #dropdownImg>
  <ng-container *ngIf="items && items[currentIndex]?.img">
    <img
      appDefaultPlaceholder
      appDynamicImgUrl
      [src]="items[currentIndex]?.img"
      class="dropdown-img mr-2"
    />
  </ng-container>
</ng-template>

<ng-template #normalText>
  {{ items ? items[currentIndex]?.value : defaultLabel }}
</ng-template>
