<span
  class="ai-indicator-container d-flex align-items-center text-white rounded py-1 px-2"
>
  <app-internal-icon
    [name]="InternalIcon.Sparkles"
    [height]="14"
    [className]="'filter-white'"
  >
  </app-internal-icon>
  <span class="ml-1">AI</span>
</span>
