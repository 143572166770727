<ng-container
  *ngIf="!onlyShowSelectedOptionsInViewMode; else customMultiSelection"
>
  <div *ngIf="displayItems" class="jip-multi-selection">
    <div
      *ngFor="let item of displayItems"
      (click)="toggleItem(item)"
      class="jip-multi-selection__item"
      [class]="{
        'jip-multi-selection__item--selected': item.selected,
        'jip-multi-selection__item--editable': editable
      }"
    >
      {{ item.value }}
    </div>
  </div>
</ng-container>

<ng-template #customMultiSelection>
  <div
    *ngIf="displayItems"
    class="jip-multi-selection"
    [class.is-grid-view]="isGridView"
  >
    <ng-container *ngFor="let item of displayItems">
      <div
        *ngIf="editable ? true : item.selected"
        (click)="toggleItem(item)"
        class="jip-multi-selection__item"
        [class]="{
          'jip-multi-selection__item--selected': editable && item.selected,
          'jip-multi-selection__item--editable': editable,
          'jip-multi-selection__item--is-grid-view': isGridView
        }"
      >
        {{ item.value }}
      </div>
    </ng-container>
  </div>
</ng-template>
