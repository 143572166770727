import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  take,
} from 'rxjs/operators';
import { CustomAtrributeValue } from '../interfaces/attribute-description.interface';
import { FormUtils } from './form-utils';

export class TextValidator {
  static notEmptyOrWhitespace(
    control: AbstractControl
  ): ValidationErrors | null {
    if (control.value && typeof control.value === 'string') {
      if (control.value.trim() === '') {
        return { notEmptyOrWhitespace: true };
      }
    }

    return null;
  }

  static cannotContainWhitespace(
    control: AbstractControl
  ): ValidationErrors | null {
    const fieldValue = (control.value as string).trim();
    if (fieldValue && fieldValue.indexOf(' ') >= 0) {
      return { cannotContainWhitespace: true };
    }

    return null;
  }

  static cannotContainManySpaces(
    control: AbstractControl
  ): ValidationErrors | null {
    const fieldValue = (control.value as string).trim();
    if (fieldValue && fieldValue.indexOf('  ') >= 0) {
      return { cannotContainManySpaces: true };
    }

    return null;
  }

  static uniqueOrgName(control: AbstractControl): ValidationErrors | null {
    const fieldValue = (control.value as string).trim();
    if (fieldValue && fieldValue.indexOf('a') >= 0) {
      return { uniqueOrgName: true };
    }

    return null;
  }
}

export class SingleSelectionValidator {
  static required(control: AbstractControl): ValidationErrors | null {
    const fieldValue: CustomAtrributeValue = control.value;
    if (
      fieldValue &&
      (fieldValue.codeId === 'DefaultValue' || !fieldValue.codeId)
    ) {
      return { required: true };
    }
    return null;
  }
}

export class DateValidators {
  static compareTwoDate(
    dateField1: string,
    isLessThan: boolean,
    dateField2: string,
    validatorErrorName: string
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const mainControl = control.get(dateField1);

      const date1 = mainControl?.value;
      const date2 = control.get(dateField2)?.value;
      const validatorField = {};
      validatorField[validatorErrorName] = true;

      if (date1 !== null && date2 !== null && isLessThan !== undefined) {
        if (isLessThan && date1 > date2) {
          mainControl.setErrors(validatorField);
          return validatorField;
        }
        if (!isLessThan && date2 > date1) {
          mainControl.setErrors(validatorField);
          return validatorField;
        }
      }
      FormUtils.removeError(mainControl, validatorErrorName);
      return null;
    };
  }
}

export class UniqueNameValidator {
  static validateDuplicateNameFromApi<T>(
    httpService: BaseHttpService<T>,
    initialValue: string
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const fieldValue = (control.value as string).trim();
    
      if (initialValue === fieldValue) return of(null);
      return control.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((value: string) => {
          return value.trim();
        }),
        switchMap((value) => {
          if (!value) {
            return of(null);
          }
          return httpService.checkDuplicateName(value).pipe(
            map(() => null),
            catchError((error) => {
              return of({
                nameDuplicated: {
                  message: error.error?.title || 'Duplicate name',
                },
              });
            })
          );
        }),
        take(1)
      );
    };
  }
}
