<form action="#" class="global-filter__search-form global-search">
        <h3 class="text-center">{{ 'UI.GlobalSearch.WelcomeMessage' | translate }}</h3>
        <div class="global-search--box"
                [class.searching]="isShowSearchBar">
            <a *ngIf="!isShowSearchBar; else searchBar"
               class="d-flex flex-sm-row align-items-center pointer-cursor"
               (click)="toggleSearchBar()">
                <span class="position-relative text-hide">
                  <i class="search-icon fas fa-search"></i>
                </span>
                <span [style.min-width.px]="'unset'"
                      class="global-search--box--placeholder pl-2"
                      >
                      {{ 'UI.GlobalSearch.SearchLabel' | translate }} <app-flip-words [flipWord]="[
                                                                'UI.GlobalSearch.InnovationInterest' | translate,
                                                                'UI.GlobalSearch.CallChallenges' | translate,
                                                                'UI.GlobalSearch.Events' | translate,
                                                                'UI.GlobalSearch.Organizations' | translate,
                                                                'UI.GlobalSearch.ProjectIdeas' | translate,
                                                                ]"></app-flip-words>
                </span>
            </a>

            <ng-template #searchBar>
                <span class="search-control left">
                    <i (click)="onSearch()" class="fa fa-search pointer-cursor"></i>
                </span>

                <input
                        type="text"
                        name="query"
                        #searchInput
                        class="form-control search-input"
                        [(ngModel)]="searchText"
                        (ngModelChange)="onSearchTextChange($event)"
                        (keydown.enter)="onSearch()"
                />
                <span class="search-control right">
                    <i (click)="clearText()"
                            class="reset-search far fa-times"
                            focusable="false">
                    </i>
                </span>
            </ng-template>
        </div>

        <div class="global-search--popular text-center">
            <span class="font-weight-bold">{{ 'UI.GlobalSearch.PopularSearch' | translate }}</span>
            <ul>
                <li>
                    <a class="global-search--popular__text" (click)="searchBy('Mobility')">
                        Mobility
                    </a>
                </li>
                <li>
                    <a class="global-search--popular__text" (click)="searchBy('Energy')">
                        Energy
                    </a>
                </li>
                <li>
                    <a class="global-search--popular__text" (click)="searchBy('Circularity')">
                        Circularity
                    </a>
                </li>
                <li>
                    <a class="global-search--popular__text" (click)="searchBy('Sustainability')">
                        Sustainability
                    </a>
                </li>
                <li>
                    <a class="global-search--popular__text" (click)="searchBy('Robotics')">
                        Robotics
                    </a>
                </li>
                <li>
                    <a class="global-search--popular__text" (click)="searchBy('AI & Deep Learning')">
                        AI & Deep Learning
                    </a>
                </li>
                <li>
                    <a class="global-search--popular__text" (click)="searchBy('Health')">
                        Health
                    </a>
                </li>
            </ul>
        </div>
    </form>
