<div *ngIf="data" class="card-item col">
  <div class="row">
    <div class="col-12 col-md-6 col-lg-5 p-0">
      <div
        appHoverDisplay
        [dataHoverDirective]="data"
        class="card-item__image-wrapper card-item__image-wrapper--venture"
        [ngClass]="{
          'padding-60-percent': !(
            shouldShowDescription(data) || data?.canViewNdaContent
          )
        }"
      >
        <img [src]="data.image?.url || data.image" appDefaultPlaceholder />
      </div>
    </div>

    <a
      class="col-12 col-md-6 col-lg-7 link-unstyled"
      appUnsavedFormCheck
      [href]="entityUrl"
    >
      <div
        class="card-item__info--link pt-3 pt-md-2 pt-lg-3 pb-3 pb-md-2 pb-lg-3 h-100 col widget__news-message--background"
      >
        <app-privacy-flag [isPrivate]="data.isPrivate"></app-privacy-flag>

        <div class="mt-2">
          <h5
            class="venture__title widget__heading truncate-line-1"
            appTooltip
            triggers="manual"
            #t="ngbTooltip"
            [toolTipControl]="t"
            [ngbTooltip]="data.title"
          >
            {{ data.title }}
          </h5>
        </div>
        <ng-container *ngIf="uiTemplate | visibility : 'InnovationProgress'">
          <div class="venture__label widget__label m-0 mt-xl-2">
            {{ 'UI.VenturePhase.InnovationProgress' | translate }}
          </div>
          <div>
            <app-form-field-custom
              *ngIf="uiTemplate !== TemplateName.Reduced4"
              [editable]="false"
              [currentValue]="data.phase"
              [type]="attributeType.VenturePhase"
              [attributeDescription]="getFieldOptions(data, 'Phase')"
            ></app-form-field-custom>

            <app-form-field-custom
              *ngIf="uiTemplate === TemplateName.Reduced4"
              [editable]="false"
              [currentValue]="data.phase"
              [type]="attributeType.VenturePhase"
              [attributeDescription]="getFieldOptions(data, 'CbChartaPhase')"
            ></app-form-field-custom>
          </div>
        </ng-container>

        <div>
          <div class="venture__label widget__label m-0 mt-xl-2" translate>
            Description
          </div>

          <app-rich-text-toggle
            *ngIf="
              shouldShowDescription(data) || data?.canViewNdaContent;
              else hiddenContentRef
            "
            [text]="data.excerpt || data.content"
            [controlName]="
              'venture-description-share-' + (data?.id || data?.instanceId)
            "
            [hideSeeMore]="true"
            [minNumOfRows]="1"
          ></app-rich-text-toggle>
        </div>
      </div>
    </a>
  </div>
</div>

<ng-template #hiddenContentRef>
  <app-hidden-content></app-hidden-content>
</ng-template>
