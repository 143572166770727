<div *ngIf="showLabel" class="mb-2 pb-1 widget__label" translate>
  {{ attributeDescription.displayName }}
</div>
<p translate>UI.PublishingStreams.FunctionExplain</p>
<form class="stream-setting-form" [formGroup]="streamForm">
  <div class="row mt-3">
    <div class="text-dark font-weight-bold col-3" translate>
      {{ getFormFieldOptions('StreamNumber').displayName }}
    </div>
    <div class="text-dark font-weight-bold col-6" translate>
      {{ getFormFieldOptions('StreamName').displayName }}
    </div>
    <div class="text-dark font-weight-bold col-2" translate>
      {{ 'Show Stream' }}
    </div>
  </div>

  <ng-container
    *ngFor="
      let item of attributeDescription.relatedEntityDefaultValues;
      index as i
    "
  >
    <div class="row mt-2" [class.d-none]="!isViewMoreStreams && i > 0">
      <ng-container [formGroupName]="item.streamNumber">
        <div class="col-3 d-flex align-items-center">
          <span>{{ item.streamNumber }}</span>
        </div>
      </ng-container>
      <div [formGroupName]="item.streamNumber" class="col-6">
        <input
          *ngIf="editable; else viewRef"
          formControlName="streamName"
          class="form-control"
          [placeholder]="item?.streamName"
        />

        <ng-template #viewRef>
          <div class="truncate-line-1">
            {{ entity ? getStreamValue(item)?.name : item.streamName }}
          </div>
        </ng-template>
      </div>

      <ng-container [formGroupName]="item.streamNumber">
        <div class="col-3 d-flex justify-content-between">
          <input
            type="checkbox"
            [id]="item.streamId"
            [name]="item.streamId"
            formControlName="isActivated"
            class="mr-2"
          />
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div class="mt-3">
    <button
      class="btn btn-link text-left p-0 font-size-sm"
      (click)="toggleViewMoreStream()"
    >
      View {{ isViewMoreStreams ? 'less' : 'more' }} streams
    </button>
  </div>
</form>
