import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-something-wrong-dialog',
  templateUrl: './something-wrong-dialog.component.html',
})
export class SomethingWrongDialogComponent {
  @Input() error: any;
  @Input() title = 'UI.SomethingWrong.Title';
  @Input() message = 'UI.SomethingWrong.Message';
  @Input() okText = 'OK';
  @ViewChild('modalRef') modalRef: ElementRef;


  constructor(public activeModal: NgbActiveModal) {}
}
