import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges } from '@angular/core';
import { EntityOrganizationsComponent } from '@src/app/components/entity-section/entity-organizations/entity-organizations.component';
import { ToastService } from '@src/app/core/toast.service';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { LeadCompany } from '@src/app/shared/interfaces/lead-company.interface';

@Component({
  selector: 'app-venture-organizations',
  templateUrl: './venture-organizations.component.html',
})
export class VentureOrganizationsComponent
  extends EntityOrganizationsComponent
  implements OnChanges
{
  @Input() emptyTextOrganizations = 'VENTURE-DETAILS__Empty-submitted-orgs';
  @Input() emptyTextChallenges = 'VENTURE-DETAILS__Empty-submitted-challenges';
  @Input() orgsOnly = false;

  constructor(private toastService: ToastService) {
    super();
  }

  selectItem(item: LeadCompany): void {
    if (item.showAsChallenge && item.isPrivate && item.id) {
      this.checkSubmitVenture(item);
    } else {
      this.handleSelectItem(item);
    }
  }

  checkSubmitVenture(item: LeadCompany): void {
    this.baseHttpService
      .checkSubmitVenture(item.id)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.handleSelectItem(item);
        },
        (errorRes: HttpErrorResponse) => {
          if (errorRes.error?.title) {
            this.toastService.showError(errorRes.error.title);
          }
        }
      );
  }
}
