<div
  *ngIf="isPopupBlocked$ | async"
  class="popup-blocker-notification d-flex align-items-center p-3 justify-content-between"
>
  <div class="d-flex align-items-center justify-content-start">
    <div class="mr-3">
      <app-internal-icon
        [name]="'triangle-exclamation'"
        [height]="24"
        [className]="'filter-yellow'"
      >
      </app-internal-icon>
    </div>
    <div class="mr-5">
      <b translate>UI.PopupBlockerNoti.PopupBlocked</b>
      {{ 'UI.PopupBlockerNoti.Message' | translate }}
      <ng-container *ngIf="guideUrl">
        <span translate>UI.PopupBlockerNoti.Click </span>
        <a class="btn-disable-guide text-underline" [href]="guideUrl" translate
          >UI.ProcessToken.Here</a
        >
        {{ 'UI.PopupBlockerNoti.ToDisablePopupBlocker' | translate }}
      </ng-container>
    </div>
  </div>
  <div>
    <i
      class="far fa-times pointer-cursor"
      focusable="false"
      (click)="removeNotification()"
    >
    </i>
  </div>
</div>
