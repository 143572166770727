<div
  (click)="$event.stopPropagation()"
  class="d-flex align-items-center justify-content-between"
  [ngClass]="{
    'd-none': !items.length
  }"
>
  <span class="font-weight-bold mr-2" translate>UI.SortOptions.Label</span>
  <app-boostrap-dropdown
    #sortOptionsDropdown
    [editable]="true"
    [items]="items"
    [currentIndex]="0"
    [defaultLabel]="'Sort by'"
    (selectItem)="onSelectItem($event)"
  >
  </app-boostrap-dropdown>
</div>
