import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SortingVenturesService } from '@src/app/components/organizations/services/sorting-ventures.service';
import { BoostrapDropdownComponent } from '@src/app/shared/components/boostrap-dropdown/boostrap-dropdown.component';
import { EntityName } from '@src/app/shared/enums/entity-name.enum';

@Component({
  selector: 'app-sort-options',
  templateUrl: './sort-options.component.html',
})
export class SortOptionsComponent implements OnChanges, AfterViewInit {
  @Input() items = [];
  @Input() entityName: EntityName;

  @Output() selectItem = new EventEmitter();

  @ViewChild('sortOptionsDropdown')
  sortOptionsDropdown!: BoostrapDropdownComponent;

  EntityName = EntityName;
  aiOptions = ['Matching'];
  firstItemEmitted = false;

  constructor(private sortingVenturesService: SortingVenturesService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && this.items.length > 0) {
      this.updateAIoptions();
      if (this.sortOptionsDropdown) {
        this.onSelectItem(this.items[0]);
        this.firstItemEmitted = true;
      }
    }
  }

  ngAfterViewInit(): void {
    if (!this.firstItemEmitted && this.items.length > 0) {
      this.onSelectItem(this.items[0]);
    }
  }

  onSelectItem(event) {
    const [propertyName, sortingDirection] = event.codeId.split('.');
    const sortingPayload = {
      sortingAttribute: {
        propertyName,
        sortingDirection,
      },
    };

    this.selectItem.emit(sortingPayload);
    this.sortingVenturesService.setSortingAttribute(
      sortingPayload.sortingAttribute
    );
  }

  updateAIoptions() {
    const aiOption = this.items.find((item) =>
      this.aiOptions.includes(item.codeId)
    );

    if (aiOption) {
      aiOption.isAIOption = true;
    }
  }
}
